import { Button } from "antd";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
} from "../../../../../plugins/Formularios/FormularioTecclas";

const HijoListConsultasAfiliadoAtributo = (props) => {
  const {
    control,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `consultas.[${props.indice}.condiciones]`,
  });

  function resetearCamposOperador(index, valorListar) {
    const fields = getValues();
    if (fields.consultas[props.indice]) {
      if (fields.consultas[props.indice].condiciones[index]) {
        delete fields.consultas[props.indice].condiciones[index].rango;
        delete fields.consultas[props.indice].condiciones[index].valor;
        fields.consultas[props.indice].condiciones[index].operador =
          valorListar;
        reset(fields);
      }
    }
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <div style={{ margin: "30px 0 20px 0", fontWeight: 600 }}>
              Condición:
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>
                Selecciona un operador
              </label>
              <SelectT
                control={control}
                name={`consultas.${props.indice}.condiciones.${index}.operador`}
                placeholder={"Selecciona un operador"}
                ancho="100%"
                rules={{ required: true }}
                onChange={(e) => {
                  resetearCamposOperador(index, e);
                }}
              >
                {watch(`consultas.${props.indice}.campo`)?.slice(
                  watch(`consultas.${props.indice}.campo`).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.campo`).length
                ) == "text" && (
                  <>
                    <OptionT value="$eq">Igual a</OptionT>
                    <OptionT value="$in">Iguales a</OptionT>
                    <OptionT value="$ne">Diferente de</OptionT>
                    <OptionT value="$nin">Diferentes de</OptionT>
                  </>
                )}

                {watch(`consultas.${props.indice}.campo`)?.slice(
                  watch(`consultas.${props.indice}.campo`).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.campo`).length
                ) == "number" && (
                  <>
                    <OptionT value="$eq">Igual a</OptionT>
                    <OptionT value="$in">Iguales a</OptionT>
                    <OptionT value="$lt">Menor que</OptionT>
                    <OptionT value="$lte">Menor o igual que</OptionT>
                    <OptionT value="$gt">Mayor que</OptionT>
                    <OptionT value="$gte">Mayor o igual que</OptionT>
                    <OptionT value="$ne">Diferente de</OptionT>
                    <OptionT value="$nin">Diferentes de</OptionT>
                    <OptionT value="rango">Rango</OptionT>
                  </>
                )}
              </SelectT>
            </div>
            {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[index]?.[
              "operador"
            ]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}

            {watch(`consultas.${props.indice}.condiciones.${index}.operador`) !=
              "$nin" &&
            watch(`consultas.${props.indice}.condiciones.${index}.operador`) !=
              "$in" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    margin: "10px 0 10px 0",
                    flexDirection: "column",
                  }}
                >
                  {watch(`consultas.${props.indice}.campo`)?.slice(
                    watch(`consultas.${props.indice}.campo`).indexOf("-") + 1,
                    watch(`consultas.${props.indice}.campo`).length
                  ) == "text" && (
                    <>
                      <label style={{ width: "100%", textAlign: "left" }}>
                        Ingresa un valor
                      </label>
                      <InputT
                        control={control}
                        name={`consultas.${props.indice}.condiciones.${index}.valor`}
                        placeholder={"Ingresa un valor"}
                        ancho="100%"
                        rules={{ required: true }}
                      ></InputT>
                    </>
                  )}

                  {watch(`consultas.${props.indice}.campo`)?.slice(
                    watch(`consultas.${props.indice}.campo`).indexOf("-") + 1,
                    watch(`consultas.${props.indice}.campo`).length
                  ) == "number" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) != "rango" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) && (
                      <>
                        <label style={{ width: "100%", textAlign: "left" }}>
                          Ingresa un valor
                        </label>
                        <InputNumberT
                          control={control}
                          name={`consultas.${props.indice}.condiciones.${index}.valor`}
                          placeholder={"Ingresa un valor"}
                          ancho="100%"
                          rules={{ required: true }}
                        ></InputNumberT>
                      </>
                    )}

                  {watch(`consultas.${props.indice}.campo`)?.slice(
                    watch(`consultas.${props.indice}.campo`).indexOf("-") + 1,
                    watch(`consultas.${props.indice}.campo`).length
                  ) == "number" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) == "rango" && (
                      <>
                        <label style={{ width: "100%", textAlign: "left" }}>
                          Ingresa el valor mínimo y el máximo
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <InputNumberT
                            control={control}
                            name={`consultas.${props.indice}.condiciones.${index}.rango.min`}
                            placeholder={"Valor mínimo"}
                            ancho="100%"
                            rules={{ required: true }}
                            min={undefined}
                            max={watch(
                              `consultas.${props.indice}.condiciones.${index}.rango.max`
                            )}
                          ></InputNumberT>
                          <InputNumberT
                            control={control}
                            name={`consultas.${props.indice}.condiciones.${index}.rango.max`}
                            placeholder={"Valor máximo"}
                            ancho="100%"
                            rules={{ required: true }}
                            min={watch(
                              `consultas.${props.indice}.condiciones.${index}.rango.min`
                            )}
                          ></InputNumberT>
                        </div>
                      </>
                    )}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    margin: "10px 0 10px 0",
                    flexDirection: "column",
                  }}
                >
                  <label style={{ width: "100%", textAlign: "left" }}>
                    Ingresa los valores
                  </label>
                  <SelectT
                    control={control}
                    name={`consultas.${props.indice}.condiciones.${index}.valor`}
                    placeholder={"Ingresa los valores"}
                    ancho="100%"
                    rules={{ required: true }}
                    mode={"tags"}
                  ></SelectT>
                </div>
              </>
            )}

            {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[index]?.[
              "valor"
            ]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}
            <Button type="secondary" danger onClick={() => remove(index)}>
              Eliminar condición
            </Button>
          </div>
        );
      })}
      <div style={{ margin: "20px 0 20px 0" }}>
        <Button
          type="secondary"
          onClick={() =>
            append({
              por: "historial",
              operador: "",
              valor: "",
            })
          }
        >
          Agregar condición
        </Button>
      </div>
    </>
  );
};

export default HijoListConsultasAfiliadoAtributo;
