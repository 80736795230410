import React from "react";
import { InputNumber, Input, Steps, Button, message, Select, Spin } from "antd";
import Countdown from "react-countdown";
import { Api } from "../../../../../../api/configApi";
import { set } from "mobx";
import { QRCodeSVG } from "qrcode.react";

const { Step } = Steps;

const steps = [
  {
    title: "Monto y moneda",
    content: "First-content",
  },
  {
    title: "Procesar pago",
    content: "Second-content",
  },
];

const TatumModal = (props) => {
  const refInputValorPagar = React.useRef("");
  const [inputValorPagar, setInputValorPagar] = React.useState("");
  const [widget, SetWidget] = React.useState({});
  const [current, setCurrent] = React.useState(0);
  const refMonedaPagar = React.useRef();
  const [monedaPagar, setMonedaPagar] = React.useState();
  const refTiempoRestante = React.useRef();
  const [tiempoRestante, setTiempoRentante] = React.useState();
  const refRuta = React.useRef(process.env.REACT_APP_RUTA);
  const refMontoMinimo = React.useRef();
  const refMontoMaximo = React.useRef();
  const refListaOpcionesMonto = React.useRef([]);
  const [listaOpcionesMonto, setListaOpcionesMonto] = React.useState([]);
  const refDireccion = React.useRef();
  const [direccion, setDireccion] = React.useState();
  const refBanderaContador = React.useRef();
  const [banderaContador, setBanderaContador] = React.useState(false);
  const refSpinCargando = React.useRef();
  const [spinCargando, setSpinCargando] = React.useState(false);
  const refMontoCriptoPagar = React.useRef();
  const [montoCriptoPagar, setMontoCriptoPagar] = React.useState();
  const refCriptoPagar = React.useRef();
  const [criptoPagar, setCriptoPagar] = React.useState();
  const [validarMonto, setValidarMonto] = React.useState(true);
  const [multiploCumple, setMultiploCumple] = React.useState(true);
  const [rangoCumple, setRangoCumple] = React.useState(true);

  const error = () => {
    message.error({
      content: "No se ha podido realizar la transacción.",
      duration: 5,
      className: "custom-class",
      style: {
        marginTop: "40vh",
      },
    });
  };

  function valida_monto(name) {
    setValidarMonto(/^[0-9]+(\.[0-9]{1,8})?$/g.test(name));
  }

  function validarCantidad(min, max, step, valor) {
    if (step) {
      if (!(parseFloat(valor) % step == 0)) {
        setMultiploCumple(false);
      } else if (parseFloat(valor) % step == 0) {
        setMultiploCumple(true);
      }
      if (!(min <= parseFloat(valor) && max >= parseFloat(valor))) {
        setRangoCumple(false);
      } else if (min <= parseFloat(valor) && max >= parseFloat(valor)) {
        setRangoCumple(true);
      }
    } else {
      if (!(min <= parseFloat(valor) && max >= parseFloat(valor))) {
        setRangoCumple(false);
      } else if (min <= parseFloat(valor) && max >= parseFloat(valor)) {
        setRangoCumple(true);
      }
    }
  }

  const enviarDatosPeticion = () => {
    if (props.props2.widget.montoPagar) {
      var amount = props.props2.widget.montoPagar;
    } else {
      var amount = refInputValorPagar.current;
    }

    Api(
      "integracionPasarelaPulsof",
      "createTransaction",
      {
        currency1: props.props2.widget.monedaMostrar
          ?.slice(0, props.props2.widget.monedaMostrar.indexOf("-"))
          .toUpperCase(),
        currency2: refMonedaPagar.current.toUpperCase(),
        amount: amount,
        idWidget: props.props2.widget._id,
      },
      {},
      "https://c76a-181-33-156-128.ngrok.io"
    )
      .then((res) => {
        refCriptoPagar.current = res.cripto;
        setCriptoPagar(refCriptoPagar.current);
        refMontoCriptoPagar.current = res.amount;
        setMontoCriptoPagar(refMontoCriptoPagar.current);

        refDireccion.current = res.address;
        setDireccion(refDireccion.current);
        refSpinCargando.current = false;
        setSpinCargando(refSpinCargando.current);

        if (res.success) {
          refBanderaContador.current = true;
          setBanderaContador(true);
          refTiempoRestante.current = res.timeout;
          setTiempoRentante(refTiempoRestante.current);
        } else {
          error();
          props.handleCancel();
        }
      })
      .catch((err) => {
        props.handleCancel();
        refSpinCargando.current = false;
        setSpinCargando(refSpinCargando.current);
        console.log(err);
      });
  };

  // const enviarIdTransaccion = (dato) => {
  //   Api(
  //     "pasarelas",
  //     props.props2.widget.listaIntegraciones,
  //     { type: "getInfoTransaccion" },
  //     { txnid: dato, idListado: refDatosEnviarPasarela.current.idListado },
  //     refRuta.current
  //   )
  //     .then((res) => {
  //       refEstado.current = res.estado;
  //       setEstado(refEstado.current);
  //     })
  //     .catch((err) => console.log(err));

  //   timeCu.current = setTimeout(() => {
  //     if (
  //       !(
  //         refEstado.current == "Pago completo" ||
  //         refEstado.current == "Cancelled / Timed Out"
  //       )
  //     ) {
  //       enviarIdTransaccion(dato);
  //     }
  //   }, 5000);
  // };

  function validarSiguiente() {
    if (props.props2.widget.opcion != "montoFijo") {
      if (refMonedaPagar.current == undefined) {
        return true;
      }
      if (refInputValorPagar.current == "") {
        return true;
      }
      if (!validarMonto) {
        return true;
      }
      if (props.props2.widget.multiplo && !multiploCumple) {
        return true;
      }
      if (refMontoMinimo.current && refMontoMaximo.current && !rangoCumple) {
        return true;
      }
    }
  }

  function copy(texto) {
    window.navigator.clipboard?.writeText(texto);
    message.success("Dirección copiada al portapapeles");
  }

  const next = () => {
    setCurrent(current + 1);
  };

  React.useEffect(() => {
    if (props.props2.widget.opcion == "rango") {
      if (props.props2.widget.montoMinimo) {
        refMontoMinimo.current = props.props2.widget.montoMinimo;
      }

      if (props.props2.widget.montoMaximo) {
        refMontoMaximo.current = props.props2.widget.montoMaximo;
      }
    }

    if (props.props2.widget.opcion == "checkOpcion") {
      refListaOpcionesMonto.current = props.props2.widget.opcionesMonto;
      setListaOpcionesMonto(refListaOpcionesMonto.current);
    }

    SetWidget(props.props2.widget);

    // return () => clearTimeout(timeCu.current);
  }, [props.props2.widget]);

  function opcionEleccion() {
    switch (props.props2.widget.opcion) {
      case "montoFijo":
        return (
          <>
            <div style={{ marginTop: "20px" }}>
              <p style={{ marginBottom: "-5px", textAlign: "center" }}>
                Monto a pagar:
              </p>
              <p
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {props.props2.widget.montoPagar || 0}{" "}
                {props.props2.widget.monedaMostrar &&
                  props.props2.widget.monedaMostrar !=
                    "SAME-No usar moneda de referencia" &&
                  (props.props2.widget.monedaMostrar.slice(
                    props.props2.widget.monedaMostrar?.indexOf("-") + 1,
                    props.props2.widget.monedaMostrar?.length
                  ) ||
                    "divisa no ingresada")}
              </p>
            </div>
          </>
        );
      case "rango":
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p>
                Ingresa el monto a pagar
                {props.props2.widget.monedaMostrar &&
                  props.props2.widget.monedaMostrar !=
                    "SAME-No usar moneda de referencia" &&
                  " en "}
                {props.props2.widget.monedaMostrar &&
                  props.props2.widget.monedaMostrar !=
                    "SAME-No usar moneda de referencia" &&
                  props.props2.widget.monedaMostrar.substring(
                    props.props2.widget.monedaMostrar.indexOf("-") + 1
                  )}
                , no olvides que el monto ingresado debe estar entre {refMontoMinimo.current} y {refMontoMaximo.current}{props.props2.widget.multiplo && `. También debe ser múltiplo de ${props.props2.widget.multiplo}`}:
              </p>
              
              <Input
                placeholder="Ingresa el monto"
                // min={refMontoMinimo.current}
                // max={refMontoMaximo.current}
                // value={inputValorPagar}
                // step={props.props2.widget.multiplo || 1}
                style={{ width: "300px" }}
                onChange={(e) => {
                  valida_monto(e.target.value);
                  validarCantidad(
                    refMontoMinimo.current,
                    refMontoMaximo.current,
                    props.props2.widget.multiplo,
                    e.target.value
                  );
                  console.log(refInputValorPagar.current, "onchange")
                  refInputValorPagar.current = e.target.value;
                  setInputValorPagar(e.target.value);
                }}
              />
              {!validarMonto && inputValorPagar.length > 0 && (
                <div>
                  <p style={{ color: "#ff0000" }}>
                    No es válido el formato del monto ingresado
                  </p>
                </div>
              )}
              {!multiploCumple && inputValorPagar.length > 0 && (
                <div>
                  <p style={{ color: "#ff0000" }}>El valor no es multiplo</p>
                </div>
              )}
              {!rangoCumple && inputValorPagar.length > 0 && (
                <div>
                  <p style={{ color: "#ff0000" }}>
                    El valor no está entre el rango estipulado
                  </p>
                </div>
              )}
            </div>
          </>
        );
      case "checkOpcion":
        return (
          <>
            <h4 style={{ textAlign: "center" }}>
              Ingresa el monto a pagar
              {props.props2.widget.monedaMostrar &&
                props.props2.widget.monedaMostrar !=
                  "SAME-No usar moneda de referencia" &&
                " en "}
              {props.props2.widget.monedaMostrar &&
                props.props2.widget.monedaMostrar !=
                  "SAME-No usar moneda de referencia" &&
                props.props2.widget.monedaMostrar.substring(
                  props.props2.widget.monedaMostrar.indexOf("-") + 1
                )}
                :
            </h4>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Select
                style={{ width: "300px" }}
                onChange={(e) => {
                  refInputValorPagar.current = e;
                  setInputValorPagar(e);
                }}
                placeholder="Selecciona una de las opciones"
              >
                {listaOpcionesMonto?.map((monto, index) => {
                  return (
                    <>
                      <Select.Option key={index} value={monto.montoOpcion}>
                        {monto.montoOpcion}
                      </Select.Option>
                    </>
                  );
                })}
              </Select>
            </div>
          </>
        );
      case "ninguno":
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p>
                Ingresa el monto a pagar
                {props.props2.widget.monedaMostrar &&
                  props.props2.widget.monedaMostrar !=
                    "SAME-No usar moneda de referencia" &&
                  " en "}
                {props.props2.widget.monedaMostrar &&
                  props.props2.widget.monedaMostrar !=
                    "SAME-No usar moneda de referencia" &&
                  props.props2.widget.monedaMostrar.substring(
                    props.props2.widget.monedaMostrar.indexOf("-") + 1
                  )}
                  {props.props2.widget.multiplo && `y debe ser múltiplo de ${props.props2.widget.multiplo}`}
                  :
              </p>
              
              <Input
                placeholder="Ingresa el monto"
                // step={props.props2.widget.multiplo || 1}
                style={{ width: "300px" }}
                onChange={(e) => {
                  valida_monto(e.target.value);
                  validarCantidad(
                    refMontoMinimo.current,
                    refMontoMaximo.current,
                    props.props2.widget.multiplo,
                    e.target.value
                  );
                  console.log(e.target.value, "e")
                  refInputValorPagar.current = e.target.value;
                  console.log(refInputValorPagar.current != "", "reff")
                  setInputValorPagar(e.target.value);
                }}
              />
              {!validarMonto && inputValorPagar.length > 0 && (
                <div>
                  <p style={{ color: "#ff0000" }}>
                    No es válido el formato del monto ingresado
                  </p>
                </div>
              )}
              {!multiploCumple && inputValorPagar.length > 0 && (
                <div>
                  <p style={{ color: "#ff0000" }}>El valor no es multiplo</p>
                </div>
              )}
            </div>
          </>
        );
      default:
        break;
    }
  }

  function tiempoFinalizado() {
    props.handleCancel();
    return (
      <>
        <div>Tiempo finalizado</div>
      </>
    );
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Steps
            progressDot={<div>1</div>}
            current={current}
            style={{ width: "50%" }}
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div style={{ marginTop: "24px" }}>
          {current < steps.length - 1 && (
            <div>
              <div
                style={{
                  minHeight: "200px",
                  width: "100%",
                  marginTop: "16px",
                  padding: "20px",
                  backgroundColor: "#fafafa",
                  border: "1px dashed #e9e9e9",
                  borderRadius: "10px",
                }}
              >
                {opcionEleccion()}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "20px",
                  }}
                >
                  <p>Elige la moneda en la que quieres pagar:</p>
                  <Select
                    placeholder="Selecciona una moneda"
                    onChange={(e) => {
                      refMonedaPagar.current = e;
                      setMonedaPagar(refMonedaPagar.current);
                    }}
                    style={{ width: "300px" }}
                    showSearch
                  >
                    {props.props2.widget.monedasPermitidas &&
                      props.props2.widget.monedasPermitidas.map(
                        (moneda, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={moneda.moneda.slice(
                                moneda.moneda.indexOf("-") + 1,
                                moneda.moneda.length
                              )}
                            >
                              {moneda.moneda.slice(
                                0,
                                moneda.moneda.indexOf("-")
                              )}
                            </Select.Option>
                          );
                        }
                      )}
                  </Select>
                </div>
              </div>

              <Button
                style={{ marginTop: "20px", alignItems: "center" }}
                type="primary"
                disabled={validarSiguiente()}
                onClick={() => {
                  setSpinCargando(true);
                  if (props.props2.widget.multiplo) {
                    if (
                      refInputValorPagar.current %
                        props.props2.widget.multiplo ==
                      0
                    ) {
                      next();
                      enviarDatosPeticion();
                    } else {
                      message.error(
                        `La cantidad ingresada no es múltiplo de ${props.props2.widget.multiplo}`
                      );
                    }
                  } else {
                    next();
                    enviarDatosPeticion();
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          )}

          {spinCargando && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "30px 0",
                }}
              >
                <Spin spinning={spinCargando}></Spin>
              </div>
            </>
          )}

          {current === steps.length - 1 && direccion && (
            <div>
              <div
                style={{
                  minHeight: "200px",
                  width: "100%",
                  marginTop: "16px",
                  backgroundColor: "#fafafa",
                  border: "1px dashed #e9e9e9",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {direccion && (
                  <>
                    <div style={{ margin: "30px" }}>
                      <QRCodeSVG
                        value={direccion}
                        size={128}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                      />
                    </div>
                  </>
                )}
                <strong style={{ fontSize: 40, textAlign: "center" }}>
                  {montoCriptoPagar}
                </strong>
                <strong style={{ fontSize: 40, textAlign: "center" }}>
                  {criptoPagar.toUpperCase()}
                </strong>
                {props.props2?.widget?.monedaMostrar !=
                  "SAME-No usar moneda de referencia" && (
                  <strong style={{ fontSize: 24 }}>
                    {props.props2.widget?.montoPagar || inputValorPagar}{" "}
                    {props.props2.widget.monedaMostrar.slice(
                      0,
                      props.props2.widget.monedaMostrar?.indexOf("-")
                    ) || inputValorPagar}
                  </strong>
                )}

                <h3>Dirección de la transacción: </h3>
                <h2>{direccion}</h2>
                <Button onClick={() => copy(direccion)}>Copiar</Button>
                <div style={{ marginTop: "20px" }}>
                  <h3 style={{ textAlign: "center" }}>
                    Estado de la transacción:
                  </h3>
                  <h2
                    style={{
                      fontSize: "30px",
                      color: !banderaContador ? "red" : "blue",
                      textAlign: "center",
                    }}
                  >
                    {!banderaContador
                      ? "Transacción sin iniciar"
                      : "Transacción iniciada"}
                  </h2>
                </div>
                {banderaContador && tiempoRestante && (
                  <div style={{ fontSize: "40px" }}>
                    <Countdown
                      date={Date.now() + tiempoRestante * 1000}
                      renderer={({ hours, minutes, seconds, completed }) => {
                        if (completed) {
                          return tiempoFinalizado();
                        } else {
                          return (
                            <span>
                              {hours < 10 ? "0" + hours : hours}:
                              {minutes < 10 ? "0" + minutes : minutes}:
                              {seconds < 10 ? "0" + seconds : seconds}
                            </span>
                          );
                        }
                      }}
                    />
                  </div>
                )}
                <div style={{ maxWidth: 300, textAlign: "center" }}>
                  <span style={{ fontSize: 12 }}>
                    Luego de enviar el pago podria tomar varios minutos antes de
                    ser recibido ya que por la naturaleza de las monedas
                    digitales requiere de ciertas validaciones.
                  </span>
                </div>

                {/* {cargando && (
                  <>
                    <Skeleton.Image style={{ marginTop: "20px" }} />{" "}
                    <Skeleton
                      style={{ marginTop: "20px" }}
                      active
                      title={false}
                      paragraph={{ rows: 2 }}
                    />
                  </>
                )} */}
              </div>

              {/* <Button
                style={{ marginTop: "20px" }}
                type="primary"
                onClick={() => props.funcion()}
              >
                Finalizar
              </Button>

              <Button style={{ margin: "20px 8px" }} onClick={() => prev()}>
                Anterior
              </Button> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TatumModal;
