import React from "react";
import CrearActualizarWidgetAutoLlenarPdf from "./tipos/Pdf/CrearActualizarWidgetPdfComponente";
import CrearActualizarWidgetIndicadoresComponente from "./tipos/Indicadores/CrearActualizarWidgetIndicadoresComponente";
import CrearActualizarWidgetTranferNumero from "./tipos/TransferNumero/CrearActualizarWidgetTranferNumeroComponente";
import CrearActualizarWidgetEncuestas from "./tipos/Encuestas/CrearActualizarWidgetEncuestas";
import CrearActualizarWidgetAcademia from "./tipos/Academia/CrearActualizarWidgetAcademia";
import CrearActualizarWidgetIframe from "./tipos/Iframe/CrearActualizarWidgetIframeComponente";
import CrearActualizarWidgetMapas from "./tipos/mapas/CrearActualizarWidgetMapasComponente";
import CrearActualizarWidgetListados from "./tipos/listados/CrearActualizarWidgetListadosComponente";
import CrearActualizarWidgetPasarelas from "./tipos/Pasarelas/CrearActualizarWidgetPasarelasComponente";
import CrearActualizarWidgetAcciones from "./tipos/acciones/CrearActualizarWidgetAccionesComponente";
import CrearActualizarWidgetBuilder from "./tipos/builderWidget/CrearActualizarWidgetBuilderComponente";
import CrearActualizarWidgetMarlinBotonComponente from "./tipos/Marlin/CrearActualizarWidgetMarlinBotonComponente";
import CrearActualizarWidgetVisualizarExportarDatos from "./tipos/ExportAndView/CrearActualizarWidgetVisualizarExportarDatos";

function TiposFormulariosWidgetsDev1(props) {
  React.useEffect(() => {}, []);
  return (
    <>
      {(props.tipo === "pdf" || props.mostrar === "pdf") && (
        <CrearActualizarWidgetAutoLlenarPdf
          visible={props.visible}
          setVisible={props.setVisible}
          data={props.data}
        />
      )}
      {(props.tipo === "indicadores" || props.mostrar === "indicadores") && (
        <CrearActualizarWidgetIndicadoresComponente
          visible={props.visible}
          setVisible={props.setVisible}
          data={props.data}
        />
      )}
      {(props.tipo === "tranferNumero" ||
        props.mostrar === "tranferNumero") && (
        <CrearActualizarWidgetTranferNumero
          visible={props.visible}
          setVisible={props.setVisible}
          data={props.data}
        />
      )}
      {(props.tipo === "encuestas" || props.mostrar === "encuestas") && (
        <>
          <CrearActualizarWidgetEncuestas
            visible={props.visible}
            setVisible={props.setVisible}
            data={props.data}
          />
        </>
      )}
      {(props.tipo === "academia" || props.mostrar === "academia") && (
        <>
          <CrearActualizarWidgetAcademia
            visible={props.visible}
            setVisible={props.setVisible}
            data={props.data}
          />
        </>
      )}
      {/* {(props.tipo === "marlinBoton" || props.mostrar === "marlinBoton") && (
        <>
          <CrearActualizarWidgetMarlinBotonComponente
            visible={props.visible}
            setVisible={props.setVisible}
            data={props.data}
          />
        </>
      )} */}
      {(props.tipo === "iframe" || props.mostrar === "iframe") && (
        <>
          <CrearActualizarWidgetIframe
            visible={props.visible}
            setVisible={props.setVisible}
            data={props.data}
          />
        </>
      )}

      {(props.tipo === "mapas" || props.mostrar === "mapas") && (
        <>
          <CrearActualizarWidgetMapas
            visible={props.visible}
            setVisible={props.setVisible}
            data={props.data}
          />
        </>
      )}

      {(props.tipo === "pasarelas" || props.mostrar === "pasarelas") && (
        <CrearActualizarWidgetPasarelas
          visible={props.visible}
          setVisible={props.setVisible}
          data={props.data}
        />
      )}
      {(props.tipo === "listados" || props.mostrar === "listados") && (
        <>
          <CrearActualizarWidgetListados
            visible={props.visible}
            setVisible={props.setVisible}
            data={props.data}
          />
        </>
      )}
      {(props.tipo === "acciones" || props.mostrar === "acciones") && (
        <>
          <CrearActualizarWidgetAcciones
            visible={props.visible}
            setVisible={props.setVisible}
            data={props.data}
          />
        </>
      )}
      {(props.tipo === "builder" || props.mostrar === "builder") && (
        <>
          <CrearActualizarWidgetBuilder
            visible={props.visible}
            setVisible={props.setVisible}
            data={props.data}
          />
        </>
      )}
      {(props.tipo === "tabla-v2" || props.mostrar === "tabla-v2") && (
        <>
          <CrearActualizarWidgetVisualizarExportarDatos
            visible={props.visible}
            setVisible={props.setVisible}
            data={props.data}
          />
        </>
      )}
    </>
  );
}

export default TiposFormulariosWidgetsDev1;
