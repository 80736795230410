import DesignWidgetPdf from "./tipos/Pdf/design/DesignWidgetPdf";
import IndicadoresDesignWidget from "./tipos/Indicadores/indicadoresDesignWidget";
import DesignWidgetTranferNumero from "./tipos/TransferNumero/design/DesignWidgetTranferNumero";
import DesignWidgetEncuestas from "./tipos/Encuestas/design/DesignWidgetEncuestas";
import DesignWidgetAcademia from "./tipos/Academia/design/DesignWidgetAcademia";
import DesignWidgetIframe from "./tipos/Iframe/design/DesignWidgetIframe";
import DesignWidgetMapas from "./tipos/mapas/design/DesignWidgetMapas";
import DesignWidgetListados from "./tipos/listados/design/DesignWidgetListados";
import DesignWidgetPasarelas from "./tipos/Pasarelas/design/DesignWidgetPasarelas";
import DesignWidgetAcciones from "./tipos/acciones/design/DesignWidgetAcciones";
import DesignWidgetBuilder from "./tipos/builderWidget/design/DesignWidgetBuilder";
import DesignWidgetMarlinBoton from "./tipos/Marlin/design/DesignWidgetMarlinBoton";
import DesignWidgetVisualizarExportarDatos from "./tipos/ExportAndView/design/DesignWidgetVisualizarExportarDatos";

function DesignWidgetsComponentes(props) {
  var widget;
  switch (props.widget.tipo) {
    case "pdf":
      widget = <DesignWidgetPdf className="widgetShadow" {...props.widget} />;
      break;
    case "indicadores":
      widget = (
        <IndicadoresDesignWidget className="widgetShadow" {...props.widget} />
      );
      break;
    case "tranferNumero":
      widget = (
        <DesignWidgetTranferNumero className="widgetShadow" {...props.widget} />
      );
      break;
    case "encuestas":
      widget = (
        <DesignWidgetEncuestas className="widgetShadow" {...props.widget} />
      );
      break;
    case "academia":
      widget = (
        <DesignWidgetAcademia className="widgetShadow" {...props.widget} />
      );
      break;
    case "pasarelas":
      widget = (
        <DesignWidgetPasarelas className="widgetShadow" {...props.widget} />
      );
      break;
    case "iframe":
      widget = (
        <DesignWidgetIframe className="widgetShadow" {...props.widget} />
      );
      break;
    case "mapas":
      widget = <DesignWidgetMapas className="widgetShadow" {...props.widget} />;
      break;
    case "listados":
      widget = <DesignWidgetListados className="" {...props.widget} />;
      break;
    case "acciones":
      widget = <DesignWidgetAcciones className="" {...props.widget} />;
      break;
    case "builder":
      widget = <DesignWidgetBuilder className="" {...props.widget} />;
      break;
    case "tabla-v2":
      widget = <DesignWidgetVisualizarExportarDatos className="" {...props.widget} />;
      break;
    // case "marlinBoton":
    //   widget = <DesignWidgetMarlinBoton className="" {...props.widget} />;
    //   break;
    default:
      widget = null;
      break;
  }
  return widget;
}

export default DesignWidgetsComponentes;
