import { Button } from "antd";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { store } from "../../../../../store";
import {
  DatePickerT2,
  InputNumberT,
  InputT,
  OptionT,
  RangePickerT2,
  SelectT,
} from "../../../../../plugins/Formularios/FormularioTecclas";

const HijoListConsultasListadoListado = (props) => {
  const {
    control,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `consultas.[${props.indice}.condiciones]`,
  });

  const refListados = React.useRef([]);
  const [listados, setListados] = React.useState([]);

  React.useEffect(() => {
    refListados.current = store.listados;
    setListados(refListados.current);
  }, []);

  function resetearCamposIdListado(index, valorListar) {
    const fields = getValues();
    if (fields.consultas[props.indice]) {
      if (fields.consultas[props.indice].condiciones[index]) {
        delete fields.consultas[props.indice].condiciones[index].operador;
        delete fields.consultas[props.indice].condiciones[index].valor;
        fields.consultas[props.indice].condiciones[index].campo = valorListar;
        reset(fields);
      }
    }
  }

  function resetearCamposOperador(index, valorListar) {
    const fields = getValues();
    if (fields.consultas[props.indice]) {
      if (fields.consultas[props.indice].condiciones[index]) {
        delete fields.consultas[props.indice].condiciones[index].rango;
        delete fields.consultas[props.indice].condiciones[index].valor;
        fields.consultas[props.indice].condiciones[index].operador =
          valorListar;
        reset(fields);
      }
    }
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <div style={{ margin: "30px 0 20px 0", fontWeight: 600 }}>
              Condición:
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>
                Campo del listado
              </label>
              <SelectT
                control={control}
                name={`consultas.${props.indice}.condiciones.${index}.campo`}
                placeholder={"Selecciona un campo del listado"}
                ancho="100%"
                rules={{ required: true }}
                onChange={(e) => {
                  resetearCamposIdListado(index, e);
                }}
              >
                <OptionT value="listado_creadoEn-date">
                  Fecha de creación
                </OptionT>
                {listados.find(
                  (listado) => listado._id == watch("idListado")
                ) &&
                  listados
                    .find((listado) => listado._id == watch("idListado"))
                    .campos?.map((campo, index) => {
                      return (
                        <>
                          <OptionT
                            key={index}
                            value={campo.NombreCampo + "-" + campo.tipoCampo}
                          >
                            {campo.NombreCampo}
                          </OptionT>
                        </>
                      );
                    })}
              </SelectT>
            </div>
            {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[index]?.[
              "campo"
            ]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>
                Selecciona un operador
              </label>
              <SelectT
                control={control}
                name={`consultas.${props.indice}.condiciones.${index}.operador`}
                placeholder={"Selecciona un operador"}
                ancho="100%"
                rules={{ required: true }}
                onChange={(e) => {
                  resetearCamposOperador(index, e);
                }}
              >
                {(watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) == "texto" ||
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "multiple") && (
                  <>
                    <OptionT value="$eq">Igual a</OptionT>
                    <OptionT value="$in">Iguales a</OptionT>
                    <OptionT value="$ne">Diferente de</OptionT>
                    <OptionT value="$nin">Diferentes de</OptionT>
                  </>
                )}

                {watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) == "numero" && (
                  <>
                    <OptionT value="$eq">Igual a</OptionT>
                    <OptionT value="$in">Iguales a</OptionT>
                    <OptionT value="$lt">Menor que</OptionT>
                    <OptionT value="$lte">Menor o igual que</OptionT>
                    <OptionT value="$gt">Mayor que</OptionT>
                    <OptionT value="$gte">Mayor o igual que</OptionT>
                    <OptionT value="$ne">Diferente de</OptionT>
                    <OptionT value="$nin">Diferentes de</OptionT>
                    <OptionT value="rango">Rango</OptionT>
                  </>
                )}

                {watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) == "date" && (
                  <>
                    <OptionT value="$eq">Igual a</OptionT>
                    <OptionT value="$lt">Antes de</OptionT>
                    <OptionT value="$gt">Después de</OptionT>
                    <OptionT value="rango">Rango</OptionT>
                  </>
                )}
              </SelectT>
            </div>
            {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[index]?.[
              "operador"
            ]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              {watch(
                `consultas.${props.indice}.condiciones.${index}.campo`
              )?.slice(
                watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                ).indexOf("-") + 1,
                watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                  .length
              ) == "date" &&
                watch(
                  `consultas.${props.indice}.condiciones.${index}.operador`
                ) != "rango" &&
                watch(
                  `consultas.${props.indice}.condiciones.${index}.operador`
                ) && (
                  <>
                    <label style={{ width: "100%", textAlign: "left" }}>
                      Ingresa una fecha
                    </label>
                    <DatePickerT2
                      control={control}
                      name={`consultas.${props.indice}.condiciones.${index}.valor`}
                      rules={{ required: true }}
                      style={{ width: "100%" }}
                    ></DatePickerT2>
                  </>
                )}

              {watch(
                `consultas.${props.indice}.condiciones.${index}.campo`
              )?.slice(
                watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                ).indexOf("-") + 1,
                watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                  .length
              ) == "date" &&
                watch(
                  `consultas.${props.indice}.condiciones.${index}.operador`
                ) == "rango" &&
                watch(
                  `consultas.${props.indice}.condiciones.${index}.operador`
                ) && (
                  <>
                    <label style={{ width: "100%", textAlign: "left" }}>
                      Ingresa un rango de fechas
                    </label>
                    <RangePickerT2
                      control={control}
                      name={`consultas.${props.indice}.condiciones.${index}.valor`}
                      rules={{ required: true }}
                      style={{ width: "100%" }}
                    ></RangePickerT2>
                  </>
                )}

              {(watch(
                `consultas.${props.indice}.condiciones.${index}.campo`
              )?.slice(
                watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                ).indexOf("-") + 1,
                watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                  .length
              ) == "number" ||
                watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) == "numero") &&
                watch(
                  `consultas.${props.indice}.condiciones.${index}.operador`
                ) == "rango" && (
                  <>
                    <label style={{ width: "100%", textAlign: "left" }}>
                      Ingresa el valor mínimo y el máximo
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <InputNumberT
                        control={control}
                        name={`consultas.${props.indice}.condiciones.${index}.rango.min`}
                        placeholder={"Valor mínimo"}
                        ancho="100%"
                        rules={{ required: true }}
                        min={undefined}
                        max={watch(
                          `consultas.${props.indice}.condiciones.${index}.rango.max`
                        )}
                      ></InputNumberT>
                      <InputNumberT
                        control={control}
                        name={`consultas.${props.indice}.condiciones.${index}.rango.max`}
                        placeholder={"Valor máximo"}
                        ancho="100%"
                        rules={{ required: true }}
                        min={watch(
                          `consultas.${props.indice}.condiciones.${index}.rango.min`
                        )}
                      ></InputNumberT>
                    </div>
                    {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[
                      index
                    ]?.["rango"]?.["min"].type == "required" && (
                      <div style={{ color: "red", marginBottom: "20px" }}>
                        Valor mínimo y máximo requerido
                      </div>
                    )}
                  </>
                )}
            </div>

            {watch(`consultas.${props.indice}.condiciones.${index}.operador`) !=
              "$nin" &&
            watch(`consultas.${props.indice}.condiciones.${index}.operador`) !=
              "$in" ? (
              <>
                {}

                {watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) == "multiple" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        margin: "10px 0 10px 0",
                        flexDirection: "column",
                      }}
                    >
                      <label style={{ width: "100%", textAlign: "left" }}>
                        Subcampo del listado
                      </label>

                      {(watch(
                        `consultas.${props.indice}.condiciones.${index}.operador`
                      ) == "$in" ||
                        watch(
                          `consultas.${props.indice}.condiciones.${index}.operador`
                        ) == "$nin") && (
                        <>
                          <SelectT
                            control={control}
                            name={`consultas.${props.indice}.condiciones.${index}.valor`}
                            placeholder="Selecciona una opción"
                            ancho="100%"
                            rules={{ required: true }}
                            mode="tags"
                          >
                            {listados
                              ?.find(
                                (listado) =>
                                  listado._id ==
                                  watch(
                                    `consultas.${props.indice}.condiciones.${index}.idListado`
                                  )
                              )
                              ?.campos?.find(
                                (campo) =>
                                  campo.NombreCampo ==
                                  watch(
                                    `consultas.${props.indice}.condiciones.${index}.campo`
                                  )?.slice(
                                    0,
                                    watch(
                                      `consultas.${props.indice}.condiciones.${index}.campo`
                                    ).indexOf("-")
                                  )
                              ) &&
                              listados
                                .find(
                                  (listado) =>
                                    listado._id ==
                                    watch(
                                      `consultas.${props.indice}.condiciones.${index}.idListado`
                                    )
                                )
                                ?.campos?.find(
                                  (campo) =>
                                    campo.NombreCampo ==
                                    watch(
                                      `consultas.${props.indice}.condiciones.${index}.campo`
                                    )?.slice(
                                      0,
                                      watch(
                                        `consultas.${props.indice}.condiciones.${index}.campo`
                                      ).indexOf("-")
                                    )
                                )
                                ?.opciones?.map((opc, index) => {
                                  return (
                                    <>
                                      <OptionT
                                        key={index + 23432}
                                        value={opc.opcion}
                                      >
                                        {opc.opcion}
                                      </OptionT>
                                    </>
                                  );
                                })}
                          </SelectT>
                        </>
                      )}

                      {watch(
                        `consultas.${props.indice}.condiciones.${index}.operador`
                      ) != "$in" &&
                        watch(
                          `consultas.${props.indice}.condiciones.${index}.operador`
                        ) != "$nin" && (
                          <>
                            <SelectT
                              control={control}
                              name={`consultas.${props.indice}.condiciones.${index}.valor`}
                              placeholder="Selecciona una opción"
                              ancho="100%"
                              rules={{ required: true }}
                            >
                              {listados
                                ?.find(
                                  (listado) =>
                                    listado._id ==
                                    watch(
                                      `consultas.${props.indice}.condiciones.${index}.idListado`
                                    )
                                )
                                ?.campos?.find(
                                  (campo) =>
                                    campo.NombreCampo ==
                                    watch(
                                      `consultas.${props.indice}.condiciones.${index}.campo`
                                    )?.slice(
                                      0,
                                      watch(
                                        `consultas.${props.indice}.condiciones.${index}.campo`
                                      ).indexOf("-")
                                    )
                                ) &&
                                listados
                                  .find(
                                    (listado) =>
                                      listado._id ==
                                      watch(
                                        `consultas.${props.indice}.condiciones.${index}.idListado`
                                      )
                                  )
                                  ?.campos?.find(
                                    (campo) =>
                                      campo.NombreCampo ==
                                      watch(
                                        `consultas.${props.indice}.condiciones.${index}.campo`
                                      )?.slice(
                                        0,
                                        watch(
                                          `consultas.${props.indice}.condiciones.${index}.campo`
                                        ).indexOf("-")
                                      )
                                  )
                                  ?.opciones?.map((opc, index) => {
                                    return (
                                      <>
                                        <OptionT
                                          key={index + 23432}
                                          value={opc.opcion}
                                        >
                                          {opc.opcion}
                                        </OptionT>
                                      </>
                                    );
                                  })}
                            </SelectT>
                          </>
                        )}
                    </div>
                    {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[
                      index
                    ]?.["valor"]?.type?.type == "required" && (
                      <div style={{ color: "red", marginBottom: "20px" }}>
                        Campo requerido
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) == "multiple" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        margin: "10px 0 10px 0",
                        flexDirection: "column",
                      }}
                    >
                      <label style={{ width: "100%", textAlign: "left" }}>
                        Subcampo del listado
                      </label>
                      <SelectT
                        control={control}
                        name={`consultas.${props.indice}.condiciones.${index}.valor`}
                        placeholder="Selecciona una opción"
                        ancho="100%"
                        rules={{ required: true }}
                        mode="multiple"
                      >
                        {listados
                          ?.find(
                            (listado) =>
                              listado._id ==
                              watch(
                                `consultas.${props.indice}.condiciones.${index}.idListado`
                              )
                          )
                          ?.campos?.find(
                            (campo) =>
                              campo.NombreCampo ==
                              watch(
                                `consultas.${props.indice}.condiciones.${index}.campo`
                              )?.slice(
                                0,
                                watch(
                                  `consultas.${props.indice}.condiciones.${index}.campo`
                                ).indexOf("-")
                              )
                          ) &&
                          listados
                            .find(
                              (listado) =>
                                listado._id ==
                                watch(
                                  `consultas.${props.indice}.condiciones.${index}.idListado`
                                )
                            )
                            ?.campos?.find(
                              (campo) =>
                                campo.NombreCampo ==
                                watch(
                                  `consultas.${props.indice}.condiciones.${index}.campo`
                                )?.slice(
                                  0,
                                  watch(
                                    `consultas.${props.indice}.condiciones.${index}.campo`
                                  ).indexOf("-")
                                )
                            )
                            ?.opciones?.map((opc, index) => {
                              return (
                                <>
                                  <OptionT
                                    key={index + 34342}
                                    value={opc.opcion}
                                  >
                                    {opc.opcion}
                                  </OptionT>
                                </>
                              );
                            })}
                      </SelectT>
                    </div>
                    {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[
                      index
                    ]?.["valor"]?.type?.type == "required" && (
                      <div style={{ color: "red", marginBottom: "20px" }}>
                        Campo requerido
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {watch(`consultas.${props.indice}.condiciones.${index}.operador`) !=
              "$nin" &&
            watch(`consultas.${props.indice}.condiciones.${index}.operador`) !=
              "$in" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    margin: "10px 0 10px 0",
                    flexDirection: "column",
                  }}
                >
                  {watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "texto" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) && (
                      <>
                        <label style={{ width: "100%", textAlign: "left" }}>
                          Ingresa un valor
                        </label>
                        <InputT
                          control={control}
                          name={`consultas.${props.indice}.condiciones.${index}.valor`}
                          placeholder={"Ingresa un valor"}
                          ancho="100%"
                          rules={{ required: true }}
                        ></InputT>
                      </>
                    )}

                  {watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "numero" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) != "rango" && (
                      <>
                        <label style={{ width: "100%", textAlign: "left" }}>
                          Ingresa un valor
                        </label>
                        <InputNumberT
                          control={control}
                          name={`consultas.${props.indice}.condiciones.${index}.valor`}
                          placeholder={"Ingresa un valor"}
                          ancho="100%"
                          rules={{ required: true }}
                        ></InputNumberT>
                      </>
                    )}
                </div>
              </>
            ) : (
              <>
                {watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) != "multiple" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        margin: "10px 0 10px 0",
                        flexDirection: "column",
                      }}
                    >
                      <label style={{ width: "100%", textAlign: "left" }}>
                        Ingresa los valores
                      </label>
                      <SelectT
                        control={control}
                        name={`consultas.${props.indice}.condiciones.${index}.valor`}
                        placeholder={"Ingresa los valores"}
                        ancho="100%"
                        rules={{ required: true }}
                        mode={"tags"}
                      ></SelectT>
                    </div>
                  </>
                )}
              </>
            )}

            {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[index]?.[
              "valor"
            ]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}
            <Button type="secondary" danger onClick={() => remove(index)}>
              Eliminar condición
            </Button>
          </div>
        );
      })}
      <div style={{ margin: "20px 0 20px 0" }}>
        <Button
          type="secondary"
          onClick={() =>
            append({
              idListado: watch("idListado"),
              por: "listado",
              campo: "",
              operador: "",
              valor: "",
            })
          }
        >
          Agregar condición
        </Button>
      </div>
    </>
  );
};

export default HijoListConsultasListadoListado;
