import {
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Spin,
} from "antd";
import { MotionConfig } from "framer-motion";
import React from "react";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../design/elementos/boton";
import AuthCode from "react-auth-code-input";
import { values } from "mobx";

const rules = [{ required: true, message: "Campo obligatorio" }];

function FormAprobarMasivamente({ widget, setDrawerAprobarMasivo, Get }) {
  const [loading, setLoading] = React.useState(false);
  const [guardando, setGuardando] = React.useState(false);
  const [monto, setMonto] = React.useState(widget.minimo);
  const [montoMaximo, setMontoMaximo] = React.useState(widget.maximo);
  const [montoMinimo, setMontoMinimo] = React.useState(widget.minimo);
  const [atributos, setAtributos] = React.useState({});
  const [losValues, setLosvalues] = React.useState({});
  const [modalCodigo, setModalCodigo] = React.useState(false);
  const [codeSecur, setCodesecur] = React.useState(0);
  const [cargandoModal, setCargandoModal] = React.useState(false);

  function Aprobar(values) {
    setGuardando(true);
    Api(
      "blockDispersion",
      "_",
      { widget: widget._id, bloques: values.bloques, limite: values.limite },
      values
    ).then((res) => {
      setGuardando(false);
      setDrawerAprobarMasivo(false);
      if (res.estado) {
        Get(widget);
        message.success("Solicitud enviada");
        setDrawerAprobarMasivo(false);
      } else {
        setGuardando(false);
        message.error(res.message || "Algo no salio bien");
      }
    });
  }
  return !loading ? (
    <>
      <Form onFinish={Aprobar} layout="vertical">
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="bloques"
          label="Cantidad de grupos"
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="limite"
          label="Solicitudes por grupo"
        >
          <InputNumber max={200} min={1} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="nombreWallet"
          label="Selecciona el código de la wallet de retiro"
          rules={[
            {
              required: true,
              message: "Por favor selecciona una opción",
            },
          ]}
        >
          <Select>
            {[
              "Binance Coin - BNB",
              "Binance Coin - BNB.BSC",
              "Binance Coin - BNB.ERC20",
              "Bitcoin - BTC",
              "Bitcoin cash - BCH",
              "Cardano - ADA",
              "Chainlink - LINK",
              "Dogecoin - DOGE",
              "Ethereum, Ether - ETH",
              "Litecoin - LTC",
              "Litecoin Testnet - LTCT",
              "Polkadot - DOT",
              "Ripple - XRP",
              "Stellar - XLM",
              "Tether - USDT.BEP2",
              "Tether - USDT.BEP20",
              "Tether - USDT.ERC20",
              "Tether - USDT.SOL",
              "Tether - USDT.TRC20",
              "Tezos - XTZ",
              "Uniswap - UNI",
              "USD Coin - USDC",
            ].map((e) => {
              return <Select.Option value={e}>{e}</Select.Option>;
            })}
          </Select>
        </Form.Item>

        <Space direction="vertical">
          <Boton loading={guardando} texto="Procesar" htmlType="submit" />
          <div style={{ marginTop: 10, cursor: "pointer" }}>
            {!guardando ? (
              <span onClick={() => setDrawerAprobarMasivo(false)}>
                Cancelar
              </span>
            ) : (
              <span>Por favor espera hasta que termine el proceso</span>
            )}
          </div>
        </Space>
      </Form>
    </>
  ) : (
    <Spin />
  );
}

export default FormAprobarMasivamente;
