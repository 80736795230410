import React from "react";
import { Button, Spin } from "antd";
import { useForm } from "react-hook-form";
import { InputT } from "../../../../../plugins/Formularios/FormularioTecclas";
import { Api } from "../../../../../api/configApi";

const ModalCrearExpo = (props) => {
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const [cargandoSpin, setCargandoSpin] = React.useState(false);
  const [validarNombre, setValidarNombre] = React.useState(true);

  function enviarNombreExport(nombreArchivo) {
    Api(
      "exportAndViewData",
      "exportAndViewData",
      {
        idWidget: props?.idWidget,
        nombreArchivo: nombreArchivo,
        export: "SI",
      },
      {},
      "http://localhost:5001"
    )
      .then((res) => {
        setCargandoSpin(false);
        props.setMostrarCrearExpo(false);
        props.obtenerTotalExport();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onSubmit(values) {
    if (validarNombre) {
      enviarNombreExport(values.nombreExportar);
      let fields = getValues();
      if (fields.nombreExportar) {
        delete fields.nombreExportar;
        reset(fields);
      }
      setCargandoSpin(true);
      props.setCargandoExport(true);
    }
  }

  function valida_usuario(name) {
    setValidarNombre(/^[ñÑA-Za-z0-9\s]+$/g.test(name));
  }

  return (
    <>
      {cargandoSpin ? (
        <>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px 0",
            }}
          >
            <Spin spinning={cargandoSpin} size="large"></Spin>
          </div>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <h3>Nueva exportación</h3>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label style={{ marginRight: "10px" }}>Nombre</label>
                <InputT
                  rules={{ required: true }}
                  onChange={(e) => {
                    valida_usuario(e.target.value);
                  }}
                  control={control}
                  name={"nombreExportar"}
                  ancho="80%"
                  placeholder="Ingresa el nombre del archivo a exportar"
                ></InputT>
              </div>
              {!validarNombre && (
                <>
                  <div style={{ color: "red" }}>
                    El nombre no puede contener caracteres especiales ni estar
                    vacio
                  </div>
                </>
              )}
              {errors?.nombreExportar?.type == "required" && (
                <div style={{ color: "red" }}>Campo requerido</div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "60%",
                  margin: "20px 0 30px 0",
                }}
              >
                <div>
                  <Button htmlType="submit">Exportar</Button>
                </div>
                <div>
                  <Button
                    type="second"
                    danger
                    onClick={() => {
                      props.setMostrarCrearExpo(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default ModalCrearExpo;
