import { Button } from "antd";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { InputT } from "../../../../../../plugins/Formularios/FormularioTecclas";

const ListMonto = () => {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "opcionesMonto",
  });

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>Monto:</label>
              <InputT
                control={control}
                name={`opcionesMonto.${index}.montoOpcion`}
                placeholder={"Ingresa un valor"}
                ancho="300px"
                rules={{ required: true }}
              ></InputT>
            </div>
            {errors?.["opcionesMonto"]?.[index]?.["montoOpcion"]?.type ===
              "required" && (
              <>
                <div style={{ color: "red" }}>Campo requerido</div>
              </>
            )}
            <Button
              type="secondary"
              danger
              onClick={() => {
                remove(index);
              }}
            >
              Eliminar monto
            </Button>
          </div>
        );
      })}

      <div style={{ margin: "20px 0 20px 0" }}>
        <Button
          type="secondary"
          onClick={() =>
            append({
              montoOpcion: "",
            })
          }
        >
          Agregar monto
        </Button>
      </div>
    </div>
  );
};

export default ListMonto;
