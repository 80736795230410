import React from "react";
import {
  Divider,
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  InputNumber,
  message,
} from "antd";

import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import { ObjectId } from "bson";
import CamposPersonalizados from "./CamposPersonalizados";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import {
  InputT,
  OptionT,
  SelectT,
} from "../../../../plugins/Formularios/FormularioTecclas";

function CrearActualizarWidgetPasarelas(props) {
  const methods = useForm();
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [formularioGuardado, setFormularioGuardado] = React.useState(false);
  const [form] = Form.useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "condicionales",
  });

  const error = () => {
    message.error({
      content: "Debes agregar al menos una moneda permitida.",
      duration: 5,
      className: "custom-class",
      style: {
        marginTop: "40vh",
      },
    });
  };

  const onSubmit = (values) => {
    if (
      (values.opcion == "checkOpcion" &&
        values.opcionesMonto?.length > 0 &&
        values.monedasPermitidas?.length > 0) ||
      (values.opcion != "checkOpcion" && values.monedasPermitidas?.length > 0)
    ) {
      values.tipo = "pasarelas";
      setPensando(true);
      setLoading(true);

      Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
        .then((res) => {
          setLoading(false);
          setFormularioGuardado(!formularioGuardado);
          setPensando(false);
          setLoading(false);
          return Api(
            "Cuentas",
            "Get",
            { _id: store.cuentaactiva },
            { widgets: 1 }
          );
        })
        .then((res) => {
          props.setVisible(false);
          setStoreWidgets(res[0].widgets);
        })
        .catch((err) => {
          setPensando(false);
          setLoading(false);
        });
    } else {
      console.log("casa error");
      error();
    }
  };
  React.useEffect(() => {
    reset();
    const objId = new ObjectId();
    setValue("_id", objId.toString());
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      reset(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      const objId2 = new ObjectId();
      setValue("_id", objId2.toString());
    }
  }, [props.data]);

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ width: "200px", textAlign: "left" }}>Nombre</label>
            <InputT
              rules={{ required: true }}
              ancho="100%"
              control={control}
              name={"nombre"}
            ></InputT>
          </div>
          {errors?.nombre?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

          <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ width: "200px", textAlign: "left" }}>Título</label>
            <InputT
              rules={{ required: true }}
              ancho="100%"
              control={control}
              name={"titulo"}
            ></InputT>
          </div>
          {errors?.titulo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ width: "200px", textAlign: "left" }}>
              Descripción
            </label>
            <InputT
              rules={{ required: true }}
              ancho="100%"
              control={control}
              name={"descripcion"}
            ></InputT>
          </div>
          {errors?.descripcion?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

          <CamposPersonalizados
            formularioGuardado={formularioGuardado}
            formulario={form}
          />

          <div
            style={{ backgroundColor: "#f5f5f5", padding: "10px 0 10px 20px" }}
          >
            {fields.map((item, index) => {
              return (
                <div key={item.id}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      margin: "10px 0 10px 0",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ textAlign: "left" }}>
                      Selecciona un atributo
                    </label>
                    <SelectT
                      control={control}
                      name={`condicionales.${index}.atributo`}
                      placeholder="Elige una opción"
                      ancho="300px"
                      rules={{ required: true }}
                      onChange={(e) => {
                        console.log(e);
                      }}
                      showSearch={true}
                    >
                      {store.losatributos.texts.map((atr) => {
                        return (
                          <OptionT value={atr.nombre}>{atr.nombre}</OptionT>
                        );
                      })}
                    </SelectT>
                  </div>
                  {errors?.["condicionales"]?.[index]?.["atributo"]?.type ===
                    "required" && (
                    <>
                      <div style={{ color: "red" }}>Campo requerido</div>
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      margin: "10px 0 10px 0",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ textAlign: "left" }}>
                      Selecciona un operador
                    </label>
                    <SelectT
                      control={control}
                      name={`condicionales.${index}.operador`}
                      placeholder="Elige una opción"
                      ancho="300px"
                      rules={{ required: true }}
                      onChange={(e) => {
                        console.log(e);
                      }}
                    >
                      <OptionT value="$eq">Es Igual</OptionT>
                      <OptionT value="$ne">Es diferente</OptionT>
                    </SelectT>
                  </div>
                  {errors?.["condicionales"]?.[index]?.["operador"]?.type ===
                    "required" && (
                    <>
                      <div style={{ color: "red" }}>Campo requerido</div>
                    </>
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      margin: "10px 0 10px 0",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ textAlign: "left" }}>
                      Selecciona un valor
                    </label>
                    <SelectT
                      control={control}
                      name={`condicionales.${index}.valor`}
                      placeholder="Elige una opción"
                      ancho="300px"
                      rules={{ required: true }}
                      onChange={(e) => {
                        console.log(e);
                      }}
                    >
                      {watch(`condicionales.${index}.atributo`) && (
                        <>
                          {store.losatributos.texts
                            .find((at) => {
                              return (
                                at.nombre ===
                                watch(`condicionales.${index}.atributo`)
                              );
                            })
                            .opciones?.map((op, index) => {
                              return (
                                <OptionT key={"i" + index} value={op}>
                                  {op}
                                </OptionT>
                              );
                            })}
                        </>
                      )}
                    </SelectT>
                  </div>
                  {errors?.["condicionales"]?.[index]?.["valor"]?.type ===
                    "required" && (
                    <>
                      <div style={{ color: "red" }}>Campo requerido</div>
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="secondary"
                      danger
                      onClick={() => remove(index)}
                    >
                      Eliminar condición
                    </Button>
                  </div>
                </div>
              );
            })}

            <div style={{ margin: "20px 0 20px 0" }}>
              <Button
                type="secondary"
                onClick={() =>
                  append({
                    atributo: undefined,
                    operador: undefined,
                    valor: undefined,
                  })
                }
              >
                Agregar condición
              </Button>
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: "40px" }}
            >
              Guardar
            </Button>
            <Button type="secondary" onClick={() => props.setVisible(false)}>
              Cancelar
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default CrearActualizarWidgetPasarelas;
