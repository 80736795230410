import { Button } from "antd";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  InputT,
} from "../../../../../plugins/Formularios/FormularioTecclas";

const FormularioMaterialDescargable = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "materialDescargable",
  });
  return (
    <>
      <div style={{ margin: "10px 0 10px 0" }}>
        {fields.map((item, index) => {
          return (
            <div key={item.id}>
              <div style={{ margin: "10px 0 10px 0" }}>
                <label>Material descargable</label>
                <div style={{ margin: "5px 0 5px 0" }}>
                  <InputT
                    control={control}
                    name={`materialDescargable.${index}.tituloMaterialDes`}
                    rules={{ required: true }}
                    placeholder="Ingresa el nombre del descargable"
                  ></InputT>
                  {errors.materialDescargable &&
                    errors.materialDescargable[index] &&
                    errors.materialDescargable[index].tituloMaterialDes &&
                    errors.materialDescargable[index].tituloMaterialDes.type ==
                      "required" && (
                      <span style={{ color: "red" }}>Campo requerido</span>
                    )}
                  {/* {(errors.materialDescargable && errors.materialDescargable[index] && errors.materialDescargable[index].tituloMaterialDes && errors.materialDescargable[index].tituloMaterialDes.type == "pattern") && <span style={{color:"red"}}>URL Requerida</span>} */}
                </div>
                <div style={{ margin: "5px 0 5px 0" }}>
                  <InputT
                    control={control}
                    name={`materialDescargable.${index}.urlMaterialAdjunto`}
                    rules={{
                      required: true,
                      pattern:
                        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                    }}
                    placeholder="Ingresa la url del material descargable"
                  ></InputT>
                  {errors.materialDescargable &&
                    errors.materialDescargable[index] &&
                    errors.materialDescargable[index].urlMaterialAdjunto &&
                    errors.materialDescargable[index].urlMaterialAdjunto.type ==
                      "required" && (
                      <span style={{ color: "red" }}>Campo requerido</span>
                    )}
                  {errors.materialDescargable &&
                    errors.materialDescargable[index] &&
                    errors.materialDescargable[index].urlMaterialAdjunto &&
                    errors.materialDescargable[index].urlMaterialAdjunto.type ==
                      "pattern" && (
                      <span style={{ color: "red" }}>URL Requerida</span>
                    )}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button type="secondary" danger onClick={() => remove(index)}>
                  Eliminar enlace
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="secondary"
          onClick={() => append({ urlMaterialAdjunto: "" })}
        >
          Agregar enlace para material descargable
        </Button>
      </div>
    </>
  );
};

export default FormularioMaterialDescargable;
