import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  InputT,
  OptionT,
  SelectT,
} from "../../../../../plugins/Formularios/FormularioTecclas";
import { Button, Select } from "antd";
import * as dataZone from "./timezones.json";
import { store } from "../../../../../store";
import ListConsultas from "./ListConsultas";

const CamposPersonalizados = (props) => {
  const refAtributos = React.useRef([]);
  const [atributos, setAtributos] = React.useState([]);
  const refListados = React.useRef([]);
  const [listados, setListados] = React.useState([]);
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "project",
  });
  const refDataZone = React.useRef();

  function resetearCampos(valorListar) {
    const fields = getValues();

    if (fields.project) {
      delete fields.project;
      fields.listar = valorListar;

      reset(fields);
    }
    if (fields.consultas) {
      fields.listar = valorListar;
      delete fields.consultas;
      reset(fields);
    }
    if (fields.idAtributoAfiliado) {
      fields.listar = valorListar;
      delete fields.idAtributoAfiliado;
      reset(fields);
    }
    if (fields.idAtributo) {
      fields.listar = valorListar;
      delete fields.idAtributo;
      reset(fields);
    }
    if (fields.idListado) {
      fields.listar = valorListar;
      delete fields.idListado;
      reset(fields);
    }
  }

  function resetearCamposIdListado(valorListar) {
    const fields = getValues();
    if (fields.project) {
      delete fields.project;
      fields.idListado = valorListar;
      reset(fields);
    }
    if (fields.consultas) {
      delete fields.consultas;
      fields.idListado = valorListar;
      reset(fields);
    }
  }

  function resetearCamposIdAtributo(valorListar) {
    const fields = getValues();
    if (fields.consultas) {
      fields.consultas.forEach((item, index) => {
        delete fields.consultas[index].condiciones;
      });
      fields.idAtributo = valorListar;
      reset(fields);
    }
  }

  function resetearCambioFormulario(valorListar) {
    const fields = getValues();
    if (fields.listar) {
      delete fields.listar;
      fields.formulario = valorListar;
      reset(fields);
    }
    if (fields.project) {
      delete fields.project;
      fields.formulario = valorListar;
      reset(fields);
    }
    if (fields.consultas) {
      delete fields.consultas;
      fields.formulario = valorListar;
      reset(fields);
    }
  }

  function resetearSubcampoListado(index, valorListar) {
    const fields = getValues();
    if (fields.project) {
      delete fields.project[index].ListadoCampoMultiple;
      fields.project[index].campo = valorListar;
      reset(fields);
    }
  }

  React.useEffect(() => {
    refDataZone.current = Object.values(dataZone.default);
    refListados.current = store.listados;
    setListados(refListados.current);
    refAtributos.current = [
      ...store.losatributos.dates,
      ...store.losatributos.flags,
      ...store.losatributos.numbers,
      ...store.losatributos.texts,
    ];
    setAtributos(refAtributos.current);
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
          margin: "10px 0 10px 0",
        }}
      >
        <label style={{ width: "100%", textAlign: "left" }}>
          Ingresa el nombre de la tabla
        </label>
        <InputT
          ancho="100%"
          placeholder="Ingresa el nombre de la tabla"
          control={control}
          name={"nombreTabla"}
          rules={{ required: true }}
        ></InputT>
      </div>
      {errors.nombreTabla && errors.nombreTabla.type == "required" && (
        <div style={{ color: "red" }}>Campo requerido</div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>Selecciona un formulario</label>
        <SelectT
          onChange={(e) => {
            resetearCambioFormulario(e);
          }}
          control={control}
          name="formulario"
          placeholder="Selecciona un formulario"
          ancho="100%"
          rules={{ required: true }}
        >
          {store.formularios.map((formu, index) => {
            return (
              <>
                <OptionT key={index + 43667} value={formu._id}>
                  {formu.nombre}
                </OptionT>
              </>
            );
          })}
        </SelectT>
      </div>
      {errors.formulario && errors.formulario.type == "required" && (
        <div style={{ color: "red" }}>Campo requerido</div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>Selecciona una zona horaria</label>
        <SelectT
          control={control}
          name="timezone"
          placeholder="Selecciona una zona horaria"
          ancho="100%"
          rules={{ required: true }}
          onChange={(e)=>{
            console.log(e, "zona horaria")
          }}
        >
          {refDataZone.current?.map((zone, index) => {
            if (index < refDataZone.current.length - 2) {
              return (
                <>
                  <OptionT
                    key={index + 1}
                    value={zone.text + "*" + zone.utc[0]}
                  >
                    {zone.text}
                  </OptionT>
                </>
              );
            }
          })}
        </SelectT>
      </div>
      {errors.formulario && errors.timezone.type == "required" && (
        <div style={{ color: "red" }}>Campo requerido</div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>Selecciona una opción</label>
        <SelectT
          onChange={(e) => {
            resetearCampos(e);
          }}
          control={control}
          name="listar"
          placeholder="Selecciona una opción"
          ancho="100%"
          rules={{ required: true }}
        >
          <OptionT value="afiliados">Afiliados</OptionT>
          <OptionT value="listados">Listados</OptionT>
          <OptionT value="atributos">Registro de atributos</OptionT>
        </SelectT>
      </div>
      {errors.listar && errors.listar.type == "required" && (
        <div style={{ color: "red", marginBottom: "20px" }}>
          Campo requerido
        </div>
      )}

      {watch("listar") == "listados" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ textAlign: "left" }}>Selecciona un listado</label>
            <SelectT
              control={control}
              name="idListado"
              placeholder="Selecciona un listado"
              ancho="100%"
              rules={{ required: true }}
              onChange={(e) => {
                resetearCamposIdListado(e);
              }}
            >
              {listados.map((listado, index) => {
                return (
                  <>
                    <OptionT key={index + 9} value={listado._id}>
                      {listado.nombre}
                    </OptionT>
                  </>
                );
              })}
            </SelectT>
          </div>
          {errors.idListado && errors.idListado.type == "required" && (
            <div style={{ color: "red", marginBottom: "20px" }}>
              Campo requerido
            </div>
          )}
        </>
      )}

      {watch("listar") == "atributos" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ textAlign: "left" }}>Selecciona un atributo</label>
            <SelectT
              control={control}
              name="idAtributo"
              placeholder="Selecciona un atributo"
              ancho="100%"
              rules={{ required: true }}
              onChange={(e) => {
                resetearCamposIdAtributo(e);
              }}
            >
              {atributos.map((atributo, index) => {
                return (
                  <>
                    <OptionT
                      key={index + 2}
                      value={atributo._id + "-" + atributo.tipo}
                    >
                      {atributo.nombre}
                    </OptionT>
                  </>
                );
              })}
            </SelectT>
          </div>
          {errors.idAtributo && errors.idAtributo.type == "required" && (
            <div style={{ color: "red", marginBottom: "20px" }}>
              Campo requerido
            </div>
          )}
        </>
      )}

      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            {watch("listar") == "listados" &&
              watch("idListado") &&
              setValue(`project.${index}.idListado`, watch("idListado"))}

            {watch("listar") == "atributos" &&
              watch("idAtributo") &&
              setValue(`project.${index}.idAtributo`, watch("idAtributo"))}

            {watch(`project.${index}.campo`) == "fecha_registro-date" &&
              setValue(`project.${index}.por`, "perfil")}

            {watch(`project.${index}.campo`) == "listado_creadoEn-date" &&
              setValue(`project.${index}.por`, "listado")}

            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                margin: "10px 0 10px 0",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>
                Ingresa el nombre de la columna
              </label>
              <InputT
                ancho="100%"
                placeholder="Ingresa el nombre de la columna"
                control={control}
                name={`project.${index}.Nombre`}
                rules={{ required: true }}
                onChange={(e) => {
                  if (
                    getValues().project.find(
                      (item) => item.Nombre == e.target.value
                    )
                  ) {
                    props.setValidarNombre(true);
                  } else {
                    props.setValidarNombre(false);
                  }
                }}
              ></InputT>
            </div>
            {errors?.["project"]?.[index]?.["Nombre"]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                margin: "10px 0 10px 0",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>
                Selecciona un campo
              </label>
              <SelectT
                control={control}
                name={`project.${index}.campo`}
                placeholder={"Selecciona un campo"}
                ancho="100%"
                rules={{ required: true }}
                onChange={(e) => {
                  resetearSubcampoListado(index, e);

                  atributos.find((atri) => {
                    if (atri.nombre == e.slice(0, e.indexOf("-"))) {
                      setValue(`project.${index}.por`, "afiliado");
                    }
                    return;
                  });
                  if (watch("formulario")) {
                    store.formularios
                      .find((e) => e._id == watch("formulario"))
                      .campos.find((elem) => {
                        if (elem.name == e.slice(0, e.indexOf("-"))) {
                          setValue(`project.${index}.por`, "perfil");
                        }

                        return;
                      });
                  }

                  if (
                    listados.find(
                      (listado) => listado._id == watch("idListado")
                    )
                  ) {
                    listados
                      .find((listado) => listado._id == watch("idListado"))
                      .campos.find((elem) => {
                        if (elem.NombreCampo == e.slice(0, e.indexOf("-"))) {
                          setValue(`project.${index}.por`, "listado");
                        }
                        return;
                      });
                  }
                }}
              >
                <OptionT value="" hidden>
                  Selecciona una opción
                </OptionT>

                <Select.OptGroup label="Atributos">
                  {atributos.map((atr, index) => {
                    return (
                      <>
                        <OptionT
                          key={index + Math.floor(Math.random() * 1000000)}
                          value={atr.nombre + "-" + atr.tipo}
                        >
                          {atr.nombre}
                        </OptionT>
                      </>
                    );
                  })}
                </Select.OptGroup>

                {watch("formulario") &&
                  watch("listar") != "listados" &&
                  watch("listar") != "afiliados" && (
                    <Select.OptGroup label="Afiliados">
                      <OptionT value="fecha_registro-date">
                        Fecha de registro
                      </OptionT>
                      {store.formularios.find(
                        (e) => e._id == watch("formulario")
                      ) &&
                        store.formularios
                          .find((e) => {
                            return e._id == watch("formulario");
                          })
                          .campos?.map((campo, index) => {
                            if (campo.type != "password") {
                              return (
                                <>
                                  <OptionT
                                    key={
                                      index +
                                      Math.floor(Math.random() * 1000000)
                                    }
                                    value={campo.name + "-" + campo.type}
                                  >
                                    {campo.name}
                                  </OptionT>
                                </>
                              );
                            }
                          })}
                    </Select.OptGroup>
                  )}

                {watch("listar") == "listados" ? (
                  <>
                    {watch("formulario") &&
                      store.formularios.find(
                        (e) => e._id == watch("formulario")
                      ) && (
                        <Select.OptGroup label="Afiliados">
                          <OptionT value="fecha_registro-date">
                            Fecha de registro
                          </OptionT>
                          {store.formularios
                            .find((e) => {
                              return e._id == watch("formulario");
                            })
                            .campos?.map((campo, index) => {
                              if (campo.type != "password") {
                                return (
                                  <>
                                    <OptionT
                                      key={
                                        index +
                                        Math.floor(Math.random() * 1000000)
                                      }
                                      value={campo.name + "-" + campo.type}
                                    >
                                      {campo.name}
                                    </OptionT>
                                  </>
                                );
                              }
                            })}
                        </Select.OptGroup>
                      )}

                    {listados.find(
                      (listado) => listado._id == watch("idListado")
                    ) && (
                      <Select.OptGroup label="Campos del listado">
                        <OptionT value="listado_creadoEn-date">
                          Fecha de creación
                        </OptionT>
                        {listados
                          .find((listado) => listado._id == watch("idListado"))
                          .campos?.map((campo, index) => {
                            return (
                              <>
                                <OptionT
                                  key={
                                    index + Math.floor(Math.random() * 1000000)
                                  }
                                  value={
                                    campo.NombreCampo + "-" + campo.tipoCampo
                                  }
                                >
                                  {campo.NombreCampo}
                                </OptionT>
                              </>
                            );
                          })}
                      </Select.OptGroup>
                    )}
                  </>
                ) : (
                  watch("listar") == "afiliados" && (
                    <>
                      {watch("formulario") &&
                        store.formularios.find(
                          (e) => e._id == watch("formulario")
                        ) && (
                          <Select.OptGroup label="Afiliados">
                            <OptionT value="fecha_registro-date">
                              Fecha de registro
                            </OptionT>
                            {store.formularios
                              .find((e) => e._id == watch("formulario"))
                              .campos?.map((campo, index) => {
                                if (campo.type != "password") {
                                  return (
                                    <>
                                      <OptionT
                                        key={
                                          index +
                                          Math.floor(Math.random() * 1000000)
                                        }
                                        value={campo.name + "-" + campo.type}
                                      >
                                        {campo.name}
                                      </OptionT>
                                    </>
                                  );
                                }
                              })}
                          </Select.OptGroup>
                        )}
                    </>
                  )
                )}
              </SelectT>
            </div>
            {errors?.["project"]?.[index]?.["campo"]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}
            <Button
              type="secondary"
              danger
              onClick={(e) => {
                remove(index);

                let contadorRep = 0;
                let contadorColum = 0;
                getValues().project.forEach((el, index, array) => {
                  contadorColum++;
                  array.forEach((elem) => {
                    if (
                      el.Nombre == elem.Nombre &&
                      el.Nombre.length > 0 &&
                      elem.Nombre.length > 0
                    ) {
                      contadorRep++;
                    }
                  });
                });
                if (contadorRep == contadorColum) {
                  props.setValidarNombre(false);
                } else {
                  props.setValidarNombre(true);
                }
              }}
            >
              Eliminar columna
            </Button>
          </div>
        );
      })}
      <div
        style={{
          marginTop: "20px",
          backgroundColor: "#fff",
          padding: "10px 0",
          borderRadius: "5px",
        }}
      >
        <Button
          disabled={
            (watch("idListado") && watch("listar")) ||
            (watch("listar") && watch("idAtributo")) ||
            watch("listar") == "afiliados"
              ? false
              : true
          }
          type="secondary"
          onClick={() => append({ Nombre: "", campo: "" })}
        >
          Agregar columnas
        </Button>
      </div>

      <ListConsultas formu={props.props.data} />
    </>
  );
};

export default CamposPersonalizados;
