import { Form, Input } from "antd";

function AccionInvitarRegistro() {
  return (
    <>
      <Form.Item
        label="Título"
        name="tituloEmaile"
        hasFeedback
        rules={[{ required: true, message: "Ingresa un titulo" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Mensaje"
        name="mensajeEmail"
        hasFeedback
        rules={[{ required: true, message: "Ingresa un mensaje" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Link página de registro"
        name="landing"
        hasFeedback
        rules={[
          { required: true, message: "Ingresa un link" },
          { type: "url", message: "Ingresa una url válida" },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
}

export default AccionInvitarRegistro;
