import { Form, Input, InputNumber, message, Select } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { Api } from "../../api/configApi";
import Boton from "../../components/design/elementos/boton";
import { setStoreActualizarLaEstructura, store } from "../../store";
const { Option } = Select;

function RenderTipodeRed() {
  const [form] = Form.useForm();
  const [cargando, setCargando] = React.useState(false);
  React.useEffect(() => {
    form.setFieldsValue(store.red);
    // console.log(store.red);
  }, [store.red]);
  function guardarConfiguracion(values) {
    setCargando(true);
    Api(
      "cuentas",
      "UpdateRed",
      { _id: store.cuentaactiva },
      { red: values }
    ).then((res) => {
      message.success("Actualizado Correctamente");
      setStoreActualizarLaEstructura();
      setCargando(false);
    });
  }
  return (
    <>
      <Form form={form} onFinish={guardarConfiguracion} layout="vertical">
        <Form.Item
          name="tipo"
          label="Red"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Select>
            <Option value="unilevel">Unilevel</Option>
            {/* <Option value="matriz">Matriz Forzada</Option>
             */}
            {/* <Option value="binario">Binario</Option> */}
            <Option value="binarioHibrido">Binario Hibrido</Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            if (
              getFieldsValue().tipo === "unilevel" ||
              getFieldsValue().tipo === "binarioHibrido"
            ) {
              return (
                <Form.Item
                  rules={[{ required: true, message: "Campo obligatorio" }]}
                  label="Profundidad Unilevel"
                  name="profundidad"
                >
                  <InputNumber
                    style={{ minWidth: 200 }}
                    placeholder="Profundidad"
                  />
                </Form.Item>
              );
            }
            if (getFieldsValue().tipo === "matriz") {
              return (
                <>
                  <Form.Item
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                    label="Frontalidad"
                    name="frontalidad"
                  >
                    <InputNumber
                      style={{ minWidth: 200 }}
                      placeholder="Frontalidad"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                    label="Profundidad matriz"
                    name="profundidad"
                  >
                    <InputNumber
                      style={{ minWidth: 200 }}
                      placeholder="Profundidad"
                    />
                  </Form.Item>
                </>
              );
            }
          }}
        </Form.Item>

        <Boton loading={cargando} texto="Guardar" htmlType="submit" />
      </Form>
    </>
  );
}

export default observer(RenderTipodeRed);
