import { PDFDocument } from "pdf-lib";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import React from "react";
import { store } from "../../../../../store";
import { Button } from "antd";


function DesignWidgetMarlinBoton(props) {
  const [widget, setWidget] = React.useState({});

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    setWidget(widgetActual);
  }, [props]);

  React.useEffect(() => {}, []);

  function dame (){
    console.log("que rico")
  }

  return (
    <>
      <Button onClick={dame}>Dame duro</Button>
    </>
  );
}

export default DesignWidgetMarlinBoton;
