import React from 'react'

const Modal = ({children, color, ancho, alto, mostrar, setMostrar, zIndexFondo, zIndexModal, borderRadius}) => {
  return (
    <>
      {mostrar && (
        <div
          onClick={() => setMostrar(false)}
          style={{
            background: "rgba(0, 0, 0, 0.6)",
            width: "100%",
            height: "100%",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: zIndexFondo,
          }}
        ></div>
      )}

      {mostrar && (
        <div
          style={{
            borderRadius: borderRadius,
            backgroundColor: color ? color : "green",
            width: ancho ? ancho : "300px",
            height: alto ? alto : "300px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: zIndexModal,
          }}
        >
          {children}
        </div>
      )}
    </>
  )
}

export default Modal