import { Spin } from "antd";
import React from "react";
import { Api } from "../../../api/configApi";
import { store } from "../../../store";
import MonedaActivar from "../MonedaActivar";

function BlockChainDirect(props) {
  const refMonedas = React.useRef();
  const [monedas, setMonedas] = React.useState();
  const refCargandoSpin = React.useRef();
  const [cargandoSpin, setCargandoSpin] = React.useState(false);

  const estadoMonedas = () => {
    setCargandoSpin(true);
    Api(
      "integracionPasarelaPulsof",
      "consultarEstadoMonedas",
      {},
      {},
      "https://841d-181-33-156-128.ngrok.io"
    )
      .then((res) => {
        console.log(res, "respuesta");
        refCargandoSpin.current = false;
        setCargandoSpin(refCargandoSpin.current);
        refMonedas.current = res;
        setMonedas(refMonedas.current);
      })
      .catch((err) => console.log(err, "entra al catch"));
  };

  React.useEffect(() => {
    estadoMonedas();
  }, []);

  return (
    <>
      <p style={{ textAlign: "center", fontSize: "24px" }}>
        Lista de criptomonedas
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spin spinning={cargandoSpin} />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ minWidth: "400px", width: "50%" }}>
          {monedas &&
            monedas?.map((elem, index) => {
              return (
                <MonedaActivar
                  nombre={elem.moneda}
                  index={index}
                  codigo={elem.codigo}
                  estado={elem.activo}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}

export default BlockChainDirect;
