// Los iconos se suben a BUNNYCDN y se pueden descargar de FLATICON.

function IconosWidgetsComponenteDev1(text) {
  var imagen = "";
  switch (text) {
    case "pdf":
      imagen = "https://octopusmultinivel.b-cdn.net/octopus10/pdf.svg";
      break;
    case "indicadores":
      imagen = "https://pulosfDevelopers.b-cdn.net/iconosWidgets/economy.png";
      break;
    case "tranferNumero":
      imagen =
        "https://pulosfDevelopers.b-cdn.net/iconosWidgets/number-blocks.png";
      break;
    case "encuestas":
      imagen = "https://pulosfDevelopers.b-cdn.net/iconosWidgets/survey.png";
      break;
    case "academia":
      imagen =
        "https://pulosfDevelopers.b-cdn.net/iconosWidgets/mortarboard.png";
      break;
    case "iframe":
      imagen = "https://pulosfDevelopers.b-cdn.net/iconosWidgets/coding.png";
      break;
    case "mapas":
      imagen = "https://pulosfDevelopers.b-cdn.net/iconosWidgets/mundo.png";
      break;
    case "listados":
      imagen = "https://pulosfDevelopers.b-cdn.net/iconosWidgets/menu.png";
      break;
    case "pasarelas":
      imagen = "https://octopusmultinivel.b-cdn.net/octopus10/credit-card.png";
      break;
    case "acciones":
      imagen = "https://pulosfDevelopers.b-cdn.net/iconosWidgets/shuttle.png";
      break;
    case "builder":
      imagen =
        "https://pulosfDevelopers.b-cdn.net/iconosWidgets/brick-wall.png";
      break;
    case "tabla-v2":
      imagen =
        "https://pulosfDevelopers.b-cdn.net/iconosWidgets/mesa-de-edicion.png";
      break;
    // case "marlinBoton":
    //   imagen =
    //     "https://pulosfDevelopers.b-cdn.net/iconosWidgets/brick-wall.png";
    //   break;
    default:
      imagen =
        "https://octopusmultinivel.b-cdn.net/octopus10/question-mark.svg";
      break;
  }
  return imagen;
}

function ListadoWidgetsBloques(props) {
  return (
    <>
      <div
        onClick={() => {
          props.showDrawer("pdf");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="pdf"
          src="https://octopusmultinivel.b-cdn.net/octopus10/pdf.svg"
        />
        <span>Pdf</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("indicadores");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="indicadores"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/economy.png"
        />
        <span>Indicadores</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("tranferNumero");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="tranferNumero"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/number-blocks.png"
        />
        <span>Numero</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("encuestas");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="encuestas"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/survey.png"
        />
        <span>Encuestas</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("academia");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="academia"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/mortarboard.png"
        />
        <span>Academia</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("iframe");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="iframe"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/coding.png"
        />
        <span>Iframe</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("mapas");
        }}
        className="item-lista-widgets"
      >
        <img
          width="30px"
          style={{ marginRight: 15 }}
          alt="mapa"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/mundo.png"
        />
        <span>Mapas</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("pasarelas");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="pasarelas"
          src="https://octopusmultinivel.b-cdn.net/octopus10/credit-card.png"
        />
        <span>Pasarelas</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("listados");
        }}
        className="item-lista-widgets"
      >
        <img
          width="30px"
          style={{ marginRight: 15 }}
          alt="mapa"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/menu.png"
        />
        <span>Listados</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("acciones");
        }}
        className="item-lista-widgets"
      >
        <img
          width="30px"
          style={{ marginRight: 15 }}
          alt="acciones"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/shuttle.png"
        />
        <span>Acciones</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("builder");
        }}
        className="item-lista-widgets"
      >
        <img
          width="30px"
          style={{ marginRight: 15 }}
          alt="builder"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/brick-wall.png"
        />
        <span>Builder</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("tabla-v2");
        }}
        className="item-lista-widgets"
      >
        <img
          width="30px"
          style={{ marginRight: 15 }}
          alt="tabla-v2"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/mesa-de-edicion.png"
        />
        <span>Tabla v2</span>
      </div>
      {/* <div
        onClick={() => {
          props.showDrawer("marlinBoton");
        }}
        className="item-lista-widgets"
      >
        <img
          width="30px"
          style={{ marginRight: 15 }}
          alt="marlinBoton"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/brick-wall.png"
        />
        <span>MarlinBoton</span>
      </div> */}
    </>
  );
}

export { IconosWidgetsComponenteDev1, ListadoWidgetsBloques };
