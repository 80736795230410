import { Form, Input, Select, Switch } from "antd";
import { store } from "../../../../store";

function AccionActualizarCampoPerfil() {
  return (
    <>
      <Form.Item
        label="Campo"
        name="campo"
        hasFeedback
        rules={[{ required: true, message: "Campo obligatorio" }]}
      >
        <Select>
          {store.cuenta.formularios.find(
            (e) => e.nombre === "Nuevo registro"
          ) &&
            store.cuenta.formularios
              .find((e) => e.nombre === "Nuevo registro")
              .campos.map((e) => {
                return <Select.Option value={e.name}>{e.name}</Select.Option>;
              })}
        </Select>
      </Form.Item>
      <Form.Item name="explicacion" label="Explicación">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="unsolocambio"
        label="Un solo cambio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </>
  );
}

export default AccionActualizarCampoPerfil;
