import { Button } from "antd";
import React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Api } from "../../../../../api/configApi";
import { InputT } from "../../../../../plugins/Formularios/FormularioTecclas";
import FormularioMaterialDescargable from "./FormularioMaterialDescargable";

const FormularioCrearLeccion = (props) => {
  
  const methods = useForm();

  function onSubmit(values) {
    props.setVisible(false);
    methods.reset("");
    crearLeccion(values);
  }

  function crearLeccion(values) {
    const infoCurso = props.data;
    delete infoCurso.lecciones;

    Api(
      "academia",
      "crearLeccion",
      { idWidget: props.infWid._id },
      { leccionInfo: values, cursoInfo: infoCurso },
      "https://ed3a-181-132-3-197.ngrok.io"
    )
      .then((res) => {
       props.obtenerLecciones()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  React.useEffect(() => {}, [props]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>Ubicación</label>
            <InputT
              control={methods.control}
              name="idLeccion"
              placeholder="Ingresa la ubicación de tu lección"
              rules={{ required: false }}
            ></InputT>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>Título de la lección</label>
            <InputT
              control={methods.control}
              name="tituloLeccion"
              placeholder="Escribe el título de la lección"
              rules={{ required: true }}
            ></InputT>
            {methods.formState.errors.tituloLeccion && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>
              Descripción de la lección
            </label>
            <InputT
              control={methods.control}
              name="descripcionLeccion"
              placeholder="Escribe la descripción de la lección"
              rules={{ required: true }}
            ></InputT>
            {methods.formState.errors.descripcionLeccion && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>
              URL del video de la lección
            </label>
            <InputT
              control={methods.control}
              name="urlVideo"
              placeholder="Escribe la URL del video de la lección"
              rules={{ required: true }}
            ></InputT>

            {methods.formState.errors.urlVideo && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}

              <FormularioMaterialDescargable></FormularioMaterialDescargable>

          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px 0 20px 0",
            }}
          >
            <Button type="secondary" htmlType="htmlType">
              Guardar
            </Button>
            <Button type="secondary" danger onClick={() => props.setVisible(false)}>
              Cancelar
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default FormularioCrearLeccion;
