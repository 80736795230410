import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Select, Switch, Button, notification, Space } from "antd";
import React from "react";
import { Api } from "../../../api/configApi";
import { setPensando, store } from "../../../store";

const { Option } = Select;

function CrearActualizarFormulario(props) {
  const [err, setError] = React.useState("");
  const { modulo } = props;
  const Formulario = React.useRef();
  const [form] = Form.useForm();
  React.useEffect(() => {
    form.setFieldsValue({
      boton: props.formularioActual.boton,
      campos: props.formularioActual.campos,
      verificarEmail: props.formularioActual?.verificarEmail,
      ingresoUsuario: props.formularioActual?.ingresoUsuario,
    });
  }, [form]);
  function guardarForm() {
    setPensando(true);
    const Valores = form.getFieldsValue();
    const campos = Valores.campos;
    campos.forEach((cam) => {
      if (cam.creador !== "admin") {
        cam.editable = true;
        cam.eliminable = true;
      }
    });
    const namesUnicos = new Set(campos.map((campo) => campo.name));
    if ([...namesUnicos].length !== campos.length) {
      setError("El atributo NAME no se puede repetir en ningún campo");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (Valores.campos.length > 0) {
      Api(
        "cuentas",
        "UpdateFormularios",
        { _id: store.cuentaactiva },
        {
          boton: Valores.boton,
          id: props.formularioActual._id,
          campos: campos,
          verificarEmail: Valores.verificarEmail,
          ingresoUsuario: Valores.ingresoUsuario,
        }
      ).then((res) => {
        // console.log(res);
        if (res.success) {
          setPensando(false);
          props.cerrarDrawerForm();
        } else {
          setPensando(false);
          notification.error({
            message: "Error",
            description:
              "No pudimos guardar tus cambios. Revisa la configuración o contacta con soporte.",
          });
        }
      });
    }
  }
  function esEditable(index) {
    const editable = form.getFieldsValue().campos[index].editable;
    if (editable || editable === undefined) {
      return false;
    } else {
      return true;
    }
  }
  function esEliminable(index) {
    const eliminable = form.getFieldsValue().campos[index].eliminable;
    if (eliminable || eliminable === undefined) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div className="camposRequeridos">
      <Form form={form} onFinish={guardarForm} ref={Formulario}>
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          label="Botón"
          name="boton"
        >
          <Input placeholder="Texto Boton" />
        </Form.Item>
        {props.formularioActual.nombre === "Nuevo registro" && (
          <Space style={{ width: "100%" }}>
            <Form.Item
              label="Verificar Email"
              name="verificarEmail"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Ingreso con Usuario"
              name="ingresoUsuario"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Space>
        )}

        <Form.Item hidden name="id">
          <Input />
        </Form.Item>

        <strong style={{ marginTop: 15 }}>Campos:</strong>
        <p></p>
        <Form.List name="campos">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    marginBottom: 8,
                    alignItems: "center",
                  }}
                  align="baseline"
                >
                  <Form.Item
                    colon={false}
                    {...restField}
                    name={[name, "label"]}
                    fieldKey={[fieldKey, "label"]}
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                    label="Label"
                    style={{
                      flex: 2,
                      marginRight: 4,
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    {...restField}
                    name={[name, "name"]}
                    fieldKey={[fieldKey, "name"]}
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                    label="Name"
                    style={{
                      flex: 2,
                      marginRight: 4,
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Input disabled={esEditable(index)} />
                  </Form.Item>

                  <Form.Item
                    colon={false}
                    {...restField}
                    name={[name, "type"]}
                    fieldKey={[fieldKey, "type"]}
                    label="Tipo"
                    style={{
                      flex: 2,
                      marginRight: 4,
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                  >
                    <Select
                      disabled={esEditable(index)}
                      style={{ minWidth: 150 }}
                    >
                      <Option value="text">Texto</Option>
                      <Option value="textarea">Area de texto</Option>
                      <Option value="number">Numero</Option>
                      <Option value="checkbox">Checkbox</Option>
                      <Option value="select">Selección</Option>
                      <Option value="email">Correo Electronico</Option>
                      <Option value="password">Contraseña</Option>
                      <Option value="file">Archivo</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    shouldUpdate
                    style={{
                      display: "flex",
                      marginBottom: 0,
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    {({ getFieldValue }) => {
                      return (
                        getFieldValue().campos[index].type === "select" && (
                          <Form.Item
                            {...restField}
                            name={[name, "opciones"]}
                            fieldKey={[fieldKey, "opciones"]}
                            label="Opciones"
                            style={{
                              flex: 2,
                              marginRight: 4,
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                          >
                            <Input
                              disabled={esEditable(index)}
                              placeholder="Separados por Comas"
                            />
                          </Form.Item>
                        )
                      );
                    }}
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    {...restField}
                    name={[name, "validaciones"]}
                    fieldKey={[fieldKey, "validaciones"]}
                    rules={[{ required: false }]}
                    label="Validaciones"
                    style={{
                      flex: 2,
                      marginRight: 4,
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Input disabled={esEditable(index)} placeholder="regexp" />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    className="inputCentro"
                    {...restField}
                    name={[name, "required"]}
                    fieldKey={[fieldKey, "required"]}
                    label="Requerido"
                    style={{
                      flex: 1,
                      marginRight: 4,
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Switch
                      disabled={esEditable(index)}
                      style={{ alignSelf: "center" }}
                      defaultChecked={
                        form.getFieldValue().campos[index].required
                      }
                    />
                  </Form.Item>

                  {esEliminable(index) && (
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faMinusCircle}
                      onClick={() => remove(name)}
                    />
                  )}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add({ required: true, type: "text" })}
                  block
                  icon={<FontAwesomeIcon icon={faPlusCircle} />}
                >
                  Agregar Campo
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <p style={{ color: "red" }}>{err}</p>

        <Button disabled={store.pensando} type="primary" htmlType="submit">
          Guardar Formulario
        </Button>
      </Form>
    </div>
  );
}

export default CrearActualizarFormulario;
