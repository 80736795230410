import React from "react";
import { Form, Input, Switch, Select, InputNumber, Space } from "antd";
import Boton from "../../../design/elementos/boton";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import { ObjectId } from "bson";
const rules = [{ required: true, message: "Campo Requerido" }];

function CrearActualizarDebitoCredito(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();
  const guardarWidget = (values) => {
    values.tipo = "debitoCredito";
    setPensando(true);
    setLoading(true);
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    // console.log(store.usuario);
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  }, [props.data]);
  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item label="Nombre" name="nombre" hasFeedback rules={rules}>
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>
        <Form.Item name="dinamico" hidden rules={rules}></Form.Item>

        <Form.Item label="Titulo" name="titulo" hasFeedback rules={rules}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={rules}
        >
          <Input />
        </Form.Item>
        <Form.Item rules={rules} name="tipoUsuario" label="Tipo de Widget">
          <Select>
            <Option value="admin">Administrador</Option>
            <Option value="usuario">Usuario</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="tipoWidget"
          label="Tipo"
          initialValue="credito"
          rules={rules}
        >
          <Select>
            <Option value="creditos">Créditos</Option>
            <Option value="debitos">Débitos</Option>
          </Select>
        </Form.Item>

        <Form.Item rules={rules} name="atributo" label="Atributo">
          <Select>
            {store.losatributos.numbers.map((e) => {
              return <Option value={e.nombre}>{e.nombre}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(a, b) => a.tipoUsuario !== b.tipoUsuario}
        >
          {({ getFieldsValue }) => {
            return (
              getFieldsValue().tipoUsuario === "usuario" && (
                <>
                  <Form.Item
                    valuePropName="checked"
                    name="aprobacion"
                    label="Debe ser aprobado"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    valuePropName="checked"
                    name="confirmacionEmail"
                    label="Enviar PIN de seguridad al correo del usuario"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    name="textoBoton"
                    label="Texto Botón"
                    rules={rules}
                    initialValue="Solicitar"
                  >
                    <Input />
                  </Form.Item>
                  <Space>
                    <Form.Item
                      name="maximo"
                      label="Monto Máximo"
                      initialValue={0}
                      rules={rules}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                      name="maximoTipo"
                      label="_"
                      style={{ width: 130 }}
                      rules={rules}
                    >
                      <Select>
                        <Select.Option value="porcentaje">
                          Porcentaje
                        </Select.Option>
                        <Select.Option value="unidad">Unidad</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="maximoAtributo"
                      label="_"
                      style={{ width: 130 }}
                      rules={rules}
                    >
                      <Select>
                        {store.losatributos.numbers.map((e) => {
                          return <Option value={e.nombre}>{e.nombre}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Space>
                  <Space>
                    <Form.Item
                      name="minimo"
                      label="Monto Minimo"
                      initialValue={1}
                      rules={rules}
                    >
                      <InputNumber min={1} type="number" />
                    </Form.Item>
                    <Form.Item
                      name="minimoTipo"
                      label="_"
                      style={{ width: 130 }}
                      rules={rules}
                    >
                      <Select>
                        <Select.Option value="porcentaje">
                          Porcentaje
                        </Select.Option>
                        <Select.Option value="unidad">Unidad</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="minimoAtributo"
                      label="_"
                      style={{ width: 130 }}
                      rules={rules}
                    >
                      <Select>
                        {store.losatributos.numbers.map((e) => {
                          return <Option value={e.nombre}>{e.nombre}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Space>
                  <Space>
                    <Form.Item
                      name="feePorcentaje"
                      label="Fee Porcentaje"
                      initialValue={0}
                      rules={rules}
                    >
                      <InputNumber min={0} type="number" />
                    </Form.Item>
                    <Form.Item
                      name="feeUnidad"
                      label="Fee Unidad"
                      initialValue={0}
                      rules={rules}
                    >
                      <InputNumber min={0} type="number" />
                    </Form.Item>
                  </Space>
                  <Space>
                    <Form.Item
                      name="maximosRetiros"
                      label="Cantidad máxima de retiros"
                      initialValue={0}
                      rules={rules}
                    >
                      <InputNumber min={0} type="number" />
                    </Form.Item>
                    <Form.Item
                      name="maximosRetirosTipo"
                      label="_"
                      style={{ width: 130 }}
                      rules={rules}
                    >
                      <Select>
                        <Select.Option value="diarios">Diarios</Select.Option>
                        <Select.Option value="mensuales">
                          Mensuales
                        </Select.Option>
                        <Select.Option value="semanal">
                          Semanales
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Space>

                  <Space>
                    <Form.Item
                      name="retirosHabilitados"
                      label="Retiros habilitados"
                      initialValue={true}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    <Form.Item
                      name="totalMaximosRetiros"
                      label="Lo máximo en total que pueden retirar - al llegar al tope se deshabilitan (toda la cuenta)"
                      initialValue={0}
                      rules={rules}
                    >
                      <InputNumber min={0} type="number" />
                    </Form.Item>
                  </Space>

                  <Form.Item name="indicaciones" label="Algunas indicaciones">
                    <Input.TextArea />
                  </Form.Item>
                </>
              )
            );
          }}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(a, b) =>
            a.tipoUsuario !== b.tipoUsuario || a.tipoWidget !== b.tipoWidget
          }
        >
          {({ getFieldsValue }) => {
            return getFieldsValue().tipoWidget === "debitos" &&
              getFieldsValue().tipoUsuario === "usuario" ? (
              <>
                <Form.Item
                  name="tipoDebito"
                  label="Tipo de debito"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona una opción",
                    },
                  ]}
                >
                  <Select>
                    <Option value="manual">Manual</Option>
                    <Option value="automaticoCoinpayments">
                      Automático Coinpayments
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldsValue }) => {
                    return (
                      getFieldsValue().tipoDebito ===
                        "automaticoCoinpayments" && (
                        <>
                          <Form.Item
                            name="nombreWallet"
                            label="Selecciona el código de tu wallet"
                            rules={[
                              {
                                required: true,
                                message: "Por favor selecciona una opción",
                              },
                            ]}
                          >
                            <Select>
                              {[
                                "Binance Coin - BNB",
                                "Binance Coin - BNB.BSC",
                                "Binance Coin - BNB.ERC20",
                                "Bitcoin - BTC",
                                "Bitcoin cash - BCH",
                                "Cardano - ADA",
                                "Chainlink - LINK",
                                "Dogecoin - DOGE",
                                "Ethereum, Ether - ETH",
                                "Litecoin - LTC",
                                "Litecoin Testnet - LTCT",
                                "Polkadot - DOT",
                                "Ripple - XRP",
                                "Stellar - XLM",
                                "Tether - USDT.BEP2",
                                "Tether - USDT.BEP20",
                                "Tether - USDT.ERC20",
                                "Tether - USDT.SOL",
                                "Tether - USDT.TRC20",
                                "Tezos - XTZ",
                                "Uniswap - UNI",
                                "USD Coin - USDC",
                              ].map((e) => {
                                return <Option value={e}>{e}</Option>;
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name="monedaConversion"
                            label="Convertir desde"
                            rules={[
                              {
                                required: true,
                                message: "Por favor selecciona una opción",
                              },
                            ]}
                          >
                            <Select>
                              {["No convertir", "COP", "USD", "EUR", "MX"].map(
                                (e) => {
                                  return <Option value={e}>{e}</Option>;
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </>
                      )
                    );
                  }}
                </Form.Item>
              </>
            ) : null;
          }}
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Boton loading={loading} texto="Guardar" htmlType="submit" />
            <Boton
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              texto="Cancelar"
              tipo="danger"
              htmlType="button"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarDebitoCredito;
