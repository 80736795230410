import { observer } from "mobx-react";
import React from "react";

import { Button, Modal } from "antd";
import CoinPaymentsModal from "./modales/CoinPaymentsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faCommentDollar,
  faShoppingCart,
  faU,
  faUnderline,
} from "@fortawesome/free-solid-svg-icons";
import { store } from "../../../../../store";
import Boton from "../../../../../components/design/elementos/boton";
import TatumModal from "./modales/TatumModal";

function DesignWidgetPasarelas(props) {
  const [widget, SetWidget] = React.useState();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const refEstiloBot = React.useRef({});
  const [estiloBot, setEstiloBot] = React.useState({});


  React.useEffect(() => {
    // console.log(props, "PASARELAAAAA")
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    SetWidget(widgetActual);
    // console.log(widgetActual, "widget acutal");
  }, [props.widget]);

  React.useEffect(() => {
    if (widget) {
      let estiloBoton = widget.estiloBoton;
      if (estiloBoton) {
        estiloBoton = JSON.parse(`{${estiloBoton}}`);
        refEstiloBot.current = estiloBoton;
        setEstiloBot(refEstiloBot.current);
      }
    }
  }, [widget]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return widget ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="widgetShadow"
    >

      {widget?.listaIntegraciones === "coinpayments" && (
        <>
          <Boton
            type="primary"
            icon={<FontAwesomeIcon icon={faCoins} style={{ marginRight: 10 }} />}
            style={{ margin: 20, ...estiloBot }}
            onClick={() => {
              showModal();
              // console.log(widget, "tronco")
            }}
            disabled={
              !(widget?.monedaEscogidaMostrar && widget?.listaListados) && true
            }
          >
            {widget?.nombreBoton ? widget?.nombreBoton : "Pagar"}
          </Boton>
        </>
      )}

      {widget?.listaIntegraciones === "tatu" && (
        <>
          <Boton
            type="primary"
            icon={<FontAwesomeIcon icon={faCoins} style={{ marginRight: 10 }} />}
            style={{ margin: 20, ...estiloBot }}
            onClick={() => {
              showModal();
              // console.log(widget, "tronco")
            }}
            disabled={
              false
            }
          >
            {widget?.nombreBoton ? widget?.nombreBoton : "Pagar"}
          </Boton>
        </>
      )}

      <Modal
        destroyOnClose
        title="Pasarela de pago"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="800px"
        footer={[<Button onClick={handleCancel}>Cerrar</Button>]}
      >
        {(widget?.listaIntegraciones === "coinpayments") && (
          <>
            <CoinPaymentsModal
              props2={{ widget: widget }}
              funcion={handleOk}
              modalVisible={isModalVisible}
            />
          </>
        )}

        {widget?.listaIntegraciones === "tatu" && (
          <>
            <TatumModal
              handleCancel = {handleCancel}
              props2={{ widget: widget }}
              funcion={handleOk}
              modalVisible={isModalVisible}
            />
          </>
        )}

        {!(widget?.listaIntegraciones) && (
          <>
            <p>Integración no seleccionada</p>
          </>
        )}

      </Modal>
    </div>
  ) : null;
}

export default observer(DesignWidgetPasarelas);
