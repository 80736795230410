import { message } from "antd";
import React from "react";
import { Api } from "../../api/configApi";
import { ButtonT } from "../../plugins/Formularios/FormularioTecclas";
import Modal from "../../plugins/Modal";
import { store } from "../../store";

const MonedaActivar = (props) => {
  const [mostrarActivarDesactivar, setMostrarActivarDesactivar] =
    React.useState(!props.estado);
  const [mostrar, setMostrar] = React.useState(false);
  const refMostrar = React.useRef();
  const refCargandoActivar = React.useRef();
  const [cargandoActivar, setCargandoActivar] = React.useState(false);
  const refCargandoDesactivar = React.useRef();
  const [cargandoDesactivar, setCargandoDesactivar] = React.useState(false);
  const refMnemonic = React.useRef();
  const [mnemonic, setMnemonic] = React.useState();
  const refDireccion = React.useRef();
  const [direccion, setDireccion] = React.useState();
  const [privateKey, setPrivateKey] = React.useState();

  const activar = () => {
    setCargandoActivar(true);
    console.log(props.codigo);
    Api(
      "integracionPasarelaPulsof",
      "generarWallet",
      { moneda: props.codigo.toLowerCase() },
      {},
      "https://841d-181-33-156-128.ngrok.io"
    )
      .then((res) => {
        refCargandoActivar.current = false;
        setCargandoActivar(refCargandoActivar.current);

        if (res.success) {
          setMostrarActivarDesactivar(!mostrarActivarDesactivar);
        }

        refMnemonic.current = res.mnemonic;
        setMnemonic(refMnemonic.current);

        refDireccion.current = res.address;
        setDireccion(refDireccion.current);

        setPrivateKey(res.privateKey);

        console.log(res);
        refMostrar.current = res.mostrar;
        setMostrar(refMostrar.current);
      })
      .catch((err) => console.log(err, "entra al catch"));
  };

  function copy(texto) {
    window.navigator.clipboard?.writeText(texto);
    message.success("Copiado al portapapeles");
  }

  const success = () => {
    message.success({
      content: "Moneda desactivada",
      className: "custom-class",
      style: {
        marginTop: "50vh",
      },
    });
  };
  const alerta = () => {
    message.error({
      content:
        "Para desactivar esta moneda, debes asegurarte de que ningún widget la esté utilizando",
      className: "custom-class",
      style: {
        marginTop: "50vh",
      },
    });
  };

  function desactivarMoneda(moneda) {
    let bandera = true;
    let widTatu = store.widgets.filter(
      (el) => el.tipo == "pasarelas" && el.listaIntegraciones == "tatu"
    );
    widTatu.forEach((el) => {
      console.log(el, "widgets tipo tatu");
      el.monedasPermitidas.forEach((mon) => {
        console.log(
          mon.moneda.slice(mon.moneda.indexOf("-") + 1, mon.moneda.length),
          "-----MONEDA EN USO"
        );

        if (
          mon.moneda.slice(mon.moneda.indexOf("-") + 1, mon.moneda.length) ==
          moneda
        ) {
          bandera = false;
        }
      });
    });

    if (bandera) {
      console.log("PERMITE DESACTIVAR: SIIII");
      console.log("si pasaaaa");
      setCargandoDesactivar(true);
      Api(
        "integracionPasarelaPulsof",
        "cambiarEstadoMoneda",
        { moneda: props.codigo.toLowerCase() },
        {},
        "https://841d-181-33-156-128.ngrok.io"
      )
        .then((res) => {
          console.log(res);
          success();
          refCargandoDesactivar.current = false;
          setCargandoDesactivar(refCargandoDesactivar.current);
          setMostrarActivarDesactivar(!mostrarActivarDesactivar);
        })
        .catch((err) => console.log(err, "entra al catch"));
    } else {
      alerta();
      console.log("PERMITE DESACTIVAR: NOOOO");
      console.log("NO PUEDE PASAR");
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
      key={props.index * 32324}
    >
      <p>{props.nombre}</p>
      {mostrarActivarDesactivar && (
        <ButtonT
          loading={cargandoActivar}
          style={{ width: "150px" }}
          onClick={() => {
            activar();
          }}
        >
          Activar
        </ButtonT>
      )}

      {!mostrarActivarDesactivar && (
        <ButtonT
          loading={cargandoDesactivar}
          style={{ width: "150px" }}
          onClick={() => {
            desactivarMoneda(props.codigo.toLowerCase());
          }}
        >
          Desactivar
        </ButtonT>
      )}

      <Modal mostrar={mostrar} setMostrar={setMostrar} ancho="auto" alto="auto">
        <div>
          <div style={{ padding: "30px 30px 0 30px" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                margin: "0 0 20px 0",
                color: "red",
              }}
            >
              ¡ADVERTENCIA!
            </p>
            <p style={{ textAlign: "center" }}>
              hola a tros es ren unmensha ede advnttecua adi qwue dbeem estatrn
              erefparados par salir corridndo en cjso de qjue haya un
              aoicalusosi hola a tros es ren unmensha ede advnttecua adi qwue
              dbeem estatrn erefparados par salir corridndo en cjso de qjue haya
              un aoicalusosi
            </p>
            <p style={{ textAlign: "center" }}>PRIVATE KEY:</p>
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              {privateKey}
            </p>
            <p style={{ textAlign: "center" }}>DIRECCIÓN:</p>
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              {direccion}
            </p>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <ButtonT onClick={() => copy(direccion)}>
                Copiar dirección
              </ButtonT>
            </div>
            <p style={{ textAlign: "center", margin: "15px 0" }}>
              FRASE DE RECUPERACIÓN:
            </p>
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              {mnemonic}
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ButtonT onClick={() => copy(mnemonic)}>Copiar frase</ButtonT>
            </div>
          </div>
          <div
            style={{
              padding: "30px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <ButtonT
              onClick={() => {
                setMostrar(false);
                console.log("SI QUIERO ACTIVAR MONEDA");
              }}
            >
              Aceptar
            </ButtonT>
            {/* <ButtonT
              onClick={() => {
                setMostrarActivarDesactivar(!mostrarActivarDesactivar);
                setMostrar(false);
              }}
            >
              Cancelar
            </ButtonT> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MonedaActivar;
