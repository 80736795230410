import React, { memo } from "react";
import { setPensando, store } from "../../../../../store";
import {
  Drawer,
  message,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
} from "antd";
import { Api } from "../../../../../api/configApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Boton from "../../../../design/elementos/boton";
import FormSolicitarDebitoCredito from "./FormSolicitarDebitoCredito";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
import {
  faCheckCircle,
  faCircle,
  faTimes,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import EditarPerfilAfiliado from "../../tabla/perfilAfiliado/EditarPerfilAfiliado";
import FormAprobarMasivamente from "./FormAprobarMasivamente";
Moment.globalMoment = moment;
Moment.globalLocale = "es";

function DesignWidgetDebitoCredito(props) {
  const [widget, setWidget] = React.useState({});
  const [DrawerSolicitud, setDrawerSolicitud] = React.useState(false);
  const [solicitudes, setSolicitudes] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const [drawerPerfil, setDrawerPerfil] = React.useState(false);
  const [drawerAprobarMasivo, setDrawerAprobarMasivo] = React.useState(false);
  const [idAfiliado, setIdAfiliado] = React.useState("null");
  const [cuales, setCuales] = React.useState("todos");

  function cancelar(el) {
    setPensando(true);
    Api(
      "multiData",
      "UpdateDebitoCredito",
      { _id: el._id, widget: widget._id },
      { estado: "cancelada" }
    ).then((res) => {
      if (res.success) {
        setPensando(false);
        Get(widget);
        message.success("Actualizado");
      } else {
        setPensando(false);
        message.error("Algo no salio bien");
      }
    });
  }
  function aprobar(el) {
    setPensando(true);
    Api(
      "multiData",
      "UpdateDebitoCredito",
      { _id: el._id, widget: widget._id },
      { estado: "aprobada", monto: el.monto }
    ).then((res) => {
      if (res.success) {
        setPensando(false);
        Get(widget);
        message.success("Actualizado");
      } else {
        setPensando(false);
        message.error("Algo no salio bien");
      }
    });
  }
  function Get(widgetActual, cual) {
    setCargando(true);
    Api("multiData", "GetDebitoCredito", {
      widget: widgetActual._id,
      cuales: cual || cuales,
    }).then((res) => {
      setCargando(false);
      if (res.success) {
        setSolicitudes(res.datos);
      } else {
        message.error("Algo no salio bien.");
        setCargando(false);
      }
    });
  }
  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
    Get(widgetActual);
  }, [props]);
  React.useEffect(() => {
    if (widget) {
    }
  }, [widget]);

  const columns = [
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "Recibe",
      dataIndex: "Recibe",
      key: "Recibe",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (e) => {
        var color = "";
        switch (e) {
          case "pendiente":
            color = "";
            break;
          case "cancelada":
            color = "red";
            break;
          case "aprobada":
            color = "green";
            break;
          default:
            break;
        }
        return <Tag color={color}>{e}</Tag>;
      },
    },
    {
      title: "Registrada",
      dataIndex: "fecha",
      key: "fecha",
      render: (e) => <Moment fromNow>{e}</Moment>,
    },
    {
      title: "Actualizada",
      dataIndex: "actualizado",
      key: "actualizado",
      render: (e) => <Moment fromNow>{e}</Moment>,
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (e, a) => (
        <Space size="middle">
          {a.estado !== "aprobada" &&
            a.estado !== "cancelada" &&
            a.estado !== "Procesando" && (
              <>
                <Popconfirm
                  title="¿Deseas cancelar?"
                  onConfirm={() => {
                    cancelar(a);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ cursor: "pointer", fontSize: 18 }}
                  />
                </Popconfirm>
                {widget.tipoUsuario === "admin" && (
                  <>
                    <Popconfirm
                      title="¿Deseas Aprobarla?"
                      onConfirm={() => {
                        aprobar(a);
                      }}
                    >
                      <FontAwesomeIcon
                        color="green"
                        icon={faCheckCircle}
                        style={{ cursor: "pointer", fontSize: 18 }}
                      />
                    </Popconfirm>
                  </>
                )}
              </>
            )}
          {widget.tipoUsuario === "admin" && (
            <FontAwesomeIcon
              icon={faUser}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIdAfiliado(a.usuario);
                setDrawerPerfil(true);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }} className="widgetShadow">
      <div style={estilos.contenido}>
        <Drawer
          width={800}
          visible={drawerPerfil}
          onClose={() => setDrawerPerfil(false)}
          destroyOnClose
        >
          <EditarPerfilAfiliado infoElemento={{ _id: idAfiliado }} />
        </Drawer>
        <Drawer
          title={widget.textoBoton}
          visible={DrawerSolicitud}
          onClose={() => {
            setDrawerSolicitud(false);
          }}
          destroyOnClose
        >
          <FormSolicitarDebitoCredito
            widget={widget}
            setDrawerSolicitud={setDrawerSolicitud}
            Get={Get}
          />
        </Drawer>
        <div style={{ textAlign: "right", marginBottom: 10 }}>
          <Boton
            onClick={() => {
              setDrawerSolicitud(true);
            }}
            texto={widget.textoBoton}
          />
        </div>
        {widget.tipoUsuario === "admin" && (
          <div style={{ textAlign: "right", marginBottom: 10 }}>
            <Drawer
              title={widget.textoBoton}
              visible={drawerAprobarMasivo}
              destroyOnClose
            >
              <FormAprobarMasivamente
                widget={widget}
                setDrawerAprobarMasivo={setDrawerAprobarMasivo}
                Get={Get}
              />
            </Drawer>
            <Boton
              onClick={() => {
                setDrawerAprobarMasivo(true);
              }}
              texto="Aprobar masivamente"
            />
          </div>
        )}
        <Segmented
          options={[
            "Todos",
            "Aprobado",
            "Cancelado",
            "Procesando",
            "Pendiente",
          ]}
          value={cuales}
          onChange={(e) => {
            setCuales(e);
            Get(widget, e);
          }}
        />
        <Table loading={cargando} dataSource={solicitudes} columns={columns} />
      </div>
    </div>
  );
}
const estilos = {
  contenido: {
    display: "flex",
    flexDirection: "column",
    fontSize: 30,
    fontWeight: 400,
  },
};
export default memo(DesignWidgetDebitoCredito);
