import { Widget } from "@uploadcare/react-widget";
import { Form, message, Modal, Select } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../../components/design/elementos/boton";
import { store } from "../../../../../store";

function ActualizarAtributo(props) {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  function actualizar(values) {
    setLoading(true);
    Api("widgets", "acciones", {
      accion: "actualizarAtributo",
      datos: values,
      widget: props.widget._id,
    }).then((res) => {
      if (res.success) {
        message.success("Atributo actualizado");
        setVisible(false);
        // refrescar pagina
        window.location.reload();
      } else {
        message.error("Error al actualizar");
      }
      setLoading(false);
    });
  }
  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => {
          form.submit();
        }}
        okText="Guardar configuración"
        cancelText="Cancelar"
        okButtonProps={{
          loading: loading,
          style: {
            backgroundColor: store.colores.botones[0],
            border: "none",
          },
        }}
      >
        <div style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}>
          Selecciona una opción
        </div>
        <div dangerouslySetInnerHTML={{ __html: props.widget.explicacion }} />
        <Form form={form} onFinish={actualizar}>
          <Form.Item
            name="opcion"
            rules={[
              {
                required: true,
                message: "Campo obligatorio",
              },
            ]}
          >
            <Select>
              {store.losatributos.texts
                .find((e) => e._id === props.widget.atributo)
                ?.opciones.map((e) => (
                  <Select.Option value={e}>{e}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Boton onClick={() => setVisible(true)}>
        {props?.widget?.textoBoton}
      </Boton>
    </>
  );
}

export default ActualizarAtributo;
