import React from "react";
import { Form, Input, Select, Button, InputNumber, Space } from "antd";

import { ObjectId } from "bson";

import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import AccionInvitarRegistro from "./accionInvitarRegistro";
import AccionActualizarAtributo from "./accionActualizarAtributo";
import AccionActualizarCampoPerfil from "./accionActualizarCampoPerfil";

function CrearActualizarWidgetAcciones(props) {
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();

  const guardarWidget = (values) => {
    values.tipo = "acciones";
    setPensando(true);
    setLoading(true);

    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  }, [props.visible]);

  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  }, [props.data]);

  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>
        <Form.Item
          name="dinamico"
          hidden
          rules={[{ required: true }]}
        ></Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="accion"
          label="Acción"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Select>
            <Select.Option value="invitarRegistro">
              Invitar afiliado
            </Select.Option>
            <Select.Option value="actualizarAtributo">
              Actualizar Atributo
            </Select.Option>
            <Select.Option value="actualizarCampoPerfil">
              Actualizar Campo perfil
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {() => {
            const { getFieldValue } = form;
            if (getFieldValue("accion") === "invitarRegistro") {
              return <AccionInvitarRegistro />;
            }
            if (getFieldValue("accion") === "actualizarAtributo") {
              return <AccionActualizarAtributo />;
            }
            if (getFieldValue("accion") === "actualizarCampoPerfil") {
              return <AccionActualizarCampoPerfil />;
            }
          }}
        </Form.Item>

        <Form.Item
          label="Texto botón"
          name="textoBoton"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un texto" }]}
        >
          <Input />
        </Form.Item>

        <p></p>
        <Button loading={loading} htmlType="submit">
          Guardar
        </Button>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetAcciones;
