import { Button } from "antd";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { store } from "../../../../../store";
import {
  OptionT,
  RangePickerT2,
  SelectT,
} from "../../../../../plugins/Formularios/FormularioTecclas";
import HijoListConsultasAfiliadoAfiliado from "./HijoListConsultasAfiliadoAfiliado";
import HijoListConsultasAfiliadoAtributo from "./HijoListConsultasAfiliadoAtributo";
import HijoListConsultasAfiliadoListado from "./HijoListConsultasAfiliadoListado";
import HijoListConsultasAtributoAfiliado from "./HijoListConsultasAtributoAfiliado";
import HijoListConsultasAtributoAtributo from "./HijoListConsultasAtributoAtributo";
import HijoListConsultasListadoAfiliado from "./HijoListConsultasListadoAfiliado";
import HijoListConsultasListadoListado from "./HijoListConsultasListadoListado";

const ListConsultas = (props) => {
  const {
    control,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const refListados = React.useRef([]);
  const [listados, setListados] = React.useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "consultas",
  });

  function hijoListConsulta(index) {
    if (
      watch("listar") === "atributos" &&
      watch(`consultas.${index}.coleccionElegida`) === "atributos"
    ) {
      return (
        <>
          <HijoListConsultasAtributoAtributo
            formu={props.formu}
            indice={index}
          />
        </>
      );
    } else if (
      watch("listar") === "afiliados" &&
      watch(`consultas.${index}.coleccionElegida`) === "atributos"
    ) {
      return (
        <>
          <HijoListConsultasAfiliadoAtributo
            formu={props.formu}
            indice={index}
          />
        </>
      );
    } else if (
      watch("listar") === "listados" &&
      watch(`consultas.${index}.coleccionElegida`) === "listados"
    ) {
      return (
        <>
          <HijoListConsultasListadoListado formu={props.formu} indice={index} />
        </>
      );
    } else if (
      watch("listar") === "afiliados" &&
      watch(`consultas.${index}.coleccionElegida`) === "listados"
    ) {
      return (
        <>
          <HijoListConsultasAfiliadoListado
            formu={props.formu}
            indice={index}
          />
        </>
      );
    } else if (
      watch("listar") === "afiliados" &&
      watch(`consultas.${index}.coleccionElegida`) === "afiliados"
    ) {
      return (
        <>
          <HijoListConsultasAfiliadoAfiliado
            formu={props.formu}
            indice={index}
          />
        </>
      );
    } else if (
      watch("listar") === "listados" &&
      watch(`consultas.${index}.coleccionElegida`) === "afiliados"
    ) {
      return (
        <>
          <HijoListConsultasListadoAfiliado
            formu={props.formu}
            indice={index}
          />
        </>
      );
    } else if (
      watch("listar") === "atributos" &&
      watch(`consultas.${index}.coleccionElegida`) === "afiliados"
    ) {
      return (
        <>
          <HijoListConsultasAtributoAfiliado
            formu={props.formu}
            indice={index}
          />
        </>
      );
    }
  }

  function resetearCamposColeccionElegida(index, valorListar) {
    const fields = getValues();
    if (fields.consultas) {
      delete fields.consultas[index].condiciones;
      delete fields.consultas[index].campo;
      delete fields.consultas[index].obligatorio;
      fields.consultas[index].coleccionElegida = valorListar;
      reset(fields);
    }
  }

  const refAtributos = React.useRef([]);
  const [atributos, setAtributos] = React.useState([]);

  React.useEffect(() => {
    refAtributos.current = [
      ...store.losatributos.dates,
      ...store.losatributos.flags,
      ...store.losatributos.numbers,
      ...store.losatributos.texts,
    ];
    setAtributos(refAtributos.current);
  }, []);

  function condicionTipo(index) {
    if (
      getValues().consultas[index]?.condiciones?.find(
        (cond) =>
          cond?.campo?.slice(
            cond?.campo?.indexOf("-") + 1,
            cond?.campo?.length
          ) == "number"
      ) &&
      getValues().consultas[index]?.condiciones?.find(
        (cond) =>
          cond?.campo?.slice(
            cond?.campo?.indexOf("-") + 1,
            cond?.campo?.length
          ) == "text"
      )
    ) {
      return (
        <>
          {atributos.map((atributo, index2) => {
            if (atributo.tipo != "date") {
              return (
                <>
                  <OptionT
                    key={index2}
                    value={atributo._id + "-" + atributo.tipo}
                  >
                    {atributo.nombre}
                  </OptionT>
                </>
              );
            }
          })}
        </>
      );
    } else if (
      getValues().consultas[index]?.condiciones?.find(
        (cond) =>
          cond?.campo?.slice(
            cond.campo.indexOf("-") + 1,
            cond?.campo?.length
          ) == "number"
      ) &&
      getValues().consultas[index]?.condiciones?.find(
        (cond) =>
          cond?.campo?.slice(
            cond?.campo?.indexOf("-") + 1,
            cond?.campo?.length
          ) == "date"
      )
    ) {
      return (
        <>
          {atributos.map((atributo, index2) => {
            if (atributo.tipo != "text") {
              return (
                <>
                  <OptionT
                    key={index2}
                    value={atributo._id + "-" + atributo.tipo}
                  >
                    {atributo.nombre}
                  </OptionT>
                </>
              );
            }
          })}
        </>
      );
    } else if (
      getValues().consultas[index]?.condiciones?.find(
        (cond) =>
          cond?.campo?.slice(
            cond?.campo?.indexOf("-") + 1,
            cond?.campo?.length
          ) == "text"
      ) &&
      getValues().consultas[index]?.condiciones?.find(
        (cond) =>
          cond?.campo?.slice(
            cond?.campo?.indexOf("-") + 1,
            cond?.campo?.length
          ) == "date"
      )
    ) {
      return (
        <>
          {atributos.map((atributo, index2) => {
            if (atributo.tipo != "number") {
              return (
                <>
                  <OptionT
                    key={index2}
                    value={atributo._id + "-" + atributo.tipo}
                  >
                    {atributo.nombre}
                  </OptionT>
                </>
              );
            }
          })}
        </>
      );
    } else {
      return (
        <>
          {atributos.map((atributo, index2) => {
            return (
              <>
                <OptionT
                  key={index2}
                  value={atributo._id + "-" + atributo.tipo}
                >
                  {atributo.nombre}
                </OptionT>
              </>
            );
          })}
        </>
      );
    }
  }

  function resetearCamposidAtributo(index, valorListar) {
    const fields = getValues();
    if (fields.consultas[index]) {
      if (fields.consultas[index].condiciones) {
        delete fields.consultas[index].condiciones;
        delete fields.consultas[index].obligatorio;
        fields.consultas[index].campo = valorListar;
        reset(fields);
      }
    }
  }

  React.useEffect(() => {
    refListados.current = store.listados;
    setListados(refListados.current);
  }, []);

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <div
              style={{
                margin: "30px 0 20px 0",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              Consulta:
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>
                Selecciona una colección
              </label>
              <SelectT
                control={control}
                name={`consultas.${index}.coleccionElegida`}
                placeholder={"Selecciona un campo"}
                ancho="100%"
                rules={{ required: true }}
                onChange={(e) => {
                  resetearCamposColeccionElegida(index, e);
                }}
              >
                {watch("listar") == "listados" && (
                  <>
                    <OptionT value="afiliados">Afiliados</OptionT>
                    <OptionT value="listados">Listados</OptionT>
                  </>
                )}

                {watch("listar") == "afiliados" && (
                  <>
                    <OptionT value="listados">Listados</OptionT>
                    <OptionT value="afiliados">Afiliados</OptionT>
                    <OptionT value="atributos">Registro de atributos</OptionT>
                  </>
                )}

                {watch("listar") == "atributos" && (
                  <>
                    <OptionT value="afiliados">Afiliados</OptionT>
                    <OptionT value="atributos">Registro de atributos</OptionT>
                  </>
                )}
              </SelectT>
            </div>
            {errors?.["consultas"]?.[index]?.["coleccionElegida"]?.type ==
              "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}

            {watch(`consultas.${index}.coleccionElegida`) == "atributos" &&
              watch("listar") != "atributos" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      margin: "10px 0 10px 0",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ width: "100%", textAlign: "left" }}>
                      Selecciona un atributo
                    </label>
                    <SelectT
                      temporal
                      onChange={(e) => {
                        resetearCamposidAtributo(index, e);
                      }}
                      control={control}
                      name={`consultas.${index}.campo`}
                      placeholder={"Selecciona un atributo"}
                      ancho="100%"
                      rules={{ required: true }}
                    >
                      {getValues().consultas[index]?.condiciones?.length ==
                      0 ? (
                        <>
                          {atributos.map((atributo, index2) => {
                            return (
                              <>
                                <OptionT
                                  key={index2}
                                  value={atributo._id + "-" + atributo.tipo}
                                >
                                  {atributo.nombre}
                                </OptionT>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>{condicionTipo(index)}</>
                      )}
                    </SelectT>
                  </div>
                  {errors?.["consultas"]?.[index]?.["campo"]?.type ==
                    "required" && (
                    <div style={{ color: "red", marginBottom: "20px" }}>
                      Campo requerido
                    </div>
                  )}
                </>
              )}

            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>
                ¿Deseas que todas las condiciones de esta consulta sean
                obligatorias? (Si escoges no, el resultado de la consulta
                dependerá de que al menos una de las condiciones se cumpla):
              </label>
              <SelectT
                control={control}
                name={`consultas.${index}.obligatorio`}
                placeholder={"Elige una opción"}
                ancho="100%"
                rules={{ required: true }}
              >
                <OptionT value="SI">Si</OptionT>
                <OptionT value="NO">No</OptionT>
              </SelectT>
            </div>

            {errors?.["consultas"]?.[index]?.["obligatorio"]?.type ==
              "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}

            {watch(`consultas.${index}.coleccionElegida`) == "atributos" && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    margin: "10px 0 10px 0",
                    flexDirection: "column",
                  }}
                >
                  <label style={{ width: "100%", textAlign: "left" }}>
                    Rango temporal (Opcional)
                  </label>
                  <RangePickerT2
                    style={{ width: "100%" }}
                    control={control}
                    name={`consultas.${index}.fecha`}
                    rules={{ required: false }}
                  ></RangePickerT2>
                </div>
              </>
            )}

            {watch(`consultas.${index}.coleccionElegida`) == "listados" &&
              watch("listar") != "listados" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      margin: "10px 0 10px 0",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ width: "100%", textAlign: "left" }}>
                      Selecciona un listado
                    </label>
                    <SelectT
                      control={control}
                      name={`consultas.${index}.idListado`}
                      placeholder={"Selecciona un campo"}
                      ancho="100%"
                      rules={{ required: true }}
                      onChange={(e) => {
                        let fields = getValues();
                        delete fields.consultas[index].condiciones;
                        fields.consultas[index].idListado = e;
                        reset(fields);
                      }}
                    >
                      {listados.map((listado, index) => {
                        return (
                          <>
                            <OptionT key={index} value={listado._id}>
                              {listado.nombre}
                            </OptionT>
                          </>
                        );
                      })}
                    </SelectT>
                  </div>
                  {errors?.["consultas"]?.[index]?.["idListado"]?.type ==
                    "required" && (
                    <div style={{ color: "red", marginBottom: "20px" }}>
                      Campo requerido
                    </div>
                  )}
                </>
              )}

            {hijoListConsulta(index)}

            <Button type="secondary" danger onClick={() => remove(index)}>
              Eliminar consulta
            </Button>
          </div>
        );
      })}
      <div
        style={{
          marginTop: "20px",
          backgroundColor: "#fff",
          padding: "10px 0",
          borderRadius: "5px",
        }}
      >
        <Button
          disabled={
            (watch("idListado") && watch("listar")) ||
            (watch("listar") && watch("idAtributo")) ||
            watch("listar") == "afiliados"
              ? false
              : true
          }
          type="secondary"
          onClick={() => append({ coleccionElegida: "", idListado: "" })}
        >
          Agregar consulta
        </Button>
      </div>
    </>
  );
};

export default ListConsultas;
