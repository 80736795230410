import React from "react";
import { Form, Input, Select, Button, InputNumber, Modal, Radio, Spin } from "antd";
import { Api } from "../../../../../api/configApi";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
} from "../../../../../plugins/Formularios/FormularioTecclas";

const { Option } = Select;

const CoinPayments = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const refInputNombreListado = React.useRef();
  const refListaCriptos = React.useRef();
  const [listaCriptos, setListaCriptos] = React.useState();
  const refRuta = React.useRef(process.env.REACT_APP_RUTA);
  const refListaMonedas = React.useRef();
  const refListaMonedasArray = React.useRef([]);
  const [listaMonedasArray, setListaMonedasArray] = React.useState();
  const refCargando = React.useRef();
  const [cargando, setCargando] = React.useState(false);

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "opcionesMonto",
  });

  const pedirDatos = () => {
    // console.log("se ejecuta")
    refCargando.current = true;
    setCargando(refCargando.current)
    Api(
      "pasarelas",
      watch("listaIntegraciones"),
      { type: "getInfoCoins" },
      {},
      refRuta.current
    )
      .then((res) => {
        refCargando.current = false;
        setCargando(false);
        let toArray = Object.entries(res);
        toArray.forEach((elem) => {
          refListaMonedasArray.current.push({
            nombreMoneda: elem[1].name,
            codigoMoneda: elem[0],
            isFiat: elem[1].is_fiat,
          });
        });
        setListaMonedasArray(refListaMonedasArray.current);

        // console.log(refListaMonedasArray, "monedas array")

        // refListaMonedas.current = Object.values(res).map(
        //   (moneda) => moneda.name
        // );
      })
      .catch((err) => console.log(err, "entra al catch"));
  };

  const enviarNombreListado = () => {
    Api(
      "pasarelas",
      watch("listaIntegraciones"),
      { type: "InsertListado" },
      { nombreListado: refInputNombreListado.current },
      refRuta.current
    )
      .then((res) => {
        // console.log(res, "respuesta nombre listado")
        setListaCriptos(res.listados);
      })
      .catch((err) => console.log(err));
  };

  const consultarListadoCriptos = () => {
    // console.log("paffff")
    // console.log(watch("listaIntegraciones"), "lista integraciones")
    Api(
      "pasarelas",
      watch("listaIntegraciones"),
      { type: "enviarListadostypeCrypto" },
      {},
      refRuta.current
    )
      .then((res) => {
        // console.log("oooooooooooooooooo")
        refListaCriptos.current = res.listados;
        setListaCriptos(refListaCriptos.current);
      })
      .catch((err) => console.log(err));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (refInputNombreListado.current) {
      // console.log("entraa")
      enviarNombreListado();
    }
    let fields = getValues()
    delete fields.listaListados
    reset(fields)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  React.useEffect(() => {
    // console.log(watch("usarMultiplos"), "garmaaa")
    // watch("usarMultiplos")
    //   ? setValorCheckP(watch("usarMultiplos"))
    //   : setValorCheckP("false");
    // if (watch("montoFijoRango")) {
    //   setValorCheck(watch("montoFijoRango"));
    //   if (watch("montoFijoRango") == "montoFijoTrue") {
    //     setMult(true);
    //   } else {
    //     setMult(false);
    //   }
    // } else {
    //   setValorCheck("ninguno");
    //   setMult(false);
    // }
    pedirDatos()
    consultarListadoCriptos();
  }, []);

  return (
    <>
    {!cargando && (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>Monedas permitidas</label>
        <SelectT
          control={control}
          mode="multiple"
          name="listaCriptos"
          placeholder="Por favor escoge las monedas permitidas"
          ancho="100%"
          rules={{ required: true }}
          showSearch={true}
        >
          {listaMonedasArray?.map((moneda, index) => {
            if (moneda.isFiat !== 1) {
              return (
                <OptionT
                  key={index}
                  value={moneda.codigoMoneda + "-" + moneda.nombreMoneda}
                >
                  {moneda.nombreMoneda}
                </OptionT>
              );
            }
          })}
        </SelectT>
      </div>
      {errors?.listaCriptos?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>
          Escoge la moneda que quieres mostrar en el valor
        </label>
        <SelectT
          control={control}
          name="monedaEscogidaMostrar"
          placeholder="Por favor escoge una moneda"
          ancho="300px"
          rules={{ required: true }}
          showSearch={true}
        >
          {listaMonedasArray?.map((moneda, index) => {
            return (
              <OptionT
                key={index}
                value={moneda.codigoMoneda + "-" + moneda.nombreMoneda}
              >
                {moneda.nombreMoneda}
              </OptionT>
            );
          })}
        </SelectT>
      </div>
      {errors?.monedaEscogidaMostrar?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>
          Escoge una de las siguientes opciones
        </label>
        {setValue("opcionElegida", watch("montoFijoRango"))}
        <SelectT
          control={control}
          name="montoFijoRango"
          placeholder="Elige una opción"
          ancho="300px"
          rules={{ required: true }}
          onChange={(e) => {
            let fields = getValues()
            if (fields.montoMaximo){
              delete fields.montoMaximo
            }
            if (fields.montoMinimo){
              delete fields.montoMinimo
            }
            if (fields.montoPagar){
              delete fields.montoPagar
            }
            if (fields.opcionesMonto){
              delete fields.opcionesMonto
            }
            if (fields.usarMultiplos){
              delete fields.usarMultiplos
            }
            if (fields.multiplo){
              delete fields.multiplo
            }
            fields.montoFijoRango = e
            reset(fields)
          }}
        >
          <OptionT value="montoFijoTrue">Permitir monto fijo</OptionT>
          <OptionT value="rangoTrue">Permitir rango</OptionT>
          <OptionT value="checkOpcion">Elegir opción</OptionT>
          <OptionT value="ninguno">Ninguno</OptionT>
        </SelectT>
      </div>
      {errors?.montoFijoRango?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}

      {watch("montoFijoRango") == "montoFijoTrue" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ width: "100%", textAlign: "left" }}>
              Cantidad a ser pagada
            </label>
            <InputNumberT
              control={control}
              name={`montoPagar`}
              placeholder={"Ingresa un valor"}
              ancho="300px"
              rules={{ required: true }}
            ></InputNumberT>
          </div>
          {errors?.montoPagar?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </>
      )}

      {watch("montoFijoRango") == "rangoTrue" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ width: "100%", textAlign: "left" }}>
              Monto mínimo a pagar
            </label>
            <InputNumberT
              control={control}
              name={`montoMinimo`}
              placeholder={"Ingresa un valor"}
              ancho="300px"
              rules={{ required: true }}
            ></InputNumberT>
          </div>
          {errors?.montoMinimo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ width: "100%", textAlign: "left" }}>
              Monto máximo a pagar
            </label>
            <InputNumberT
              control={control}
              name={`montoMaximo`}
              placeholder={"Ingresa un valor"}
              ancho="300px"
              rules={{ required: true }}
            ></InputNumberT>
          </div>
          {errors?.montoMaximo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </>
      )}
      {watch("montoFijoRango") == "checkOpcion" && (
        <>
          {fields.map((item, index) => {
            return (
              <div key={item.id}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    margin: "10px 0 10px 0",
                    flexDirection: "column",
                  }}
                >
                  <label style={{ width: "100%", textAlign: "left" }}>
                    Monto
                  </label>
                  <InputNumberT
                    control={control}
                    name={`opcionesMonto.${index}.montoOpcion`}
                    placeholder={"Ingresa un valor"}
                    ancho="300px"
                    rules={{ required: true }}
                  ></InputNumberT>
                </div>
                {errors?.["opcionesMonto"]?.[index]?.["montoOpcion"]?.type ===
                  "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
                <Button type="secondary" danger onClick={() => remove(index)}>
                  Eliminar monto
                </Button>
              </div>
            );
          })}

          <div style={{ margin: "20px 0 20px 0" }}>
            <Button
              type="secondary"
              onClick={() =>
                append({
                  montoOpcion: "",
                })
              }
            >
              Agregar monto
            </Button>
          </div>
        </>
      )}

      {(watch("montoFijoRango") != "montoFijoTrue" && watch("montoFijoRango") != "checkOpcion" ) && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ textAlign: "left" }}>
              ¿Deseas usar múltiplos en la cantidad a recibir?
            </label>
            <SelectT
              control={control}
              name="usarMultiplos"
              placeholder="Elige una opción"
              ancho="300px"
              rules={{ required: true }}
              onChange={(e) => {
                // console.log(e);
                let fields = getValues();
                if (e == "false") {
                  if (fields.multiplo) {
                    delete fields.multiplo;
                    fields.usarMultiplos = e;
                    reset(fields);
                  }
                }
              }}
            >
              <OptionT value="true">Si</OptionT>
              <OptionT value="false">No</OptionT>
            </SelectT>
          </div>
          {errors?.usarMultiplos?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </>
      )}

      {watch("usarMultiplos") == "true" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ width: "100%", textAlign: "left" }}>
              La cantidad ingresada debe ser múltiplo de:
            </label>
            <InputNumberT
              control={control}
              name={`multiplo`}
              placeholder={"Ingresa un valor"}
              ancho="300px"
              rules={{ required: true }}
            ></InputNumberT>
          </div>
          {errors?.multiplo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>
          Por favor escoge un listado para almacenar el pago:
        </label>
        <SelectT
          control={control}
          name="listaListados"
          placeholder="Elige una opción"
          ancho="300px"
          rules={{ required: true }}
          onChange={(e) => {
            if (e == "crearListado") {
              showModal();
            }
            // console.log(e);
          }}
        >
          <OptionT value="crearListado">Crear listado</OptionT>
          {listaCriptos &&
            listaCriptos?.map((currency, index) => {
              return (
                <OptionT key={index} value={currency._id}>
                  {currency.nombre}
                </OptionT>
              );
            })}
        </SelectT>
      </div>
      {errors?.listaListados?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}

      <Modal
        title="Crear un listado para almacenar el pago"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form.Item name="nombreListado" label="Nombre del listado">
          <Input
            onChange={(e) => {
              refInputNombreListado.current = e.target.value;
            }}
            placeholder="Ingresa el nombre del listado"
          ></Input>
        </Form.Item>
      </Modal>
    </>
    )}
      {cargando && (
      <>
      <div style={{display:"flex", justifyContent:"center", margin:"30px 0"}}>
        <Spin size="large" spinning={cargando} />
      </div>
      </>
      )}
    </>
  );
};

export default CoinPayments;
