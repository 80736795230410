import { Collapse, Space } from "antd";
import BlockChainDirect from "./formulariosIntegraciones.js/blockChainDirect";
const { Panel } = Collapse;

function IndexIntegraciones(props) {
    return <Collapse style={{ marginTop: 10 }}>
        <Panel
            showArrow={false}
            header={
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <span> BlockChain Direct (Blockchain)</span>
                    {/* <img
                        height={40}
                        style={{ padding: 10 }}
                        alt="Blockchain"
                        src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/blockchain.jpeg"
                    /> */}
                </div>
            }
            key="1"
        >
            <BlockChainDirect />
        </Panel>

    </Collapse>

}

export default IndexIntegraciones;