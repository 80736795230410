import { Form, Input, Select, Switch } from "antd";
import { store } from "../../../../store";

function AccionActualizarAtributo() {
  return (
    <>
      <Form.Item
        label="Atributo"
        name="atributo"
        hasFeedback
        rules={[{ required: true, message: "Campo obligatorio" }]}
      >
        <Select>
          {store.losatributos.texts.map((e) => {
            return (
              <Select.Option value={e._id} key={e._id}>
                {e.nombre}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="explicacion" label="Explicación">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="unsolocambio"
        label="Un solo cambio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </>
  );
}

export default AccionActualizarAtributo;
