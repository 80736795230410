import { Form, Input, Select, Spin } from "antd";
import React from "react";

import CoinPayments from "./integraciones/CoinPayments";
import { Api } from "../../../../api/configApi";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  InputT,
  OptionT,
  SelectT,
} from "../../../../plugins/Formularios/FormularioTecclas";
import Tatum from "./integraciones/ListTatum/Tatum";

const { Option } = Select;

function CamposPersonalizados(props) {
  const refListaIntegraciones = React.useRef(["coinpayments"]);




  const {
    control,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue,
  } = useFormContext();



  React.useEffect(() => {

  }, [watch("listaIntegraciones")]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "10px 0 10px 0",
        }}
      >
        <label style={{ width: "200px", textAlign: "left" }}>
          Nombre del botón
        </label>
        <InputT
          rules={{ required: true }}
          ancho="100%"
          control={control}
          name={"nombreBoton"}
        ></InputT>
      </div>
      {errors?.nombreBoton?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "10px 0 10px 0",
        }}
      >
        <label style={{ width: "200px", textAlign: "left" }}>
          Estilo del botón
        </label>
        <InputT
          rules={{ required: false }}
          ancho="100%"
          control={control}
          name={"estiloBoton"}
        ></InputT>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>Lista de integraciones</label>
        <SelectT
          control={control}
          name="listaIntegraciones"
          placeholder="Selecciona una integración"
          ancho="100%"
          rules={{ required: true }}
          // onChange={(e) => {
          //   if (e == "coinpayments"){
          //     console.log("barney")
          //     pedirDatos();
          //   }
          // }}
        >
          <OptionT key={435345345345436} value="tatu">
                Blockchain Direct
          </OptionT>
          {refListaIntegraciones.current.map((integracion, index) => {
            return (
              <OptionT key={index} value={integracion}>
                {integracion}
              </OptionT>
            );
          })}
        </SelectT>
      </div>
      {errors?.listaIntegraciones?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}

      {(watch("listaIntegraciones") == "coinpayments") && (
      <>
          <CoinPayments
          />
      </>
      )}

      {(watch("listaIntegraciones") == "tatu") && (
      <>
          <Tatum
          />
      </>
      )}
      {/* <Spin spinning={cargando} /> */}


    </>
  );
}

export default CamposPersonalizados;
