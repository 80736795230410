import "./App.css";
import React from "react";
import { useHistory, withRouter, useParams } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Nologueado from "./pages/cuenta/nologueado";
import Estructura from "./pages/estructura";
import { observer } from "mobx-react";
import {
  store,
  setUser,
  setStoreCuenta,
  setStoreLogo,
  setStoreFormularios,
} from "./store";
import { Api } from "./api/configApi";

// Import the functions you need from the SDKs you need
import firebase from "firebase";

import CrearCuenta from "./components/ cuenta/crearCuenta";
import { Drawer, Layout, Spin } from "antd";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_AR-s2EBfTpI36E9Xu_JhYJSVTKzwyns",
  authDomain: "pulsoft-190ca.firebaseapp.com",
  projectId: "pulsoft-190ca",
  storageBucket: "pulsoft-190ca.appspot.com",
  messagingSenderId: "757267418666",
  appId: "1:757267418666:web:16a7df7ff9b795726b7d53",
  databaseURL: "https://pulsoft-190ca-default-rtdb.firebaseio.com",
};

// Initialize Firebase

const appFirebase = firebase.initializeApp(firebaseConfig);

function App(props) {
  const [cargando, setCargando] = React.useState(true);

  const [existeCuenta, setExisteCuenta] = React.useState(true);
  var url = new URL(window.location);

  React.useEffect(() => {
    console.log("Version: 1.1 (apha )");

    const usuarioparams = url.searchParams.get("user");
    if (usuarioparams) {
      localStorage.removeItem("token");
      const tokenCookie = url.searchParams.get("tt");

      if (tokenCookie) {
        localStorage.setItem("token", tokenCookie);
        const idUusario = usuarioparams;
        setUser(idUusario);
      }
      url.searchParams.delete("user");
      url.searchParams.delete("tt");
      window.location = url;
    }
    const token = localStorage.getItem("token");
    token
      ? Api("usuarios", "Get", { pilas: "ok" })
          .then((res) => {
            setUser(res);
            setCargando(false);
          })
          .catch((err) => {
            setCargando(false);
            console.log(err);
          })
      : Api("confirmarCuenta", { dominio: window.location.host }).then(
          (res) => {
            if (res.success) {
              setExisteCuenta(res.existe);
              if (res.existe) {
                console.log(res.cuenta?.formularios);
                setStoreFormularios(res.cuenta?.formularios);
                setStoreCuenta(res.cuenta?._id);
                setStoreLogo(res.cuenta?.logo);
              }
              setCargando(false);
            } else {
              setExisteCuenta(res.existe);
              setCargando(false);
            }
          }
        );
  }, []);
  return !cargando ? (
    <div style={contenedor}>
      {store.user ? (
        <>
          <Estructura firebase></Estructura>
        </>
      ) : !store.user && existeCuenta ? (
        <Nologueado></Nologueado>
      ) : (
        <CrearCuenta />
      )}
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Spin size="large" />
      <span style={{ position: "absolute", bottom: 10, left: 10 }}>
        versión: {store.version}
      </span>
    </div>
  );
}

const contenedor = {
  display: "flex",
  flexDirection: "row",
  borderRadius: 10,
  backgroundColor: "#fff",
  position: "relative",
  height: "100vh",
};

export default withRouter(observer(App));
