import React from "react";
import { Button, message } from "antd";
import { ObjectId } from "bson";
import { store, setStoreWidgets } from "../../../../store";
import { useForm, FormProvider } from "react-hook-form";
import {
  InputT,
  OptionT,
  SelectT,
} from "../../../../plugins/Formularios/FormularioTecclas";
import moment from "moment";
import { Api } from "../../../../api/configApi";
import CamposPersonalizados from "./Integrar/CamposPersonalizados";

const CrearActualizarWidgetVisualizarExportarDatos = (props) => {
  const methods = useForm();
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [validarNombre, setValidarNombre] = React.useState(false);
  const refContadorCond = React.useRef(0);
  const refEntregar = React.useRef({
    consultas: undefined,
    project: undefined,
    listar: undefined,
    idAtributo: undefined,
    idListado: undefined,
  });

  const onSubmit = (values) => {
    if (values.timezone.slice(0, 5) != "(UTC)") {
      values.zonaHoraria = {
        hora: values.timezone.slice(4, 7),
        minuto: values.timezone.slice(8, 10),
      };
    } else {
      values.zonaHoraria = {
        hora: "00",
        minuto: "00",
      };
    }

    values?.consultas?.forEach((consul, ind) => {
      consul.condiciones?.forEach((cond, index) => {
        if (cond.fecha) {
          values.consultas[ind].condiciones[index].fecha[0] = moment(
            values.consultas[ind].condiciones[index].fecha[0]
          );
          values.consultas[ind].condiciones[index].fecha[1] = moment(
            values.consultas[ind].condiciones[index].fecha[1]
          );
        } else {
          if (
            values.consultas[ind].condiciones[index].campo?.slice(
              values.consultas[ind].condiciones[index].campo.indexOf("-") + 1,
              values.consultas[ind].condiciones[index].campo.length
            ) == "date" &&
            values.consultas[ind].condiciones[index].operador != "rango"
          ) {
            let fecha = moment(
              values.consultas[ind].condiciones[index].valor
            ).format("YYYY-MM-DD");
            values.consultas[ind].condiciones[index].valor = fecha;
          }

          if (
            values.consultas[ind].condiciones[index].campo?.slice(
              values.consultas[ind].condiciones[index].campo.indexOf("-") + 1,
              values.consultas[ind].condiciones[index].campo.length
            ) == "date" &&
            values.consultas[ind].condiciones[index].operador == "rango"
          ) {
            let fecha1 = moment(
              values.consultas[ind].condiciones[index].valor[0]
            ).format("YYYY-MM-DD");
            let fecha2 = moment(
              values.consultas[ind].condiciones[index].valor[1]
            ).format("YYYY-MM-DD");

            values.consultas[ind].condiciones[index].valor = [fecha1, fecha2];
          }
        }
      });
    });

    let consul = [];
    if (values.consultas) {
      values.consultas.forEach((consulta) => {
        consul.push({
          coleccionElegida: consulta.coleccionElegida,
          condiciones: consulta.condiciones,
          idListado: consulta.idListado || undefined,
        });
      });
    }

    refEntregar.current.consultas = consul;
    refEntregar.current.project = values?.project;
    refEntregar.current.listar = values?.listar;
    refEntregar.current.idAtributo = values?.idAtributo;
    refEntregar.current.idListado = values?.idListado;

    values.tipo = "tabla-v2";

    values.limiteCantidad = +values.limiteCantidad;
    values.limiteWidth = +values.limiteWidth;

    refContadorCond.current = 0;

    values.consultas.forEach((consul, index) => {
      if (consul.fecha?.length > 0) {
        values.consultas[index].fecha = [
          moment(values.consultas[index].fecha[0]).format("YYYY-MM-DD"),
          moment(values.consultas[index].fecha[1]).format("YYYY-MM-DD"),
        ];
      }

      consul.condiciones.forEach((cond) => {
        refContadorCond.current += consul.condiciones.length;
      });
    });

    if (
      values.project?.length > 0 &&
      refContadorCond.current >= values.consultas?.length &&
      values.consultas?.length > 0
    ) {
      setLoading(true);
      Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
        .then((res) => {
          props.setVisible(false);
          setLoading(false);
          return Api(
            "Cuentas",
            "Get",
            { _id: store.cuentaactiva },
            { widgets: 1 }
          );
        })
        .then((res) => {
          setStoreWidgets(res[0].widgets);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      error();
    }
  };

  const error = () => {
    message.error({
      content:
        "No se pudo ejecutar la acción porque debe haber al menos una columna creada, al menos una consulta con al menos una condición.",
      duration: 5,
      className: "custom-class",
      style: {
        marginTop: "40vh",
      },
    });
  };

  React.useEffect(() => {
    methods.reset();
    const objId = new ObjectId();
    methods.setValue("_id", objId.toString());
    methods.setValue("dinamico", false);
  }, [props.visible]);

  React.useEffect(() => {
    if (props.data) {
      methods.reset(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      const objId2 = new ObjectId();
      methods.setValue("_id", objId2.toString());
      methods.setValue("dinamico", false);
    }
  }, [props.data]);

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ width: "100px", textAlign: "left" }}>Nombre</label>
            <InputT
              rules={{ required: true }}
              ancho="100%"
              control={methods.control}
              name={"nombre"}
            ></InputT>
          </div>
          {methods?.formState.errors?.nombre?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ width: "100px", textAlign: "left" }}>Título</label>
            <InputT
              rules={{ required: true }}
              ancho="100%"
              control={methods.control}
              name={"titulo"}
            ></InputT>
          </div>
          {methods?.formState.errors?.titulo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ width: "100px", textAlign: "left" }}>
              Descripción
            </label>
            <InputT
              rules={{ required: true }}
              ancho="100%"
              control={methods.control}
              name={"descripcion"}
            ></InputT>
          </div>
          {methods?.formState.errors?.descripcion?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
          <div hidden>
            <InputT control={methods.control} name={"_id"}></InputT>
          </div>
          <div hidden>
            <InputT control={methods.control} name={"dinamico"}></InputT>
          </div>

          <CamposPersonalizados
            props={props}
            setValidarNombre={setValidarNombre}
            validarNombre={validarNombre}
          />

          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ textAlign: "left" }}>
              ¿Deseas aplicar estos criterios al afiliado actual?
            </label>
            <SelectT
              control={methods.control}
              name="afiliadoActual"
              placeholder="¿Aplicar al afiliado actual?"
              ancho="100%"
              rules={{ required: true }}
            >
              <OptionT value="si">Si</OptionT>
              <OptionT value="no">No</OptionT>
            </SelectT>
          </div>
          {methods?.formState.errors?.afiliadoActual?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

          {validarNombre && (
            <div style={{ color: "red" }}>
              No puede haber más de una columna con el mismo nombre
            </div>
          )}

          <div style={{ marginTop: "10px" }}>
            <Button
              disabled={validarNombre}
              loading={loading}
              type="primary"
              htmlType="htmlType"
              style={{ marginRight: "40px" }}
            >
              Guardar
            </Button>
            <Button type="secondary" onClick={() => props.setVisible(false)}>
              Cancelar
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CrearActualizarWidgetVisualizarExportarDatos;
