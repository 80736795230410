import { Button } from "antd";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { store } from "../../../../../store";
import {
  DatePickerT2,
  InputNumberT,
  InputT,
  OptionT,
  RangePickerT2,
  SelectT,
} from "../../../../../plugins/Formularios/FormularioTecclas";

const HijoListConsultasAfiliadoAfiliado = (props) => {
  const {
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `consultas.[${props.indice}.condiciones]`,
  });

  const refAtributos = React.useRef([]);
  const [atributos, setAtributos] = React.useState([]);

  React.useEffect(() => {
    refAtributos.current = [
      ...store.losatributos.dates,
      ...store.losatributos.flags,
      ...store.losatributos.numbers,
      ...store.losatributos.texts,
    ];
    setAtributos(refAtributos.current);
  }, []);

  function resetearCamposcampo(index, valorListar) {
    const fields = getValues();
    if (fields.consultas[props.indice]) {
      if (fields.consultas[props.indice].condiciones[index]) {
        delete fields.consultas[props.indice].condiciones[index].operador;
        delete fields.consultas[props.indice].condiciones[index].valor;
        fields.consultas[props.indice].condiciones[index].campo = valorListar;
        reset(fields);
      }
    }
  }

  function resetearCamposOperador(index, valorListar) {
    const fields = getValues();
    if (fields.consultas[props.indice]) {
      if (fields.consultas[props.indice].condiciones[index]) {
        delete fields.consultas[props.indice].condiciones[index].rango;
        delete fields.consultas[props.indice].condiciones[index].valor;
        fields.consultas[props.indice].condiciones[index].operador =
          valorListar;
        reset(fields);
      }
    }
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <div style={{ margin: "30px 0 20px 0", fontWeight: 600 }}>
              Condición:
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>
                Campo del afiliado
              </label>
              <SelectT
                control={control}
                name={`consultas.${props.indice}.condiciones.${index}.campo`}
                placeholder={"Selecciona un campo del afiliado"}
                ancho="100%"
                rules={{ required: true }}
                onChange={(e) => {
                  resetearCamposcampo(index, e);
                }}
              >
                {watch("formulario") &&
                  store.formularios.find((e) => e._id == watch("formulario")) &&
                  store.formularios
                    .find((e) => e._id == watch("formulario"))
                    .campos?.map((campo, index) => {
                      if (campo.type != "password") {
                        return (
                          <>
                            <OptionT
                              key={index + 8}
                              value={campo.name + "-" + campo.type}
                            >
                              {campo.name}
                            </OptionT>
                          </>
                        );
                      }
                    })}
                {atributos.map((atributo, index) => {
                  return (
                    <>
                      <OptionT
                        key={index + 65}
                        value={atributo.nombre + "-" + atributo.tipo}
                      >
                        {atributo.nombre}
                      </OptionT>
                    </>
                  );
                })}
                <OptionT value="fecha_registro-date">Fecha de registro</OptionT>
              </SelectT>
            </div>
            {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[index]?.[
              "campo"
            ]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}

            {watch("formulario") &&
            store.formularios.find((e) => e._id == watch("formulario")) &&
            store.formularios
              .find((e) => e._id == watch("formulario"))
              .campos?.find(
                (cam) =>
                  cam.name ==
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).slice(
                    0,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-")
                  )
              ) ? (
              <>
                {setValue(
                  `consultas.${props.indice}.condiciones.${index}.por`,
                  "perfil"
                )}
              </>
            ) : watch(`consultas.${props.indice}.condiciones.${index}.campo`) ==
              "fecha_registro-date" ? (
              <>
                {setValue(
                  `consultas.${props.indice}.condiciones.${index}.por`,
                  "perfil"
                )}
              </>
            ) : (
              <>
                {setValue(
                  `consultas.${props.indice}.condiciones.${index}.por`,
                  "afiliados"
                )}
              </>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ width: "100%", textAlign: "left" }}>
                Selecciona un operador
              </label>
              <SelectT
                control={control}
                name={`consultas.${props.indice}.condiciones.${index}.operador`}
                placeholder={"Selecciona un operador"}
                ancho="100%"
                rules={{ required: true }}
                onChange={(e) => {
                  resetearCamposOperador(index, e);
                }}
              >
                {(watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) == "text" ||
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "password" ||
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "email") && (
                  <>
                    <OptionT value="$eq">Igual a</OptionT>
                    <OptionT value="$in">Iguales a</OptionT>
                    <OptionT value="$ne">Diferente de</OptionT>
                    <OptionT value="$nin">Diferentes de</OptionT>
                  </>
                )}

                {watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) == "number" && (
                  <>
                    <OptionT value="$eq">Igual a</OptionT>
                    <OptionT value="$in">Iguales a</OptionT>
                    <OptionT value="$lt">Menor que</OptionT>
                    <OptionT value="$lte">Menor o igual que</OptionT>
                    <OptionT value="$gt">Mayor que</OptionT>
                    <OptionT value="$gte">Mayor o igual que</OptionT>
                    <OptionT value="$ne">Diferente de</OptionT>
                    <OptionT value="$nin">Diferentes de</OptionT>
                    <OptionT value="rango">Rango</OptionT>
                  </>
                )}

                {watch(
                  `consultas.${props.indice}.condiciones.${index}.campo`
                )?.slice(
                  watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  ).indexOf("-") + 1,
                  watch(`consultas.${props.indice}.condiciones.${index}.campo`)
                    .length
                ) == "date" && (
                  <>
                    <OptionT value="$eq">Igual a</OptionT>
                    <OptionT value="$lt">Antes de</OptionT>
                    <OptionT value="$gt">Después de</OptionT>
                    <OptionT value="rango">Rango</OptionT>
                  </>
                )}
              </SelectT>
            </div>
            {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[index]?.[
              "operador"
            ]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}
            {watch(`consultas.${props.indice}.condiciones.${index}.operador`) !=
              "$nin" &&
            watch(`consultas.${props.indice}.condiciones.${index}.operador`) !=
              "$in" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    margin: "10px 0 10px 0",
                    flexDirection: "column",
                  }}
                >
                  {(watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "text" ||
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    )?.slice(
                      watch(
                        `consultas.${props.indice}.condiciones.${index}.campo`
                      ).indexOf("-") + 1,
                      watch(
                        `consultas.${props.indice}.condiciones.${index}.campo`
                      ).length
                    ) == "password" ||
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    )?.slice(
                      watch(
                        `consultas.${props.indice}.condiciones.${index}.campo`
                      ).indexOf("-") + 1,
                      watch(
                        `consultas.${props.indice}.condiciones.${index}.campo`
                      ).length
                    ) == "email") &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) && (
                      <>
                        <label style={{ width: "100%", textAlign: "left" }}>
                          Ingresa un valor amigo
                        </label>
                        <InputT
                          control={control}
                          name={`consultas.${props.indice}.condiciones.${index}.valor`}
                          placeholder={"Ingresa un valor"}
                          ancho="100%"
                          rules={{ required: true }}
                        ></InputT>
                      </>
                    )}

                  {watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "number" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) != "rango" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) && (
                      <>
                        <label style={{ width: "100%", textAlign: "left" }}>
                          Ingresa un valor
                        </label>
                        <InputNumberT
                          control={control}
                          name={`consultas.${props.indice}.condiciones.${index}.valor`}
                          placeholder={"Ingresa un valor"}
                          ancho="100%"
                          rules={{ required: true }}
                        ></InputNumberT>
                      </>
                    )}

                  {watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "number" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) == "rango" && (
                      <>
                        <label style={{ width: "100%", textAlign: "left" }}>
                          Ingresa el valor mínimo y el máximo
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <InputNumberT
                            control={control}
                            name={`consultas.${props.indice}.condiciones.${index}.rango.min`}
                            placeholder={"Valor mínimo"}
                            ancho="100%"
                            rules={{ required: true }}
                            min={undefined}
                            max={watch(
                              `consultas.${props.indice}.condiciones.${index}.rango.max`
                            )}
                          ></InputNumberT>
                          <InputNumberT
                            control={control}
                            name={`consultas.${props.indice}.condiciones.${index}.rango.max`}
                            placeholder={"Valor máximo"}
                            ancho="100%"
                            rules={{ required: true }}
                            min={watch(
                              `consultas.${props.indice}.condiciones.${index}.rango.min`
                            )}
                          ></InputNumberT>
                        </div>
                      </>
                    )}

                  {watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "date" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) != "rango" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) && (
                      <>
                        <label style={{ width: "100%", textAlign: "left" }}>
                          Ingresa una fecha
                        </label>
                        <DatePickerT2
                          control={control}
                          name={`consultas.${props.indice}.condiciones.${index}.valor`}
                          rules={{ required: true }}
                          style={{ width: "100%" }}
                        ></DatePickerT2>
                      </>
                    )}

                  {watch(
                    `consultas.${props.indice}.condiciones.${index}.campo`
                  )?.slice(
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).indexOf("-") + 1,
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.campo`
                    ).length
                  ) == "date" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) == "rango" &&
                    watch(
                      `consultas.${props.indice}.condiciones.${index}.operador`
                    ) && (
                      <>
                        <label style={{ width: "100%", textAlign: "left" }}>
                          Ingresa un rango de fechas
                        </label>
                        <RangePickerT2
                          control={control}
                          name={`consultas.${props.indice}.condiciones.${index}.valor`}
                          rules={{ required: true }}
                          style={{ width: "100%" }}
                        ></RangePickerT2>
                      </>
                    )}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    margin: "10px 0 10px 0",
                    flexDirection: "column",
                  }}
                >
                  <label style={{ width: "35%", textAlign: "left" }}>
                    Ingresa los valores
                  </label>
                  <SelectT
                    control={control}
                    name={`consultas.${props.indice}.condiciones.${index}.valor`}
                    placeholder={"Ingresa los valores"}
                    mode={"tags"}
                    ancho="100%"
                    rules={{ required: true }}
                  ></SelectT>
                </div>
              </>
            )}

            {errors?.["consultas"]?.[props.indice]?.["condiciones"]?.[index]?.[
              "valor"
            ]?.type == "required" && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Campo requerido
              </div>
            )}
            <Button type="secondary" danger onClick={() => remove(index)}>
              Eliminar condición
            </Button>
          </div>
        );
      })}
      <div style={{ margin: "20px 0 20px 0" }}>
        <Button
          type="secondary"
          onClick={() =>
            append({
              campo: "",
              operador: "",
              valor: "",
            })
          }
        >
          Agregar condición
        </Button>
      </div>
    </>
  );
};

export default HijoListConsultasAfiliadoAfiliado;
