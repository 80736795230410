import { Collapse, Space } from "antd";
import IntegracionSendGrid from "./sendGrid/integracionSendGrid";
import IntegracionFad from "./FAD/integracionFad";
import IntegracionUploadCare from "./uploadCare/integracionUploadCare";
import IntegracionCoinpayments from "./coinpayments/integracionCoinpayments";
import IntegracionCoinApi from "./CoinApi/IntegracionCoinApi";
import React from "react";
import { Api } from "../../../../api/configApi";
import IndexIntegraciones from "../../../../frontDEV1/integraciones/indexIntegraciones";

const { Panel } = Collapse;

function RenderIntegraciones() {
  const [integraciones, setIntegraciones] = React.useState({});

  React.useEffect(() => {
    Api("cuentas", "getIntegraciones").then((e) => {
      if (e.integraciones) {
        setIntegraciones(e.integraciones);
      }
    });
  }, []);

  return (
    <div>
      <Collapse accordion>
        <Panel
          showArrow={false}
          header={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>Envio de correo electrónico</span>
              <img
                height={40}
                style={{ padding: 10 }}
                alt="senGrid"
                src="https://octopusmultinivel.b-cdn.net/octopus10/SendGrid_2016_Logo.png"
              />
            </div>
          }
          key="1"
        >
          <IntegracionSendGrid integraciones={integraciones} />
        </Panel>
        <Panel
          showArrow={false}
          header={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>Firma Digital</span>
              <img
                height={40}
                alt="fad"
                src="https://uatclientes.firmaautografa.com/assets/img/backoffice/@0.5xlogo_inicio.svg"
              />
            </div>
          }
          key="2"
        >
          <IntegracionFad integraciones={integraciones} />
        </Panel>
        <Panel
          showArrow={false}
          header={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>Upload Care. Permite la subida y consulta de archivos</span>
              <img
                height={40}
                alt="uploadCare"
                src="https://dka575ofm4ao0.cloudfront.net/pages-transactional_logos/retina/15468/uploadcare-logo-statuspage.png"
              />
            </div>
          }
          key="3"
        >
          <IntegracionUploadCare integraciones={integraciones} />
        </Panel>
        <Panel
          showArrow={false}
          header={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>Recibe pagos con cientos de criptomonedas</span>
              <img
                height={40}
                alt="coinpayments"
                src="https://www.seekpng.com/png/full/120-1204562_logo-coinpayments-airdrop.png"
              />
            </div>
          }
          key="4"
        >
          <IntegracionCoinpayments integraciones={integraciones} />
        </Panel>
        <Panel
          showArrow={false}
          header={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>Conversión de cripto-monedas</span>
              <img
                height={40}
                style={{ padding: 10 }}
                alt="CoinApi"
                src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/t3pahzkkfze7g1zdw8ov"
              />
            </div>
          }
          key="5"
        >
          <IntegracionCoinApi integraciones={integraciones} />
        </Panel>
      </Collapse>
      <IndexIntegraciones integraciones={integraciones} />
    </div>
  );
}

export default RenderIntegraciones;
