import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Widget } from "@uploadcare/react-widget";
import { Form, Input, message, Modal, Select, Spin } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../../components/design/elementos/boton";
import { store } from "../../../../../store";

function ActualizarCampoPerfil(props) {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [perfil, setPerfil] = React.useState([]);

  function getPerfil() {
    Api(
      "afiliados",
      "perfilAfiliado",
      { idAfiliado: store.usuario.id },
      {}
    ).then((res) => {
      console.log(res.campos, "campos...");
      console.log(perfil, "El perfil...");
      setPerfil(res.campos);
    });
  }

  React.useEffect(() => {
    getPerfil();
  }, []);

  function actualizar(values) {
    setLoading(true);
    Api("widgets", "acciones", {
      accion: "actualizarCampoPerfil",
      datos: values,
      widget: props.widget._id,
    }).then((res) => {
      if (res.success) {
        message.success("Dato actualizado");
        setVisible(false);
        setPerfil([]);
        getPerfil();
      } else {
        message.error("Error al actualizar");
      }
      setLoading(false);
    });
  }

  function cortarTexto(texto) {
    if (!texto) {
      return (
        <Boton onClick={() => setVisible(true)}>
          {props.widget.textoBoton}
        </Boton>
      );
    } else if (texto.length > 30) {
      return (
        texto.substring(0, 7) + " *** " + texto.substring(texto.length - 7)
      );
    } else {
      return texto;
    }
  }
  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => {
          form.submit();
        }}
        okText="Guardar"
        cancelText="Cancelar"
        okButtonProps={{
          loading: loading,
          style: {
            backgroundColor: store.colores.botones[0],
            border: "none",
          },
        }}
      >
        <div style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}>
          Actualizar {props.widget.titulo}
        </div>
        <div dangerouslySetInnerHTML={{ __html: props.widget.explicacion }} />
        <Form form={form} onFinish={actualizar}>
          <Form.Item
            name="opcion"
            rules={[
              {
                required: true,
                message: "Campo obligatorio",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {perfil.length > 0 ? (
        <div>
          <strong>{props.widget.titulo}</strong>
          <br></br>
          <string style={{ fontSize: 12 }}>{props.widget.descripcion}</string>
          <div
            style={{
              marginTop: 5,
              background: "rgb(244 244 244)",
              padding: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {cortarTexto(
              perfil.find((e) => e.name === props.widget.campo).valor
            )}
            <FontAwesomeIcon
              onClick={() => setVisible(true)}
              style={{ cursor: "pointer" }}
              icon={faEdit}
            />
          </div>
        </div>
      ) : (
        <Spin />
      )}
    </>
  );
}

export default ActualizarCampoPerfil;
