import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ObjectId } from "bson";
import { Api } from "../../../../api/configApi";
import { setStoreWidgets, store } from "../../../../store";
import {
  ButtonT,
  InputT,
} from "../../../../plugins/Formularios/FormularioTecclas";
import CamposPersonalizados from "./CamposPersonalizados/CamposPersonalizados";
import { Button, Drawer } from "antd";
import FormularioCrearCurso from "./Formularios/FormularioCrearCurso";
import DrawerCursos from "./Drawers/DrawerCursos";

const CrearActualizarWidgetAcademia = (props) => {
  const methods = useForm();
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");

  const onSubmit = (values) => {
    //   tipo de widget
    values.tipo = "academia";
    methods.setValue("tipo", "academia");
    setLoading(true);
    values.limiteCantidad = +values.limiteCantidad;
    values.limiteWidth = +values.limiteWidth;

    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    methods.reset();
    const obj1 = new ObjectId();
    methods.setValue("_id", obj1.toString());
    methods.setValue("dinamico", false);
  }, [props.visible]);

  React.useEffect(() => {
    if (props.data) {
      methods.reset(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      const obj2 = new ObjectId();
      methods.setValue("_id", obj2.toString());
      methods.setValue("dinamico", false);
    }
  }, [props.data]);

  return (
    <div>
      {insertOrUpdate == "InsertWidgets" ? (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <label style={{ marginBottom: "10px" }}>Nombre</label>
                <InputT
                  control={methods.control}
                  name="nombre"
                  placeholder="Escribe el nombre"
                  rules={{ required: true }}
                ></InputT>
                {methods.formState.errors.nombre && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <label style={{ marginBottom: "10px" }}>Título</label>
                <InputT
                  control={methods.control}
                  name="titulo"
                  placeholder="Escribe el titulo"
                  rules={{ required: true }}
                ></InputT>
                {methods.formState.errors.titulo && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <label style={{ marginBottom: "10px" }}>Descripción</label>
                <InputT
                  control={methods.control}
                  name="descripcion"
                  placeholder="Escribe la descripcion"
                  rules={{ required: true }}
                ></InputT>

                {methods.formState.errors.descripcion && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
              <div>
                <InputT
                  control={methods.control}
                  name="_id"
                  hidden={true}
                ></InputT>
              </div>
              <div hidden>
                <InputT
                  control={methods.control}
                  name="dinamico"
                  hidden={true}
                ></InputT>
              </div>
              <div style={{ margin: "10px 0 10px 0" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0 10px 0",
                  }}
                >
                  <label style={{ width: "180px", textAlign: "left" }}>
                    Nombre de tu academia
                  </label>
                  <InputT
                    rules={{ required: true }}
                    ancho="220px"
                    control={methods.control}
                    name="nombreAcademia"
                    placeholder="Ingresa el título de la academia"
                  ></InputT>
                </div>
                {methods.formState.errors.nombreAcademia && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "20px 0 20px 0",
                }}
              >
                <ButtonT type="primary" htmlType="htmlType">
                  Guardar
                </ButtonT>
                <ButtonT
                  type="secondary"
                  onClick={() => props.setVisible(false)}
                >
                  Cancelar
                </ButtonT>
              </div>
            </form>
          </FormProvider>
        </>
      ) : (
        <>
          <DrawerCursos vis={props.setVisible} infoWidget={props.data}></DrawerCursos>
        </>
      )}
    </div>
  );
};

export default CrearActualizarWidgetAcademia;
