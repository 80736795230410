import React from "react";
import { Button, Modal, Input, Form, message } from "antd";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
  CheckboxT,
} from "../../../../../../plugins/Formularios/FormularioTecclas";
import { Api } from "../../../../../../api/configApi";
import ListMonto from "./ListMonto";
import { WarningOutlined } from "@ant-design/icons";

const Tatum = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const refInputNombreListado = React.useRef();
  const refListados = React.useRef();
  const [listados, setListados] = React.useState();
  const refMonedas = React.useRef();
  const [monedas, setMonedas] = React.useState();

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "monedasPermitidas",
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (refInputNombreListado.current.length > 0) {
      crearListado();
    }
  };

  function resetListadosInput() {
    let fields = getValues();
    delete fields.listados;
    reset(fields);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const obtenerListados = () => {
    Api(
      "integracionPasarelaPulsof",
      "obtenerListados",
      {},
      {},
      "https://c76a-181-33-156-128.ngrok.io"
    )
      .then((res) => {
        refListados.current = res.listados;
        setListados(refListados.current);
      })
      .catch((err) => console.log(err));
  };

  const crearListado = () => {
    Api(
      "integracionPasarelaPulsof",
      "crearNuevoListado",
      { nombreListado: refInputNombreListado.current },
      {},
      "https://c76a-181-33-156-128.ngrok.io"
    )
      .then((res) => {
        obtenerListados();
      })
      .catch((err) => console.log(err));
  };

  const obtenerMonedasPermitidas = () => {
    Api(
      "integracionPasarelaPulsof",
      "mostrarMonedasPermitidas",
      {},
      {},
      "https://c76a-181-33-156-128.ngrok.io"
    )
      .then((res) => {
        refMonedas.current = res;
        setMonedas(refMonedas.current);
      })
      .catch((err) => console.log(err, "entra al catch"));
  };

  const monedasMostrar = [
    {
      nombre: "No usar moneda de referencia",
      codigo: "SAME",
    },
    {
      nombre: "Dírham árabes",
      codigo: "AED",
    },
    {
      nombre: "Afghani",
      codigo: "AFN",
    },
    {
      nombre: "Lek",
      codigo: "ALL",
    },
    {
      nombre: "Dram",
      codigo: "AMD",
    },
    {
      nombre: "Florín de las Antillas Neerlandesas",
      codigo: "ANG",
    },
    {
      nombre: "Kwanza",
      codigo: "AOA",
    },
    {
      nombre: "Peso argentino",
      codigo: "ARS",
    },
    {
      nombre: "Dólar australiano",
      codigo: "AUD",
    },
    {
      nombre: "Florín",
      codigo: "AWG",
    },
    {
      nombre: "Manat",
      codigo: "AZN",
    },
    {
      nombre: "Marca convertible",
      codigo: "BAM",
    },
    {
      nombre: "Dólar de Barbados",
      codigo: "BBD",
    },
    {
      nombre: "Taka",
      codigo: "BDT",
    },
    {
      nombre: "Lev búlgaro",
      codigo: "BGN",
    },
    {
      nombre: "Dinar de Bahréin",
      codigo: "BHD",
    },
    {
      nombre: "Franco de Burundi",
      codigo: "BIF",
    },
    {
      nombre: "Dólar de las Bermudas",
      codigo: "BMD",
    },
    {
      nombre: "Dólar de Brunei",
      codigo: "BND",
    },
    {
      nombre: "Boliviano",
      codigo: "BOB",
    },
    {
      nombre: "Real",
      codigo: "BRL",
    },
    {
      nombre: "Dólar de las Bahamas",
      codigo: "BSD",
    },
    {
      nombre: "Ngultrum",
      codigo: "BTN",
    },
    {
      nombre: "Pula",
      codigo: "BWP",
    },
    {
      nombre: "Beyondfi",
      codigo: "BYN",
    },
    {
      nombre: "Rublo bielorrusia",
      codigo: "BYR",
    },
    {
      nombre: "Dólar de Belice",
      codigo: "BZD",
    },
    {
      nombre: "Dólar canadiense",
      codigo: "CAD",
    },
    {
      nombre: "Franco congoleño",
      codigo: "CDF",
    },
    {
      nombre: "Franco suizo",
      codigo: "CHF",
    },
    {
      nombre: "---",
      codigo: "CLF",
    },
    {
      nombre: "Peso chileno",
      codigo: "CLP",
    },
    {
      nombre: "Yuan Renminbi",
      codigo: "CNY",
    },
    {
      nombre: "Peso colombiano",
      codigo: "COP",
    },
    {
      nombre: "Colón",
      codigo: "CRC",
    },
    {
      nombre: "---",
      codigo: "CUC",
    },
    {
      nombre: "Peso cubano",
      codigo: "CUP",
    },
    {
      nombre: "Escudo de Cabo Verde",
      codigo: "CVE",
    },
    {
      nombre: "Corona checo",
      codigo: "CZK",
    },
    {
      nombre: "Franco de Djibouti",
      codigo: "DJF",
    },
    {
      nombre: "Corona danés",
      codigo: "DKK",
    },
    {
      nombre: "Peso dominicano",
      codigo: "DOP",
    },
    {
      nombre: "Dinar argelino",
      codigo: "DZD",
    },
    {
      nombre: "Libra egipcio",
      codigo: "EGP",
    },
    {
      nombre: "Nakfa",
      codigo: "ERN",
    },
    {
      nombre: "Birr",
      codigo: "ETB",
    },
    {
      nombre: "Euro",
      codigo: "EUR",
    },
    {
      nombre: "Dólar Fidschi",
      codigo: "FJD",
    },
    {
      nombre: "Libra de las Malvinas",
      codigo: "FKP",
    },
    {
      nombre: "Libra esterlina",
      codigo: "GBP",
    },
    {
      nombre: "Lari georgiano",
      codigo: "GEL",
    },
    {
      nombre: "Libra de Guernsey",
      codigo: "GGP",
    },
    {
      nombre: "Cedi de Ghana",
      codigo: "GHS",
    },
    {
      nombre: "Libra de Gibraltar",
      codigo: "GIP",
    },
    {
      nombre: "Dalasi",
      codigo: "GMD",
    },
    {
      nombre: "Franco de Guinea",
      codigo: "GNF",
    },
    {
      nombre: "Quetzal",
      codigo: "GTQ",
    },
    {
      nombre: "Dólar de Guyana",
      codigo: "GYD",
    },
    {
      nombre: "Dólar de Hong Kong",
      codigo: "HKD",
    },
    {
      nombre: "Lempira",
      codigo: "HNL",
    },
    {
      nombre: "Kuna",
      codigo: "HRK",
    },
    {
      nombre: "Gourde",
      codigo: "HTG",
    },
    {
      nombre: "Forint húngaro",
      codigo: "HUF",
    },
    {
      nombre: "Rupia indonesio",
      codigo: "IDR",
    },
    {
      nombre: "Shekel israelí",
      codigo: "ILS",
    },
    {
      nombre: "Libra manesa",
      codigo: "IMP",
    },
    {
      nombre: "Rupia india",
      codigo: "INR",
    },
    {
      nombre: "Dinar iraquí",
      codigo: "IQD",
    },
    {
      nombre: "Rial iraní",
      codigo: "IRR",
    },
    {
      nombre: "Corona islandesa",
      codigo: "ISK",
    },
    {
      nombre: "Libra de Jersey",
      codigo: "JEP",
    },
    {
      nombre: "Dólar de Jamaica",
      codigo: "JMD",
    },
    {
      nombre: "Dinar jordano",
      codigo: "JOD",
    },
    {
      nombre: "Yen japonés",
      codigo: "JPY",
    },
    {
      nombre: "Chelín keniano",
      codigo: "KES",
    },
    {
      nombre: "Som",
      codigo: "KGS",
    },
    {
      nombre: "Riel camboyano",
      codigo: "KHR",
    },
    {
      nombre: "Franco comorano",
      codigo: "KMF",
    },
    {
      nombre: "Won",
      codigo: "KPW",
    },
    {
      nombre: "Won",
      codigo: "KRW",
    },
    {
      nombre: "Dinar kuwaití",
      codigo: "KWD",
    },
    {
      nombre: "Dólar caimán",
      codigo: "KYD",
    },
    {
      nombre: "Tenge",
      codigo: "KZT",
    },
    {
      nombre: "Kip",
      codigo: "LAK",
    },
    {
      nombre: "Libra libanés",
      codigo: "LBP",
    },
    {
      nombre: "Rupia de Sri Lanka",
      codigo: "LKR",
    },
    {
      nombre: "Dólar liberiano",
      codigo: "LRD",
    },
    {
      nombre: "Loti de Lesotho",
      codigo: "LSL",
    },
    {
      nombre: "---",
      codigo: "LTL",
    },
    {
      nombre: "LevelApp Token",
      codigo: "LVL",
    },
    {
      nombre: "Dinar libio",
      codigo: "LYD",
    },
    {
      nombre: "Dirham marroquí",
      codigo: "MAD",
    },
    {
      nombre: "Leu",
      codigo: "MDL",
    },
    {
      nombre: "Ariary malgache",
      codigo: "MGA",
    },
    {
      nombre: "Denar",
      codigo: "MKD",
    },
    {
      nombre: "Kyat",
      codigo: "MMK",
    },
    {
      nombre: "Tugrik",
      codigo: "MNT",
    },
    {
      nombre: "Pataca macanés",
      codigo: "MOP",
    },
    {
      nombre: "Ouguiya",
      codigo: "MRO",
    },
    {
      nombre: "Rupia mauriciano",
      codigo: "MUR",
    },
    {
      nombre: "Rufiyaa maldivo",
      codigo: "MVR",
    },
    {
      nombre: "Kwacha",
      codigo: "MWK",
    },
    {
      nombre: "Peso mexicano",
      codigo: "MXN",
    },
    {
      nombre: "Ringgit",
      codigo: "MYR",
    },
    {
      nombre: "Metical",
      codigo: "MZN",
    },
    {
      nombre: "Dólar de Namibia",
      codigo: "NAD",
    },
    {
      nombre: "Naira",
      codigo: "NGN",
    },
    {
      nombre: "Oro de Córdoba",
      codigo: "NIO",
    },
    {
      nombre: "Corona noruego",
      codigo: "NOK",
    },
    {
      nombre: "Rupia nepalés",
      codigo: "NPR",
    },
    {
      nombre: "Dólar neozelandés",
      codigo: "NZD",
    },
    {
      nombre: "Rial omaní",
      codigo: "OMR",
    },
    {
      nombre: "Balboa panameño",
      codigo: "PAB",
    },
    {
      nombre: "Sol nuevo",
      codigo: "PEN",
    },
    {
      nombre: "Kina",
      codigo: "PGK",
    },
    {
      nombre: "Peso filipino",
      codigo: "PHP",
    },
    {
      nombre: "	Rupia paquistaní",
      codigo: "PKR",
    },
    {
      nombre: "Zloty",
      codigo: "PLN",
    },
    {
      nombre: "Guaraní",
      codigo: "PYG",
    },
    {
      nombre: "Rial katarí",
      codigo: "QAR",
    },
    {
      nombre: "Leu",
      codigo: "RON",
    },
    {
      nombre: "Dinar serbio",
      codigo: "RSD",
    },
    {
      nombre: "Rublo ruso",
      codigo: "RUB",
    },
    {
      nombre: "Franco ruandés",
      codigo: "RWF",
    },
    {
      nombre: "Rial saudí",
      codigo: "SAR",
    },
    {
      nombre: "Dólar de Salomon",
      codigo: "SBD",
    },
    {
      nombre: "Rupia de Seychelles",
      codigo: "SCR",
    },
    {
      nombre: "Libra sudanés",
      codigo: "SDG",
    },
    {
      nombre: "Corona sueco",
      codigo: "SEK",
    },
    {
      nombre: "Dólar de Singapur",
      codigo: "SGD",
    },
    {
      nombre: "Libra de St-Helena",
      codigo: "SHP",
    },
    {
      nombre: "Leone",
      codigo: "SLL",
    },
    {
      nombre: "Chelín somalí",
      codigo: "SOS",
    },
    {
      nombre: "Dólar de Suriname",
      codigo: "SRD",
    },
    {
      nombre: "Dobra",
      codigo: "STD",
    },
    {
      nombre: "Silvercash",
      codigo: "SVC",
    },
    {
      nombre: "Libra sirios",
      codigo: "SYP",
    },
    {
      nombre: "Lilangeni swazi",
      codigo: "SZL",
    },
    {
      nombre: "Baht tailandés",
      codigo: "THB",
    },
    {
      nombre: "Somoni",
      codigo: "TJS",
    },
    {
      nombre: "Manat",
      codigo: "TMT",
    },
    {
      nombre: "Dinar tunecinos",
      codigo: "TND",
    },
    {
      nombre: "Pa'anga",
      codigo: "TOP",
    },
    {
      nombre: "Lira turcos",
      codigo: "TRY",
    },
    {
      nombre: "Dólar de Trinidad and Tobago",
      codigo: "TTD",
    },
    {
      nombre: "Dólar nuevo taiwanés",
      codigo: "TWD",
    },
    {
      nombre: "Chelín de Tanzania",
      codigo: "TZS",
    },
    {
      nombre: "Hrywnja",
      codigo: "UAH",
    },
    {
      nombre: "Chelín de Uganda",
      codigo: "UGX",
    },
    {
      nombre: "Dólar estadounidenses",
      codigo: "USD",
    },
    {
      nombre: "Peso uruguayo",
      codigo: "UYU",
    },
    {
      nombre: "Sum uzbeko",
      codigo: "UZS",
    },
    {
      nombre: "Bolívar fuerte",
      codigo: "VEF",
    },
    {
      nombre: "Dong",
      codigo: "VND",
    },
    {
      nombre: "Vatu",
      codigo: "VUV",
    },
    {
      nombre: "Tala",
      codigo: "WST",
    },
    {
      nombre: "Franco centroafricano",
      codigo: "XAF",
    },
    {
      nombre: "Plata gramo",
      codigo: "XAG",
    },
    {
      nombre: "Oro gramo",
      codigo: "XAU",
    },
    {
      nombre: "Dólar del Caribe Oriental",
      codigo: "XCD",
    },
    {
      nombre: "Derechos de giro especiales",
      codigo: "XDR",
    },
    {
      nombre: "Franco de África Occidental",
      codigo: "XOF",
    },
    {
      nombre: "Franco del Pacífico",
      codigo: "XPF",
    },
    {
      nombre: "Rial yemení",
      codigo: "YER",
    },
    {
      nombre: "Rand sudafricano",
      codigo: "ZAR",
    },
    {
      nombre: "Kwacha de Zambiano",
      codigo: "ZMK",
    },
    {
      nombre: "Kwacha de Zambiano",
      codigo: "ZMW",
    },
    {
      nombre: "Dólar de Zimbabue",
      codigo: "ZWL",
    },
  ];

  React.useEffect(() => {
    obtenerListados();
    obtenerMonedasPermitidas();
  }, []);

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ textAlign: "left" }}>
                Selecciona una moneda:
              </label>
              <SelectT
                control={control}
                name={`monedasPermitidas.${index}.moneda`}
                placeholder="Elige una opción"
                ancho="300px"
                rules={{ required: true }}
                showSearch={true}
              >
                {monedas?.map((moneda) => {
                  return (
                    <OptionT value={moneda.nombre + "-" + moneda.codigo}>
                      {moneda.nombre}
                    </OptionT>
                  );
                })}
              </SelectT>
            </div>
            {errors?.["monedasPermitidas"]?.[index]?.["moneda"]?.type ===
              "required" && (
              <>
                <div style={{ color: "red" }}>Campo requerido</div>
              </>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ textAlign: "left" }}>
                Ingresa un margen de error:
              </label>
              <InputT
                control={control}
                name={`monedasPermitidas.${index}.margenError`}
                placeholder={"Ingresa un valor"}
                ancho="300px"
                backgroundColor="red !important"
                rules={{ required: true, pattern: /^[0-9]+(\.[0-9]{1,8})?$/ }}
              ></InputT>
            </div>
            {errors?.["monedasPermitidas"]?.[index]?.["margenError"]?.type ===
              "required" && (
              <>
                <div style={{ color: "red" }}>Campo requerido</div>
              </>
            )}
            {errors?.["monedasPermitidas"]?.[index]?.["margenError"]?.type ==
              "pattern" && (
              <>
                <div style={{ color: "red" }}>Formato incorrecto</div>
              </>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <label style={{ textAlign: "left" }}>
                Tiempo de expiración para esta moneda en horas:
              </label>
              <InputT
                control={control}
                name={`monedasPermitidas.${index}.timeout`}
                placeholder={"Ingresa un valor"}
                ancho="300px"
                rules={{ required: true, pattern: /^[0-9]+(\.[0-9]{1,8})?$/ }}
              ></InputT>
            </div>
            {errors?.["monedasPermitidas"]?.[index]?.["timeout"]?.type ===
              "required" && (
              <>
                <div style={{ color: "red" }}>Campo requerido</div>
              </>
            )}
            {errors?.["monedasPermitidas"]?.[index]?.["timeout"]?.type ==
              "pattern" && (
              <>
                <div style={{ color: "red" }}>Formato incorrecto</div>
              </>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="secondary"
                danger
                onClick={() => {
                  remove(index);
                  let fields = getValues();
                  reset(fields);
                }}
              >
                Eliminar moneda
              </Button>
            </div>
          </div>
        );
      })}

      <div style={{ margin: "20px 0 20px 0" }}>
        <Button
          type="secondary"
          onClick={() =>
            append({
              moneda: undefined,
              margenError: undefined,
            })
          }
        >
          Agregar moneda permitida
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>
          Escoge la moneda que quieres mostrar en el valor:
        </label>
        <SelectT
          control={control}
          name="monedaMostrar"
          placeholder="Por favor escoge una moneda"
          ancho="300px"
          rules={{ required: true }}
          showSearch={true}
        >
          {monedasMostrar.map((moneda, index) => {
            return (
              <OptionT key={index} value={moneda.codigo + "-" + moneda.nombre}>
                {moneda.nombre}
              </OptionT>
            );
          })}
        </SelectT>
      </div>
      {errors?.monedaMostrar?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>
          Escoge una de las siguientes opciones:
        </label>
        <SelectT
          control={control}
          name="opcion"
          placeholder="Elige una opción"
          ancho="300px"
          rules={{ required: true }}
          onSelect={(e) => {
            if (e != "montoFijo") {
              let fields = getValues();
              delete fields.montoPagar;
              fields.opcion = e;
              reset(fields);
            }
          }}
          onChange={(e) => {
            let fields = getValues();
            if (fields.montoMaximo) {
              delete fields.montoMaximo;
            }
            if (fields.montoMinimo) {
              delete fields.montoMinimo;
            }
            if (fields.montoPagar) {
              delete fields.montoPagar;
            }
            if (fields.opcionesMonto) {
              delete fields.opcionesMonto;
            }
            if (fields.usarMultiplo2) {
              fields.usarMultiplo2 = false;
              // fields.usarMultiplo = undefined
              // setBandera(!bandera)
            }
            if (fields.multiplo) {
              delete fields.multiplo;
            }
            fields.opcion = e;
            reset(fields);
          }}
        >
          <OptionT value="montoFijo">Permitir monto fijo</OptionT>
          <OptionT value="rango">Permitir rango</OptionT>
          <OptionT value="checkOpcion">Elegir opción</OptionT>
          <OptionT value="ninguno">Ninguno</OptionT>
        </SelectT>
      </div>
      {errors?.opcion?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}

      {watch("opcion") == "montoFijo" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ width: "100%", textAlign: "left" }}>
              Cantidad a ser pagada:
            </label>
            <InputNumberT
              control={control}
              name={`montoPagar`}
              placeholder={"Ingresa un valor"}
              ancho="300px"
              rules={{ required: true }}
            ></InputNumberT>
          </div>
          {errors?.montoPagar?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </>
      )}

      {watch("opcion") == "rango" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ width: "100%", textAlign: "left" }}>
              Monto mínimo a pagar:
            </label>
            <InputNumberT
              control={control}
              name={`montoMinimo`}
              placeholder={"Ingresa un valor"}
              ancho="300px"
              rules={{ required: true }}
            ></InputNumberT>
          </div>
          {errors?.montoMinimo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ width: "100%", textAlign: "left" }}>
              Monto máximo a pagar:
            </label>
            <InputNumberT
              control={control}
              name={`montoMaximo`}
              placeholder={"Ingresa un valor"}
              ancho="300px"
              rules={{ required: true }}
            ></InputNumberT>
          </div>
          {errors?.montoMaximo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </>
      )}

      {watch("opcion") == "checkOpcion" && (
        <>
          <ListMonto />
        </>
      )}

      {watch("opcion") != "montoFijo" && watch("opcion") != "checkOpcion" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ textAlign: "left", marginBottom: "10px" }}>
              ¿Deseas usar múltiplos en la cantidad a recibir?
            </label>
            <div style={{ marginLeft: "10px" }}>
              <CheckboxT
                control={control}
                name="usarMultiplo2"
                checked={false}
                onChange={(e) => {
                  let fields = getValues();
                  if (fields.multiplo) {
                    delete fields.multiplo;
                  }
                  fields.usarMultiplo2 = e.target.checked;
                  reset(fields);
                }}
              ></CheckboxT>
            </div>
          </div>
          {errors?.usarMultiplo2?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </>
      )}

      {/*{watch("usarMultiplo") == "true" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ width: "100%", textAlign: "left" }}>
              La cantidad ingresada debe ser múltiplo de:
            </label>
            <InputNumberT
              control={control}
              name={`multiplo`}
              placeholder={"Ingresa un valor"}
              ancho="300px"
              rules={{ required: true }}
            ></InputNumberT>
          </div>
          {errors?.multiplo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </>
      )}*/}

      {watch("usarMultiplo2") == true && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              margin: "10px 0 10px 0",
              flexDirection: "column",
            }}
          >
            <label style={{ width: "100%", textAlign: "left" }}>
              La cantidad ingresada debe ser múltiplo de:
            </label>
            <InputNumberT
              control={control}
              name={`multiplo`}
              placeholder={"Ingresa un valor"}
              ancho="300px"
              rules={{ required: true }}
            ></InputNumberT>
          </div>
          {errors?.multiplo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "start",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <label style={{ textAlign: "left" }}>
          Por favor escoge un listado para almacenar el pago:
        </label>
        <SelectT
          control={control}
          name="listados"
          placeholder="Elige una opción"
          ancho="300px"
          rules={{ required: true }}
          onSelect={(e) => {
            console.log(e, "paty");
            if (e == "crearListado") {
              showModal();
            }
          }}
        >
          <OptionT value="crearListado">Crear listado</OptionT>
          {listados &&
            listados?.map((listado, index) => {
              return (
                <OptionT key={index} value={listado._id}>
                  {listado.nombre}
                </OptionT>
              );
            })}
        </SelectT>
      </div>
      {errors?.listados?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}

      <Modal
        title="Crear un listado para almacenar el pago"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          handleCancel();
          resetListadosInput();
        }}
      >
        <Form.Item name="nombreListado" label="Nombre del listado">
          <Input
            onChange={(e) => {
              refInputNombreListado.current = e.target.value;
            }}
            placeholder="Ingresa el nombre del listado"
          ></Input>
        </Form.Item>
      </Modal>

      {Object.entries(errors).length != 0 && (
        <div style={{ padding: "10px 20px", margin: "30px 0" }}>
          <div
            style={{
              textAlign: "center",
              fontSize: "32px",
              padding: 0,
              color: "red",
            }}
          >
            <WarningOutlined />
          </div>
          <p style={{ textAlign: "center", margin: 0, color: "red" }}>
            Existen campos que no se han llenado correctamente
          </p>
        </div>
      )}
    </>
  );
};

export default Tatum;
