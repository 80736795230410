import {
  Table,
  Input,
  Space,
  Popconfirm,
  message,
  Popover,
  Button,
} from "antd";
import { Drawer } from "antd";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import React from "react";
import { setStoreWidgets, store } from "../../store";
import Boton from "../design/elementos/boton";
import { Api } from "../../api/configApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CrearActualizarWidgetNumero from "../widgets/tipos/numero/CrearActualizarWidgetNumeroComponente";
import CrearActualizarWidgetLink from "./tipos/link/CrearActualizarWidgetLinkComponente";
import CrearActualizarWidgetWoocommerce from "./tipos/woocommerce/CrearActualizarWidgetWoocommerce";
import CrearActualizarWidgetArbolGenealogico from "./tipos/arbolGenealogico/crearActualizarWidgetArbolGenealogico";
import CrearActualizarWidgetTabla from "./tipos/tabla/CrearActualizarWidgetTabla";
import CrearActualizarWidgetListaRecursos from "./tipos/Lista recursos/CrearActualizarWigetListaRecursos";
import CrearActualizarWidgetAdminRecursos from "./tipos/Admin Recursos/CrearActualizarWidgetAdminRecursos";
import CrearActualizarDebitoCredito from "./tipos/debitoCredito/CrearActualizarWidgetDebitoCredito";
import CrearActualizarWidgetTexto from "./tipos/texto/CrearActualizarWidgetTextoComponente";
import CrearActualizarWidgetObjetivo from "./tipos/objetivo/CrearActualizarWidgetNumeroComponente";
import CrearActualizarWidgetBanner from "./tipos/banner/CrearActualizarWidgetBannerComponente";
import CrearActualizarWidgetLogros from "./tipos/logros/CrearActualizarWidgetLogrosComponente";

import CrearActualizarWidgetVideo from "./tipos/video/CrearActualizarWidgetVideoComponente";
import CrearActualizarUploadFiles from "./tipos/uploadFiles/CrearActualizarWidgetUploadFiles";
import { IconosWidgetsComponenteDev1 } from "../../frontDEV1/widgets/ListaWidgetsComponenteDev1";
import TiposFormulariosWidgetsDev1 from "../../frontDEV1/widgets/TiposFormulariosWidgetsDev1";

function ListaWidgetsComponente() {
  const [filtro, setFiltro] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [modalEditar, setModalEditar] = React.useState(false);
  const [data, setData] = React.useState();
  const [columns, setColumns] = React.useState([
    {
      title: "",
      dataIndex: "tipo",
      key: "tipo",
      render: (text, record) => {
        // console.log(text, "aleluyaaaaaa")
        var imagen = "";
        switch (text) {
          case "tabla":
            imagen = "tabla.svg";
            break;
          case "numero":
            imagen = "scale.svg";
            break;
          case "texto":
            imagen = "font.svg";
            break;
          case "objetivo":
            imagen = "loading.svg";
            break;
          case "link":
            imagen = "link.svg";
            break;
          case "arbolGenealogico":
            imagen = "family-tree.svg";
            break;
          case "woocommerce":
            imagen = "shopping-cart.svg";
            break;
          case "listaRecursos":
            imagen = "carpeta%20(1).svg";
            break;
          case "adminRecursos":
            imagen = "settings.svg";
            break;
          case "debitoCredito":
            imagen = "upload.svg";
            break;
          case "banner":
            imagen = "hanging-billboard%20(1).png";
            break;
          case "logros":
            imagen = "rating.png";
            break;
          case "video":
            imagen = "video-player.png";
            break;

          case "uploadFiles":
            imagen = "uploading.png";
            break;

          default:
            imagen = "";
            break;
        }
        var urlImagen = `https://octopusmultinivel.b-cdn.net/octopus10/${imagen}`;
        if (!imagen) {
          urlImagen = IconosWidgetsComponenteDev1(text);
        }
        return <img width="25px" alt="widget" src={urlImagen} />;
      },
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, record) => (
        <Space size="middle">
          <FontAwesomeIcon
            onClick={() => {
              setModalEditar(true);
              mostrar(record);
            }}
            style={{ cursor: "pointer" }}
            icon={faEdit}
          />
          <Popconfirm
            title="¿Estás seguro?"
            onConfirm={() => {
              eliminarWidget(record, text, "felicidad");
            }}
            okText="Si"
            cancelText="No"
          >
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrash} />
          </Popconfirm>
        </Space>
      ),
    },
  ]);
  const mostrar = (e) => {
    const dato = toJS(e);
    // console.log(dato, "dato ListaWidgetsComponente")
    setData(dato);
    setTipo(dato.tipo);
  };
  const eliminarWidget = (e, b, c) => {
    // console.log(e._id);
    const menu = [...store.menuAdmin, ...store.menuFront];
    var existe = false;
    menu.forEach((c) =>
      c.widgets.forEach((a) =>
        a.forEach((z) => {
          // console.log(z._id, e._id);
          if (z._id === e._id) {
            existe = true;
          }
        })
      )
    );

    if (existe) {
      message.error(
        "Este widget está en uso. Primero debes eliminarlo del menú."
      );
    } else {
      const dato = toJS(e);
      Api(
        "Cuentas",
        "DeleteWidget",
        { _id: store.cuentaactiva },
        { widget: dato._id }
      )
        .then((res) => {
          return Api(
            "Cuentas",
            "Get",
            { _id: store.cuentaactiva },
            { widgets: 1 }
          );
        })
        .then((rs) => {
          message.success("Widget eliminado correctamente");
          setStoreWidgets(rs[0].widgets);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const onClose = () => {
    setModalEditar(false);
  };

  return (
    <div>
      <Input
        placeholder="Filtrar Widgets"
        onChange={(e) => {
          setFiltro(e.target.value);
        }}
      />
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {data?.tipo}
            <Popover trigger="hover" title="Id" content={data?._id}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Popover>
          </div>
        }
        placement="right"
        closable={false}
        visible={modalEditar}
        onClose={onClose}
        width={800}
      >
        {tipo === "numero" && (
          <CrearActualizarWidgetNumero
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}

        {tipo === "texto" && (
          <CrearActualizarWidgetTexto
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "objetivo" && (
          <CrearActualizarWidgetObjetivo
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}

        {tipo === "link" && (
          <CrearActualizarWidgetLink
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "video" && (
          <CrearActualizarWidgetVideo
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "woocommerce" && (
          <CrearActualizarWidgetWoocommerce
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "arbolGenealogico" && (
          <CrearActualizarWidgetArbolGenealogico
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "tabla" && (
          <CrearActualizarWidgetTabla
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "listaRecursos" && (
          <CrearActualizarWidgetListaRecursos
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "adminRecursos" && (
          <CrearActualizarWidgetAdminRecursos
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "debitoCredito" && (
          <CrearActualizarDebitoCredito
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "uploadFiles" && (
          <CrearActualizarUploadFiles
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "banner" && (
          <CrearActualizarWidgetBanner
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}
        {tipo === "logros" && (
          <CrearActualizarWidgetLogros
            data={data}
            visible={modalEditar}
            setVisible={setModalEditar}
          />
        )}

        <TiposFormulariosWidgetsDev1
          tipo={tipo}
          data={data}
          visible={modalEditar}
          setVisible={setModalEditar}
        />
      </Drawer>
      <Table
        columns={columns}
        dataSource={store.widgets.filter((widget) =>
          widget.nombre.toLowerCase().includes(filtro.toLowerCase())
        )}
      />
    </div>
  );
}

export default observer(ListaWidgetsComponente);
