import React from "react";
import { Form, Input, Select, message, Switch, Button } from "antd";

import { ObjectId } from "bson";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import Boton from "../../../../components/design/elementos/boton";


function CrearActualizarWidgetMarlinBotonComponente(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();


  const guardarWidget = (values) => {
    
      values.tipo = "marlinBoton";
      setPensando(true);
      setLoading(true);
      values.limiteCantidad = +values.limiteCantidad;
      values.limiteWidth = +values.limiteWidth;
      Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
        .then((res) => {
          props.setVisible(false);
          setPensando(false);
          setLoading(false);
          return Api(
            "Cuentas",
            "Get",
            { _id: store.cuentaactiva },
            { widgets: 1 }
          );
        })
        .then((res) => {
          setStoreWidgets(res[0].widgets);
        })
        .catch((err) => {
          console.log(err);
          setPensando(false);
          setLoading(false);
        });

  };


  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  }, [props.visible]);

  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }

  }, [props.data]);

  React.useEffect(() => {}, []);

  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>
        <Form.Item
          name="dinamico"
          hidden
          rules={[{ required: true }]}
        ></Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Boton loading={loading} texto="Guardar" htmlType="submit" />
            <Boton
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              texto="Cancelar"
              tipo="danger"
              htmlType="button"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetMarlinBotonComponente;
